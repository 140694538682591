/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import CompanyPickerFormField from '../form/CompanyPickerFormField/Field';
import c from '../../constant';
import * as Yup from 'yup';

const M_BRANCH_PARTNER = gql`
	mutation ($branchId: String!, $partnerId: String!) {
		addBranchSalePartner(branchId: $branchId, partnerId: $partnerId)
	}
`;

const validationSchema = Yup.object().shape({
	companyId: Yup.string().required('Company is required'),
});

function CustomModal({ branchId = null, isOpen = false, onClose = () => null, onCallback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [sendData, { loading }] = useMutation(M_BRANCH_PARTNER);

	return (
		<>
			<Modal closeOnEsc={loading ? false : true} closeOnOverlayClick={loading ? false : true} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add Partnered Company')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{ companyId: '' }}
							validationSchema={validationSchema}
							onSubmit={(values, { setFieldError }) =>
								sendData({ variables: { branchId, partnerId: values?.companyId } })
									.then(({ data }) => {
										if (data?.addBranchSalePartner) {
											toast({
												title: t('Operation success'),
												status: 'success',
												isClosable: true,
												position: 'top-right',
											});
											onClose();
											onCallback();
										}
									})
									.catch(({ message }) => {
										setFieldError('companyId', message);
									})
							}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<CompanyPickerFormField
											name="companyId"
											label="Company"
											required
											companyTypes={['GENERAL_INSURANCE', 'LIFE_INSURANCE', 'MICRO_INSURANCE']}
										/>
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center" float="right">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												isDisabled={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default CustomModal;
