import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Flex, Text, SimpleGrid } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faBell, faCookieBite, faBullhorn, faCogs, faToolbox, faKey, faEnvelope, faSortNumericUpAlt, faFileAlt, faChartBar, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import c from '../../constant';

function Settings() {
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function menuItem({ item, index }) {
		return (
			<Link key={`menu-${index}`} to={item.path}>
				<Flex
					h="64px"
					alignItems="center"
					bg="white"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					p="16px"
					cursor="pointer"
				>
					<FontAwesomeIcon icon={item?.icon} color="#303030" p="2" style={{ fontSize: 20 }} />
					<Text fontWeight="500" ml="4">
						{t(item.title)}
					</Text>
				</Flex>
			</Link>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '#',
						},
					]}
				/>
				<SimpleGrid columns={[2, null, 3]} spacing='4' mt="4">
					{menu.map((item, index) => menuItem({ item, index }))}
				</SimpleGrid>
			</Container>
		</>
	);
}

export default Settings;

const menu = [
	{
		title: 'Maintenance Status',
		path: '/settings/maintenance-status',
		icon: faToolbox,
	},
	{
		title: 'Delete notification from all user',
		path: '/settings/delete-notification',
		icon: faBell,
	},
	{
		title: 'Products',
		path: '/product/list',
		icon: faCookieBite,
	},
	{
		title: 'Announcement',
		path: '/announcement/list',
		icon: faBullhorn,
	},
	{
		title: 'Other Setting',
		path: '/other-setting/list',
		icon: faCogs,
	},
	{
		title: 'Email',
		path: '/settings/email?page=1',
		icon: faEnvelope,
	},
	{
		title: 'CamDigiKey',
		path: '/settings/camdigikey',
		icon: faKey,
	},
	{
		title: 'White List ORG CamDX',
		path: '/settings/whitelist-org-camdx/list',
		icon: faKey,
	},
	{
		title: 'Type Of Issued Letter',
		path: '/type-of-issued-letter?page=type-of-issued-letter',
		icon: faSortNumericUpAlt,
	},
	{
		title: 'Public service report',
		path: '/system-report/public-service-report',
		icon: faChartBar,
	},
	{
		title: 'Proposal',
		path: '/settings/proposal',
		icon: faFileAlt,
	},
	{
		title: 'Agent Statistic Report',
		path: '/settings/agent/agent-statistic-report',
		icon: faUsers,
	},
	{
		title: 'Logs Files',
		path: '/logs-files',
		icon: faUsers,
	},
];
