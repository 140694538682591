/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
	useToast,
	Textarea,
	Flex,
	Badge,
	CloseButton,
	Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import {
	useBranchRequestStore,
	usePartneredCompanyStore,
	useProfessionalBranchChangeRequestStore,
	useProfessionalRequestStore,
	useTerminationRequestStore,
} from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import c from '../../../../constant';

const MUTATION_DATA = gql`
	mutation up($id: String!, $status: String, $description: String, $type: String, $from: String) {
		updateProfessionalStatus(id: $id, status: $status, description: $description, type: $type, from: $from) {
			id
			status
			specializedCertificate
			agent {
				code
				name
				latinName
			}
			branch {
				name
				nameLatin
			}
			description
			currentBranch
			endDate
			terminationType
			terminationDescription
		}
	}
`;

function UpdateProfessionalStatus({ id = null, status = null, type = 'new', from = 'partner', onCallback = () => null }) {
	const cancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { isPermitted: isPermittedPartneredCompany } = usePartneredCompanyStore((state) => state.isPermitted);
	const { isPermitted: isPermittedBranchRequest } = useBranchRequestStore((state) => state.isPermitted);
	const { isPermitted: isPermittedProfessional } = useProfessionalRequestStore((state) => state.isPermitted);
	const { isPermitted: isPermittedProfessionalBranchChange } = useProfessionalBranchChangeRequestStore((state) => state.isPermitted);
	const { isPermitted: isPermittedTermination } = useTerminationRequestStore((state) => state.isPermitted);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [rejectDesc, setRejectDesc] = useState('');
	const [update, { error, loading, data }] = useMutation(MUTATION_DATA);

	useEffect(() => {
		if (data?.updateProfessionalStatus) {
			onCallback(data?.updateProfessionalStatus);
			onClose();
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function approve() {
		update({
			variables: {
				id,
				status: 'approved',
				description: null,
				type,
				from,
			},
		});
	}

	function reject() {
		update({
			variables: {
				id,
				status: 'rejected',
				description: rejectDesc,
				type,
				from,
			},
		});
	}

	function reset() {
		update({
			variables: {
				id,
				status: null,
				description: null,
				type,
				from,
			},
		});
	}

	return (
		<>
			{!status && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Tooltip label={t('approve')}>
							<Button
								size="sm"
								isLoading={loading}
								isDisabled={
									isPermittedPartneredCompany ||
									isPermittedBranchRequest ||
									isPermittedProfessional ||
									isPermittedProfessionalBranchChange ||
									isPermittedTermination
										? false
										: true
								}
								colorScheme="green"
								onMouseDown={approve}
								ml={0}
								borderRadius={c.borderRadius}
							>
								{t('approve')}
							</Button>
						</Tooltip>
						<Tooltip label={t('reject')}>
							<Button
								size="sm"
								isLoading={loading}
								isDisabled={
									isPermittedPartneredCompany ||
									isPermittedBranchRequest ||
									isPermittedProfessional ||
									isPermittedProfessionalBranchChange ||
									isPermittedTermination
										? false
										: true
								}
								colorScheme="red"
								onMouseDown={onOpen}
								ml={2}
								borderRadius={c.borderRadius}
							>
								{t('reject')}
							</Button>
						</Tooltip>
					</Flex>
				</>
			)}
			{status === 'approved' && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Tooltip label={t('approved')}>
							<Badge colorScheme="green" variant="solid">
								{t('approved')}
							</Badge>
						</Tooltip>
						<Tooltip label={t('You can change this status')}>
							<CloseButton
								borderRadius={c.borderRadius}
								variant="solid"
								hidden={
									isPermittedPartneredCompany ||
									isPermittedBranchRequest ||
									isPermittedProfessional ||
									isPermittedProfessionalBranchChange ||
									isPermittedTermination
										? false
										: true
								}
								onMouseDown={reset}
								size="xs"
								p={1}
								ml={1}
								color="gray.400"
							/>
						</Tooltip>
					</Flex>
				</>
			)}
			{status === 'rejected' && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Tooltip label={t('rejected')}>
							<Badge colorScheme="red" variant="solid">
								{t('rejected')}
							</Badge>
						</Tooltip>
						<Tooltip label={t('You can change this status')}>
							<CloseButton
								borderRadius={c.borderRadius}
								variant="solid"
								hidden={
									isPermittedPartneredCompany ||
									isPermittedBranchRequest ||
									isPermittedProfessional ||
									isPermittedProfessionalBranchChange ||
									isPermittedTermination
										? false
										: true
								}
								onMouseDown={reset}
								size="xs"
								p={1}
								ml={1}
								color="gray.400"
							/>
						</Tooltip>
					</Flex>
				</>
			)}
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reason of Rejection')}
						</AlertDialogHeader>
						<AlertDialogBody>
							{t('Please input reason of rejection')}
							<Textarea value={rejectDesc} onChange={(e) => setRejectDesc(e.currentTarget.value)} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button borderRadius={c.borderRadius} isLoading={loading} colorScheme="gray" ref={cancelRef} onMouseDown={onClose} ml={0}>
								{t('Cancel')}
							</Button>
							<Button
								borderRadius={c.borderRadius}
								isLoading={loading}
								isDisabled={rejectDesc ? false : true}
								colorScheme="red"
								onMouseDown={reject}
								ml={2}
							>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default UpdateProfessionalStatus;
