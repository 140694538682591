/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Flex,
	Button,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Tag,
	TagCloseButton,
	Box,
	Text,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import CompanyName from '../CompanyName';
import c from '../../constant';

const M_BRANCH_PARTNER = gql`
	mutation ($branchId: String!, $partnerIds: [String!]) {
		deleteBranchSalePartners(branchId: $branchId, partnerIds: $partnerIds)
	}
`;

function CustomModal({ branchId = null, partners = [], isOpen = false, onClose = () => null, onCallback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [partnerIds, setPartnerIds] = useState([]);
	const [sendData, { loading, error, data }] = useMutation(M_BRANCH_PARTNER);

	useEffect(() => {
		if (data) {
			if (data?.deleteBranchSalePartners) {
				toast({
					title: t('Operation success'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				onCallback();
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Modal closeOnEsc={loading ? false : true} closeOnOverlayClick={loading ? false : true} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Delete Partnered Company')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{partners?.length <= 0 && (
							<Flex direction="column" alignItems="center" justifyContent="center" p={16}>
								<Text fontSize="md" fontWeight="normal" color="gray.600">
									{t('No Data')}
								</Text>
							</Flex>
						)}
						{partners?.length > 0 && (
							<>
								{partners?.map((el, i) => (
									<Box key={el?.id}>
										<Tag
											mt={i === 0 ? 0 : 4}
											size="lg"
											borderRadius={c.borderRadius}
											variant="solid"
											colorScheme={partnerIds?.includes(el?.id) ? 'red' : 'green'}
											w="100%"
											h="42px"
											justifyContent="space-between"
										>
											<CompanyName company={el} color="white" />
											<TagCloseButton
												onMouseDown={() => {
													if (partnerIds?.includes(el?.id)) {
														setPartnerIds(partnerIds.filter((item) => item !== el?.id));
													} else {
														setPartnerIds([...partnerIds, el?.id]);
													}
												}}
											/>
										</Tag>
									</Box>
								))}
								<Flex mt="10" justifyContent="space-between" alignItems="center">
									<Flex justifyContent="center" alignItems="center"></Flex>
									<Button
										variant="solid"
										size="md"
										h="42px"
										w="128px"
										minW="128px"
										color="white"
										colorScheme="red"
										isLoading={loading}
										isDisabled={loading || partnerIds?.length === 0}
										onMouseDown={() => sendData({ variables: { branchId, partnerIds } })}
									>
										{t(`Delete`)}
									</Button>
								</Flex>
							</>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default CustomModal;
