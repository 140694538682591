/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	Box,
	Button,
	Center,
	Table as ChakraTable,
	TableContainer as ChakraTableContainer,
	Tbody as ChakraTbody,
	Td as ChakraTd,
	Th as ChakraTh,
	Thead as ChakraThead,
	Tr as ChakraTr,
	Container,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { faArrowRight, faCheck, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyName from '../../../../components/CompanyName';
import DownloadAndViewButton from '../../../../components/Files/DownloadAndViewButton';
import LabelDetail from '../../../../components/LabelDetail';
import Loading from '../../../../components/Loading';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryModel from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryModel';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import { useProfessionalRequestStore } from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import IntermediaryType from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryType';
import NoData from '../../../../components/NoData';
import PrintLayout from '../../../../components/Print/PrintLayout';
import c from '../../../../constant';
import useMoment from '../../../../hooks/useMoment';
import useStore from '../../../../store';
import formatMoneyKH from '../../../../utils/formatMoneyKH';
import ProfessionalIndividualList from '../components/ProfessionalIndividualList';
import EventLoggingButton from '../../../../components/ManageAgentPartner/Modal/EventLoggingButton';

const LABEL_WIDTH = '130px';
const Q_GET_REQUESTS = gql`
	query get($id: String!) {
		getIntermediaryRequestForProfessionalIndividualById(id: $id) {
			id
			number
			description
			status
			adminStatus
			paymentStatus
			paymentData
			createdAt
			createdBy
			startedAt
			type
			approvalStatus
			completedLetter
			details {
				id
				requestId
				partnerId
				partnerType
				model
				labelPartnerType
				company {
					id
					name
					nameKhmer
					type
					logo
				}
			}
			company {
				id
				name
				nameKhmer
				type
				logo
			}
		}
	}
`;

const M_NEXT = gql`
	mutation next($id: String!, $requestDate: String, $solarDate: String, $lunarDate: String) {
		nextStepIntermediaryProfessionalIndividualReview(id: $id, requestDate: $requestDate, solarDate: $solarDate, lunarDate: $lunarDate)
	}
`;

function IntermediaryPartnerRequest() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const setPermitted = useProfessionalRequestStore((state) => state.setPermitted);
	const { t } = useTranslation();
	const { push } = useHistory();
	const { paperGeneralDateString, paperSolarDateString, paperLunarDateString } = useMoment();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isPermitted } = useProfessionalRequestStore((state) => state.isPermitted);
	const [data, setData] = useState(null);
	const [pfListCompleted, setPfListCompleted] = useState({});
	const [isFormCompleted, setIsFormCompleted] = useState(false);
	const [loadData, { loading, data: loadedData, error: loadedError }] = useLazyQuery(Q_GET_REQUESTS);
	const [submitToNextStep, { loading: loadingNext, data: dataNext, error: errorNext }] = useMutation(M_NEXT);

	useEffect(() => {
		loadData({ variables: { id } });
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (loadedData) {
			setData(loadedData?.getIntermediaryRequestForProfessionalIndividualById);
		}
	}, [loadedData]);

	useEffect(() => {
		if (dataNext?.nextStepIntermediaryProfessionalIndividualReview) {
			refresh();
		}
	}, [dataNext]);

	useEffect(() => {
		if (loadedError) {
			if (loadedError?.graphQLErrors?.length > 0) {
				loadedError?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(loadedError?.message ? loadedError?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [loadedError]);

	useEffect(() => {
		if (errorNext) {
			if (errorNext?.graphQLErrors?.length > 0) {
				errorNext?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorNext?.message ? errorNext?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorNext]);

	useEffect(() => {
		let completed = true;
		if (data?.approvalStatus === 'rejected') {
			completed = true;
		} else if (data?.approvalStatus === 'approved') {
			completed = isPfListCompleted(pfListCompleted);
		} else {
			completed = false;
		}
		setIsFormCompleted(completed);
	}, [pfListCompleted, data]);

	useEffect(() => {
		if (data?.adminStatus && user?.position) {
			setPermitted(data?.adminStatus, user?.position);
		}
	}, [data, user]);

	function refresh() {
		setData(null);
		setTimeout(() => loadData({ variables: { id } }), 100);
	}

	function isPfListCompleted(data) {
		let completed = true;
		Object.keys(pfListCompleted).forEach((key) => {
			if (pfListCompleted[key] === false) {
				completed = false;
			}
		});
		return completed;
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Request for New Professional Individual',
								path: '/intermediary/professional-individual-request?page=1',
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex w="100%" justifyContent="space-between" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Detail')}
									</Text>
									{data?.id && (
										<Flex justifyContent="flex-end" alignItems="center">
											<EventLoggingButton label="Logs" typeObjectId={id} />
											<Button
												onClick={onOpen}
												leftIcon={<FontAwesomeIcon icon={faFileInvoice} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
												ml={2}
											>
												{t('Payment')}
											</Button>
											<>
												{data?.adminStatus !== 'completed' && (
													<>
														{isPermitted && (
															<>
																<Button
																	isLoading={loadingNext}
																	isDisabled={!isFormCompleted}
																	rightIcon={
																		<FontAwesomeIcon
																			icon={data?.adminStatus === 'reviewing_by_department' ? faCheck : faArrowRight}
																			style={{ fontSize: 16 }}
																		/>
																	}
																	colorScheme="blue"
																	variant="solid"
																	size="sm"
																	borderRadius={c.borderRadius}
																	border="1px solid #bdc3c7"
																	ml={2}
																	onMouseDown={() => {
																		if (isFormCompleted) {
																			submitToNextStep({
																				variables: {
																					id: data?.id,
																					requestDate: paperGeneralDateString(data?.startedAt, 'ឆ្នាំ'),
																					solarDate: paperSolarDateString(),
																					lunarDate: paperLunarDateString(),
																				},
																			});
																		}
																	}}
																>
																	{t(data?.adminStatus === 'reviewing_by_department' ? 'Done' : 'Submit')}
																</Button>
															</>
														)}
													</>
												)}
											</>
										</Flex>
									)}
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{loading && (
									<>
										<Loading />
									</>
								)}
								{!loading && !data && (
									<>
										<NoData />
									</>
								)}
								{!loading && data && (
									<>
										<SimpleGrid columns={[1, 1, 2]} w="100%" bg="white" spacing={0} mb={4}>
											<LabelDetail labelWidth={LABEL_WIDTH} direction="row" label="Number" titleDetail={data?.number} />
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Created By"
												childDetail={<CompanyName company={data?.company} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Started At"
												childDetail={<LocalizedDate dateString={data?.createdAt} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Status"
												childDetail={<IntermediaryStatus status={data?.adminStatus} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Type"
												childDetail={<IntermediaryType type={data?.type} />}
											/>
											{data?.adminStatus === 'completed' && data?.completedLetter?.url && (
												<LabelDetail
													label="Approval Letter"
													childDetail={<DownloadAndViewButton url={c.baseFilePath + data?.completedLetter?.url} marginLeft={0} />}
												/>
											)}
										</SimpleGrid>
									</>
								)}
								{data && (
									<Box>
										<Tabs variant="enclosed">
											<TabList>
												{data?.details?.map((item, index) => (
													<>
														<Tab key={`th-${index}`}>{t(item?.labelPartnerType)}</Tab>
													</>
												))}
											</TabList>
											<TabPanels>
												{data?.details?.map((item, index) => {
													return (
														<TabPanel key={`tab-i-${index}`} borderWidth={1} borderStyle="solid" borderColor="gray.200">
															{item?.oldCompany?.id && (
																<LabelDetail
																	label="Old Partnered Insurance Company"
																	childDetail={<CompanyName company={item?.oldCompany} />}
																/>
															)}
															<SimpleGrid columns={[1, 1, 2]} w="100%" bg="white" spacing={1} mb={2}>
																<LabelDetail
																	label="Partnered Insurance Company"
																	childDetail={<CompanyName company={item?.company} />}
																/>
																<LabelDetail label="Model" childDetail={<IntermediaryModel model={item?.model} />} />
															</SimpleGrid>
															{item?.model === 'direct' && (
																<LabelDetail
																	hoverBgColor="grba(0,0,0,0)"
																	label="Professional Individual List"
																	childDetail={
																		<ProfessionalIndividualList
																			id={item?.id}
																			callback={(e) =>
																				setPfListCompleted({ ...pfListCompleted, [e?.id]: e?.isCompleted })
																			}
																			type="new"
																			from="professional"
																		/>
																	}
																/>
															)}
														</TabPanel>
													);
												})}
											</TabPanels>
										</Tabs>
									</Box>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
			<Modal size="lg" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Payment')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<ChakraTableContainer>
							<ChakraTable size="sm">
								<ChakraThead>
									<ChakraTr>
										<ChakraTh>{t('Label')}</ChakraTh>
										<ChakraTh isNumeric>{t('Amount')}(KHR)</ChakraTh>
										<ChakraTh isNumeric>Trx.ID</ChakraTh>
									</ChakraTr>
								</ChakraThead>
								<ChakraTbody>
									{data?.paymentData?.map((item, index) => {
										return (
											<ChakraTr>
												<ChakraTd>{item?.label}</ChakraTd>
												<ChakraTd isNumeric>{formatMoneyKH(parseFloat(item?.amount))}</ChakraTd>
												<ChakraTd
													isNumeric
													cursor={user.role === 'admin' ? 'pointer' : 'default'}
													_hover={{ textDecoration: user.role === 'admin' ? 'underline' : 'none' }}
													onMouseDown={() => user.role === 'admin' && push(`/payment?page=1&trxId=${item?.tranId}`)}
												>
													{item?.tranId}
												</ChakraTd>
											</ChakraTr>
										);
									})}
								</ChakraTbody>
							</ChakraTable>
						</ChakraTableContainer>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default IntermediaryPartnerRequest;
