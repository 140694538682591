/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button, Center, Container, Divider, Flex, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTerminationPartnerCompanyStore } from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import UpdateTerminationPartnerItemApprovalStatus from './components/UpdateTerminationPartnerItemApprovalStatus';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import PrintLayout from '../../../../components/Print/PrintLayout';
import Loading from '../../../../components/Loading';
import NoData from '../../../../components/NoData';
import LabelDetail from '../../../../components/LabelDetail';
import CompanyName from '../../../../components/CompanyName';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import IntermediaryModel from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryModel';
import DownloadAndViewButton from '../../../../components/Files/DownloadAndViewButton';
import useMoment from '.././../../../hooks/useMoment';
import useStore from '../../../../store';
import c from '../../../../constant';

const Q_DETAIL = gql`
	query get($id: String!) {
		getTerminationPartnerRequestById(id: $id) {
			id
			number
			createdAt
			company {
				id
				name
				nameKhmer
				type
				logo
			}
			adminStatus
			completedAt
			items {
				id
				requestId
				partner {
					id
					name
					nameKhmer
					type
					logo
				}
				partnerType
				model
				approvalStatus
				rejectionReason
				letterOfNotification
				letterOfTerminationApproval
				letterOfBoardOfDirectors
				completedLetter
			}
		}
	}
`;

const M_SUBMIT = gql`
	mutation submit($id: String!, $requestDate: String, $solarDate: String, $lunarDate: String) {
		submitTerminationPartnerReview(id: $id, requestDate: $requestDate, solarDate: $solarDate, lunarDate: $lunarDate)
	}
`;

function IntermediaryTerminationPartnerRequestDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location?.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const setPermitted = useTerminationPartnerCompanyStore((state) => state.setPermitted);
	const { isPermitted } = useTerminationPartnerCompanyStore((state) => state.isPermitted);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { t } = useTranslation();
	const { paperLunarDateString, paperSolarDateString, paperGeneralDateString } = useMoment();
	const [detailData, setDetailData] = useState(null);
	const [fetchDetailData, { loading, error, data }] = useLazyQuery(Q_DETAIL);
	const [sumitReviewData, { loading: loadingSubmit }] = useMutation(M_SUBMIT);

	useEffect(() => {
		loadDetailData();
	}, [id]);

	useEffect(() => {
		data?.getTerminationPartnerRequestById && setDetailData(data?.getTerminationPartnerRequestById);
	}, [data]);

	useEffect(() => {
		loadPermission();
	}, [detailData, user]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	const loadDetailData = () => {
		fetchDetailData({ variables: { id } });
	};

	const loadPermission = () => {
		detailData?.adminStatus && user?.position && setPermitted(detailData?.adminStatus, user?.position);
	};

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Request for Termination Partner with Insurance Company',
								path: `/intermediary/termination-partner-request?page=1`,
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex w="100%" justifyContent="space-between" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Detail')}
									</Text>
									<Flex justifyContent="flex-end" alignItems="center">
										<>
											{detailData?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingSubmit}
																isDisabled={detailData?.items?.filter((el) => el?.approvalStatus === null)?.length !== 0}
																rightIcon={
																	<FontAwesomeIcon
																		icon={detailData?.adminStatus === 'reviewing_by_department' ? faCheck : faArrowRight}
																		style={{ fontSize: 16 }}
																	/>
																}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() =>
																	sumitReviewData({
																		variables: {
																			id: detailData?.id,
																			requestDate: paperGeneralDateString(data?.startedAt, 'ឆ្នាំ'),
																			solarDate: paperSolarDateString(),
																			lunarDate: paperLunarDateString(),
																		},
																	})
																		.then(({ data }) => {
																			loadDetailData();
																		})
																		.catch((error) => {
																			toast({
																				title: t(error?.message ? error?.message : 'something went wrong'),
																				status: 'error',
																				isClosable: true,
																				position: 'bottom-right',
																			});
																		})
																}
															>
																{t(detailData?.adminStatus === 'reviewing_by_department' ? 'Done' : 'Submit')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									</Flex>
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{loading && (
									<>
										<Loading />
									</>
								)}
								{!loading && !detailData?.id && (
									<>
										<NoData />
									</>
								)}
								{!loading && detailData?.id && (
									<Box w="100%">
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<LabelDetail mb={1} mt={0} labelWidth={32} direction="row" label="Number" titleDetail={detailData?.number} />
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Created By"
												childDetail={<CompanyName company={detailData?.company} />}
											/>
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Submitted At"
												childDetail={<LocalizedDate dateString={detailData?.createdAt} />}
											/>
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Status"
												childDetail={<IntermediaryStatus status={detailData?.adminStatus} />}
											/>
											{detailData?.completedAt && (
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={32}
													direction="row"
													label="Completed At"
													childDetail={<LocalizedDate dateString={detailData?.completedAt} />}
												/>
											)}
										</SimpleGrid>
										<Tabs variant="enclosed" mt={4}>
											<TabList>
												{detailData?.items?.map((item, i) => (
													<>
														<Tab key={`tab-i-${i}`}>{t(item?.partnerType)}</Tab>
													</>
												))}
											</TabList>
											<TabPanels>
												{detailData?.items?.map((item, i) => (
													<TabPanel key={`tab-panel-i-${i}`} borderWidth={1} borderStyle="solid" borderColor="gray.200">
														<SimpleGrid columns={[1, null, 2]} spacingX={8}>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																direction="column"
																label="Partnered Insurance Company"
																childDetail={<CompanyName company={item?.partner} />}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																direction="column"
																label="Model"
																childDetail={<IntermediaryModel model={item?.model} />}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																label="Letter of Termination Notification"
																childDetail={
																	<>
																		{item?.letterOfNotification ? (
																			<DownloadAndViewButton
																				marginLeft={0}
																				url={item?.letterOfNotification?.url}
																				size={item?.letterOfNotification?.size}
																			/>
																		) : (
																			<IntermediaryStatus status="N/A" />
																		)}
																	</>
																}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																label="Letter of Termination Approval"
																childDetail={
																	<>
																		{item?.letterOfTerminationApproval ? (
																			<DownloadAndViewButton
																				marginLeft={0}
																				url={item?.letterOfTerminationApproval?.url}
																				size={item?.letterOfTerminationApproval?.size}
																			/>
																		) : (
																			<IntermediaryStatus status="N/A" />
																		)}
																	</>
																}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																label="Letter of Board of Directors Approval"
																childDetail={
																	<>
																		{item?.letterOfBoardOfDirectors ? (
																			<DownloadAndViewButton
																				marginLeft={0}
																				url={item?.letterOfBoardOfDirectors?.url}
																				size={item?.letterOfBoardOfDirectors?.size}
																			/>
																		) : (
																			<IntermediaryStatus status="N/A" />
																		)}
																	</>
																}
															/>
														</SimpleGrid>
														<Divider mb={2} mt={4} />
														<SimpleGrid columns={[1, null, 2]} spacingX={8}>
															<LabelDetail
																mb={1}
																mt={0}
																label="Decision"
																childDetail={
																	<UpdateTerminationPartnerItemApprovalStatus
																		id={item?.id}
																		approvalStatus={item?.approvalStatus}
																		onCallback={(result) =>
																			setDetailData({
																				...detailData,
																				items: detailData?.items?.reduce((pre, cur) => {
																					let localPre = pre;
																					if (cur?.id === result?.id) {
																						localPre.push(result);
																					} else {
																						localPre.push(cur);
																					}
																					return localPre;
																				}, []),
																			})
																		}
																	/>
																}
															/>
															{detailData?.adminStatus === 'completed' && item?.approvalStatus === true && (
																<LabelDetail
																	mb={1}
																	mt={0}
																	label="Acknowledgement Letter"
																	childDetail={
																		<DownloadAndViewButton
																			marginLeft={0}
																			url={c.baseFilePath + item?.completedLetter?.url}
																		/>
																	}
																/>
															)}
															{item?.approvalStatus === false && (
																<LabelDetail
																	mb={1}
																	mt={0}
																	label="Reason of Rejection"
																	childDetail={<Text color="red.400">{t(item?.rejectionReason) || 'N/A'}</Text>}
																/>
															)}
														</SimpleGrid>
													</TabPanel>
												))}
											</TabPanels>
										</Tabs>
									</Box>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
		</>
	);
}

export default IntermediaryTerminationPartnerRequestDetail;
