/* eslint-disable react-hooks/exhaustive-deps */
import create from 'zustand';
import c from '../../../constant';

const usePartneredCompanyStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_contract_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position)) ||
			(adminStatus === 'reviewing_contract_by_department' && c.stepDepartmentRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_dg' && c.stepDirectorGeneralRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useTerminationPartnerCompanyStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_dg' && c.stepDirectorGeneralRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useTerminationBranchManagerStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a = adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position);
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useBranchRequestStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useBranchManagerChangeRequestStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useProfessionalRequestStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useProfessionalBranchChangeRequestStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useTerminationRequestStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useSaleProfessionalPermissionStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a =
			(adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position)) ||
			(adminStatus === 'reviewing_by_department' && c.stepDepartmentRoles.includes(position));
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

const useTerminationBranchStore = create((set) => ({
	isPermitted: false,
	setPermitted: (adminStatus, position) => {
		const a = adminStatus === 'reviewing_by_office' && c.stepOfficeRoles.includes(position);
		set((state) => ({ isPermitted: { ...state.isPermitted, isPermitted: a } }));
	},
}));

export {
	usePartneredCompanyStore,
	useTerminationPartnerCompanyStore,
	useBranchRequestStore,
	useBranchManagerChangeRequestStore,
	useProfessionalRequestStore,
	useProfessionalBranchChangeRequestStore,
	useTerminationRequestStore,
	useTerminationBranchManagerStore,
	useSaleProfessionalPermissionStore,
	useTerminationBranchStore,
};
