import React, { useEffect } from 'react';
import {
	Image,
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	DrawerFooter,
	Button,
	useToast,
} from '@chakra-ui/react';
import Logo from '../assets/images/logo-border-white-92px.png';
import { Link } from 'react-router-dom';
import { faChevronDown, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../constant';
import { capitalizeFirstLetter } from '../utils';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import useStore from '../store';
import ChangeLanguage from '../components/ChangeLanguage';
import { useTranslation } from 'react-i18next';
import NotificationList from './NotificationList';

const QUERY_CURRENT_USER = gql`
	query {
		getCurrentuser {
			id
			username
			fullName
			fullNameKhmer
			role
			position
			canAssignUserToCompanyProposal
			canUpdateCompanyLicensing
			canUpdateCompanyGovernance
			canUpdateCompanyContact
			canUploadDoneFileToCompanyProposal
			canViewListReportToCompanyProposal
			canAutoreassignProposal
			canAssignUserToCompanyReport
			canApproveRejectLeaveRequest
			canViewLeaveRequestReport
			canNotApplyForLeave
			canApproveRejectDeactivateAgent
			canFinalApproveRejectAgent
			canUploadDoneFileToComplaint
			canAssignUserToComplaint
			announcingItem
			paymentAgentRegistration
			paymentPublicService
			paymentLicense
			canUpdateLicense
			paymentFine
			canUpdateFine
			canCreateIssuedLetter
			canUpdateIssuedLetter
			canDeleteIssuedLetter
			homeMenu
			department
			office
		}
	}
`;

const GET_EMPLOYEE_ID_BY_USER_ID = gql`
	query get($id: String!) {
		getEmployeeIdByUserId(id: $id)
	}
`;

const QUERY_COUNT_UNREAD_NOTIFICATIONS = gql`
	query {
		getCountUnreadNotifications
	}
`;

const MUTATION_MARK_ALL_AS_READ = gql`
	mutation {
		markAllNotificationAsRead
	}
`;

function Header() {
	const toast = useToast();
	const { t } = useTranslation();
	const [loadEmployeeId, { data: employeeId }] = useLazyQuery(GET_EMPLOYEE_ID_BY_USER_ID);
	const [loadCurrentUser, { error, loading, data }] = useLazyQuery(QUERY_CURRENT_USER);
	const [
		loadCountUnreadNotifications,
		{ error: errorCountUnreadNotifications, loading: loadingCountUnreadNotifications, data: dataCountUnreadNotifications },
	] = useLazyQuery(QUERY_COUNT_UNREAD_NOTIFICATIONS);
	const [markAllAsRead, { error: errorMarkAll, loading: loadingMarkAll, data: dataMarkAll }] = useMutation(MUTATION_MARK_ALL_AS_READ);

	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const { currentUser } = useStore((state) => state.currentUser);
	const { currentLang } = useStore((state) => state.currentLang);
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);

	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		loadCurrentUser();
		loadCountUnreadNotifications();
	}, []);

	useEffect(() => {
		if (data) {
			setCurrentUser(data?.getCurrentuser);
			loadEmployeeId({
				variables: { id: data?.getCurrentuser?.id },
			});
		}
	}, [data]);

	useEffect(() => {
		if (dataMarkAll?.markAllNotificationAsRead) {
			loadCountUnreadNotifications();
		}
	}, [dataMarkAll]);

	useEffect(() => {
		if (errorMarkAll) {
			toast({
				title: t('Mark all as read error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorMarkAll]);

	useEffect(() => {
		if (dataCountUnreadNotifications) {
			setCountUnreadNotifications(parseInt(dataCountUnreadNotifications?.getCountUnreadNotifications));
		}
	}, [dataCountUnreadNotifications]);

	function getDisplayName(user) {
		let displayName = capitalizeFirstLetter(user?.username);
		if (currentLang === 'kh') {
			displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : capitalizeFirstLetter(user?.username);
		} else {
			displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : capitalizeFirstLetter(user?.username);
		}
		return displayName;
	}

	return (
		<>
			<Flex
				className="hidden-print"
				boxShadow="0px 3px 3px #00000029"
				// bg="primary.dark"
				bg="#232F3E"
				justifyContent="center"
			>
				<Flex h="40px" maxW="95%" p="0.5px" flex="1" alignItems="center" justifyContent="space-between">
					<Link to="/">
						<Flex alignItems="center">
							<Image src={Logo} h="25px" w="auto" ml="16px" />
							<Text
								className="hide-mobile"
								mt={currentLang === 'kh' ? '1px' : '4px'}
								fontFamily={currentLang === 'kh' ? 'Moul' : 'Rajdhani'}
								fontWeight={currentLang !== 'kh' ? '600' : '400'}
								fontSize="14px"
								ml="1"
								color="white"
							>
								{t('INSURANCE REGULATOR of CAMBODIA')}
							</Text>
							<Text fontFamily={'Moul'} fontSize="1px" ml="1" pointerEvents="none">
								.
							</Text>
							<Text fontFamily={'Hanuman'} fontSize="1px" ml="1" pointerEvents="none">
								.
							</Text>
						</Flex>
					</Link>

					<Flex>
						<Flex
							onClick={onOpen}
							w="60px"
							h="40px"
							justifyContent="center"
							alignItems="center"
							cursor="pointer"
							_hover={{ bg: 'rgba(236, 240, 241,.2)' }}
						>
							<FontAwesomeIcon icon={faBell} style={{ fontSize: 16, color: countUnreadNotifications > 0 ? '#e74c3c' : 'white', marginTop: 4 }} />
							{countUnreadNotifications > 0 && (
								<Text userSelect="none" color="#e74c3c" mt="-12px" fontWeight="bold" fontSize="18px">
									{countUnreadNotifications}
								</Text>
							)}
						</Flex>
						<Flex _hover={{ bg: 'rgba(236, 240, 241,.2)' }} h="40px" justifyContent="center" alignItems="center" pl="8px" mr="8px">
							<ChangeLanguage />
						</Flex>
						<Menu>
							<MenuButton cursor="pointer">
								<Flex>
									<Text fontWeight="500" color="#d5dbdb">
										{currentUser?.username ? getDisplayName(currentUser) : ''}{' '}
									</Text>
									<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 16, color: '#d5dbdb', marginTop: 2, marginLeft: 6 }} />
								</Flex>
							</MenuButton>
							<MenuList zIndex={999} pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
								<Link to="/account">
									<MenuItem>{t('Account')}</MenuItem>
								</Link>
								<MenuDivider />
								{employeeId?.getEmployeeIdByUserId && (
									<>
										<Link to={`/employee/detail?id=${employeeId?.getEmployeeIdByUserId}`}>
											<MenuItem>{t('Curriculum Vitae')}</MenuItem>
										</Link>
										<MenuDivider />
									</>
								)}

								{currentUser?.role === 'admin' && (
									<>
										<Link to="/users?page=1">
											<MenuItem color="#3498db">{t('Users')}</MenuItem>
										</Link>
										<Link to="/settings">
											<MenuItem color="#3498db">{t('Settings')}</MenuItem>
										</Link>

										<MenuDivider />
									</>
								)}

								<MenuItem
									onClick={() => {
										sessionStorage.clear();
										window.location.reload();
									}}
								>
									{t('Sign Out')}
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>
			</Flex>
			<Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{t('Notifications')}</DrawerHeader>

					<Flex overflow="scroll">{!loadingCountUnreadNotifications && <NotificationList onClose={onClose} />}</Flex>
					<DrawerFooter p="2" bg="#ecf0f1">
						<Button
							isLoading={loadingMarkAll}
							size="sm"
							variant="solid"
							bg="white"
							onClick={() => {
								markAllAsRead();
							}}
						>
							{t('Mark all as read')}
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default Header;
