/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Center, Flex, Table, Tbody, Td, Tfoot, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SectionHeader } from '../../Other';
import CompanyBadge from '../../Other/CompanyBadge';
import CompanyName from '../../CompanyName';
import LocalizedDate from '../../LocalizedDate';
import c from '../../../constant';

function DistributionSystemBrokerPartnerPrint({ list = [], companyType = null, ...props }) {
	const { push } = useHistory();
	const { t } = useTranslation();

	function Status({ status = null }) {
		return (
			<>
				<>{status === 'active' && <Badge colorScheme="green">{t('Active')}</Badge>}</>
				<>{status === 'inactive' && <Badge colorScheme="red">{t('Inactive')}</Badge>}</>
			</>
		);
	}

	function Model({ model = null, color = 'orange' }) {
		switch (model) {
			case 'direct':
				return (
					<Badge variant="solid" colorScheme={color}>
						{t('Direct Sale')}
					</Badge>
				);
			case 'ref':
				return (
					<Badge variant="solid" colorScheme={color}>
						{t('Referral')}
					</Badge>
				);
			default:
				return null;
		}
	}

	function isAllowedIntermediaryCompany(type) {
		return type === c.insuranceCompanyType.broker.value;
	}

	function isAllowedInsuranceCompany(type) {
		return type === c.insuranceCompanyType.general.value || type === c.insuranceCompanyType.life.value || type === c.insuranceCompanyType.micro.value;
	}

	return (
		<Flex {...props}>
			<Box className="show-only-printing" width="100%">
				<Center mt={4}>
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="flex-start" borderBottom="1px solid #dbdbdb">
							{isAllowedIntermediaryCompany(companyType) && (
								<>
									<SectionHeader title={t('Partnering with Insurance Company')} fontSize="lg" />
								</>
							)}
							{isAllowedInsuranceCompany(companyType) && (
								<>
									<SectionHeader title={t('Partnering with Broker Company')} fontSize="lg" />
								</>
							)}
						</Flex>
						<Box p="16px">
							<Table variant="simple">
								<Thead>
									<Tr>
										{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Name')}</Th>}
										{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Type')}</Th>}
										{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Name')}</Th>}
										{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Type')}</Th>}
										<Th>{t('Model')}</Th>
										<Th>{t('Date')}</Th>
										<Th isNumeric>{t('Status')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{list?.length === 0 && (
										<Tr bg="white">
											<Td colSpan={100}>{t('No Data')}</Td>
										</Tr>
									)}
									{list?.map((item, index) => {
										return (
											<Tr key={`item-${index}`} bg="white">
												{isAllowedIntermediaryCompany(companyType) && (
													<Td>
														<CompanyName company={item?.company} />
													</Td>
												)}
												{isAllowedIntermediaryCompany(companyType) && (
													<Td>
														<CompanyBadge type={item?.company?.type} />
													</Td>
												)}
												{isAllowedInsuranceCompany(companyType) && (
													<Td>
														<CompanyName company={item?.intermediary} />
													</Td>
												)}
												{isAllowedInsuranceCompany(companyType) && (
													<Td>
														<CompanyBadge type={item?.intermediary?.type} />
													</Td>
												)}
												<Td>
													<Model model={item?.model} />
												</Td>
												<Td>
													<LocalizedDate dateString={item?.createdAt} />
												</Td>
												<Td isNumeric>
													{item?.active ? (
														<>
															<Status status="active" />
														</>
													) : (
														<Tooltip label={<LocalizedDate dateString={item?.inactiveAt} color="white" />}>
															<Box onMouseDown={() => push(`/intermediary/partner-request/detail?id=${item?.inactiveById}`)}>
																<Status status="inactive" />
															</Box>
														</Tooltip>
													)}
												</Td>
											</Tr>
										);
									})}
								</Tbody>
								<Tfoot>
									<Tr>
										{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Name')}</Th>}
										{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Type')}</Th>}
										{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Name')}</Th>}
										{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Type')}</Th>}
										<Th>{t('Model')}</Th>
										<Th>{t('Date')}</Th>
										<Th isNumeric>{t('Status')}</Th>
									</Tr>
								</Tfoot>
							</Table>
						</Box>
					</Box>
				</Center>
			</Box>
		</Flex>
	);
}

export default DistributionSystemBrokerPartnerPrint;
