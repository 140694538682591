/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Table,
	Thead,
	Tbody,
	Tr,
	TableContainer,
	Button,
	SimpleGrid,
	useToast,
	Divider,
	Tfoot,
	Badge,
} from '@chakra-ui/react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useBranchManagerChangeRequestStore } from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import DownloadAndViewButton from '../../../../components/Files/DownloadAndViewButton';
import UpdateIntermediaryBranchManagerChangeStatus from '../components/UpdateIntemediaryBranchManagerChangeStatus';
import PrintLayout from '../../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import Loading from '../../../../components/Loading';
import NoData from '../../../../components/NoData';
import LabelDetail from '../../../../components/LabelDetail';
import CompanyName from '../../../../components/CompanyName';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import TableHead from '../../../../components/TableHead';
import TableData from '../../../../components/TableData';
import useStore from '../../../../store';
import useMoment from '../../../../hooks/useMoment';
import c from '../../../../constant';

const LABEL_WIDTH = '200px';

const Q_GET_REQUESTS = gql`
	query get($id: String!) {
		getIntermediaryRequestForBranchManagerChangeById(id: $id) {
			id
			number
			description
			approvalStatus
			adminStatus
			status
			createdAt
			createdBy
			updatedAt
			managerName
			managerNameEn
			gender
			nominationType
			idDocumentType
			idDocumentNumber
			idDocument
			workingContract
			completedLetter
			reason
			branches {
				id
				name
				nameLatin
				managerName
				managerNameLatin
			}
			company {
				name
				nameKhmer
				type
				logo
			}
		}
	}
`;

const M_NEXT = gql`
	mutation next($id: String!, $requestDate: String, $solarDate: String, $lunarDate: String) {
		nextStepIntermediaryBranchManagerChangeReview(id: $id, requestDate: $requestDate, solarDate: $solarDate, lunarDate: $lunarDate)
	}
`;

function IntermediaryPartnerRequest() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const setPermitted = useBranchManagerChangeRequestStore((state) => state.setPermitted);
	const { t } = useTranslation();
	const { paperGeneralDateString, paperSolarDateString, paperLunarDateString } = useMoment();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isPermitted } = useBranchManagerChangeRequestStore((state) => state.isPermitted);
	const [data, setData] = useState(null);
	const [isFormCompleted, setIsFormCompleted] = useState(false);
	const [loadData, { loading, error: loadedError, data: loadedData }] = useLazyQuery(Q_GET_REQUESTS);
	const [submitToNextStep, { loading: loadingNext, error: errorNext, data: dataNext }] = useMutation(M_NEXT);

	useEffect(() => {
		loadData({ variables: { id } });
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (loadedData) {
			setData(loadedData?.getIntermediaryRequestForBranchManagerChangeById);
		}
	}, [loadedData]);

	useEffect(() => {
		if (dataNext?.nextStepIntermediaryBranchManagerChangeReview) {
			refresh();
		}
	}, [dataNext]);

	useEffect(() => {
		if (loadedError) {
			if (loadedError?.graphQLErrors?.length > 0) {
				loadedError?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(loadedError?.message ? loadedError?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [loadedError]);

	useEffect(() => {
		if (errorNext) {
			if (errorNext?.graphQLErrors?.length > 0) {
				errorNext?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorNext?.message ? errorNext?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorNext]);

	useEffect(() => {
		let completed = true;
		if (data?.approvalStatus === 'rejected') {
			completed = true;
		} else if (data?.approvalStatus === 'approved') {
			completed = true;
		} else {
			completed = false;
		}
		setIsFormCompleted(completed);
	}, [data]);

	useEffect(() => {
		if (data?.adminStatus && user?.position) {
			setPermitted(data?.adminStatus, user?.position);
		}
	}, [data, user]);

	function refresh() {
		setData(null);
		setTimeout(() => loadData({ variables: { id } }), 100);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Request for Nomination of Branch Manager',
								path: '/intermediary/branch-manager-change-request?page=1',
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex w="100%" justifyContent="space-between" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Detail')}
									</Text>
									{data?.id && (
										<>
											{data?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingNext}
																isDisabled={!isFormCompleted}
																rightIcon={
																	<FontAwesomeIcon
																		icon={data?.adminStatus === 'reviewing_by_department' ? faCheck : faArrowRight}
																		style={{ fontSize: 16 }}
																	/>
																}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() => {
																	if (isFormCompleted) {
																		submitToNextStep({
																			variables: {
																				id: data?.id,
																				requestDate: paperGeneralDateString(data?.createdAt, 'ឆ្នាំ'),
																				solarDate: paperSolarDateString(),
																				lunarDate: paperLunarDateString(),
																			},
																		});
																	}
																}}
															>
																{t(data?.adminStatus === 'reviewing_by_department' ? 'Done' : 'Submit')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									)}
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{loading && (
									<>
										<Loading />
									</>
								)}
								{!loading && !data && (
									<>
										<NoData />
									</>
								)}
								{!loading && data && (
									<>
										<SimpleGrid columns={1}>
											<SimpleGrid columns={[1, null, 2]} spacingX={8}>
												<LabelDetail mb={1} mt={0} labelWidth={LABEL_WIDTH} direction="row" label="Number" titleDetail={data?.number} />
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Created By"
													childDetail={<CompanyName company={data?.company} />}
												/>
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Started At"
													childDetail={<LocalizedDate dateString={data?.createdAt} />}
												/>
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Status"
													childDetail={<IntermediaryStatus status={data?.adminStatus} />}
												/>
											</SimpleGrid>
											<SimpleGrid columns={[1, null, 2]} spacingX={8}>
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Branch Manager Name(Khmer)"
													titleDetail={data?.managerName}
												/>
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Branch Manager Name(English)"
													titleDetail={data?.managerNameEn}
												/>
											</SimpleGrid>
											<SimpleGrid columns={[1, null, 2]} spacingX={8}>
												<Box w="100%">
													<LabelDetail
														mb={1}
														mt={0}
														labelWidth={LABEL_WIDTH}
														direction="row"
														label="Gender"
														titleDetail={t({ m: 'Male', f: 'Female' }[data?.gender])}
													/>
													<LabelDetail
														mb={1}
														mt={0}
														labelWidth={LABEL_WIDTH}
														direction="row"
														label="Nomination"
														childDetail={
															<Badge
																variant="solid"
																colorScheme={t({ official: 'blue', temporary: 'orange' }[data?.nominationType])}
															>
																{t({ official: 'Official', temporary: 'Temporary' }[data?.nominationType])}
															</Badge>
														}
													/>
													<LabelDetail
														mb={1}
														mt={0}
														labelWidth={LABEL_WIDTH}
														label="Working Contract"
														childDetail={
															<DownloadAndViewButton
																url={data?.workingContract?.url}
																size={data?.workingContract?.size}
																marginLeft={0}
															/>
														}
													/>
												</Box>
												<Box w="100%">
													{data?.idDocumentType === 'n' && (
														<>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth={LABEL_WIDTH}
																direction="row"
																label="National ID Card Number"
																childDetail={data?.idDocumentNumber}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth={LABEL_WIDTH}
																label="National ID Card Photo"
																childDetail={
																	<DownloadAndViewButton
																		url={data?.idDocument?.url}
																		size={data?.idDocument?.size}
																		marginLeft={0}
																	/>
																}
															/>
														</>
													)}
													{data?.idDocumentType === 'p' && (
														<>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth={LABEL_WIDTH}
																direction="row"
																label="Passport Number"
																childDetail={data?.idDocumentNumber}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth={LABEL_WIDTH}
																label="Passport Photo"
																childDetail={
																	<DownloadAndViewButton
																		url={data?.idDocument?.url}
																		size={data?.idDocument?.size}
																		marginLeft={0}
																	/>
																}
															/>
														</>
													)}
												</Box>
											</SimpleGrid>
										</SimpleGrid>
										<Divider mb={2} mt={4} />
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<LabelDetail
												mb={0}
												mt={0}
												labelWidth={LABEL_WIDTH}
												label="Decision"
												childDetail={
													<UpdateIntermediaryBranchManagerChangeStatus
														id={data?.id}
														status={data?.approvalStatus}
														onCallback={(e) => setData(e)}
													/>
												}
											/>
											{data?.adminStatus === 'completed' && data?.completedLetter?.url && (
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={LABEL_WIDTH}
													label="Acknowledgement Letter"
													childDetail={<DownloadAndViewButton url={c.baseFilePath + data?.completedLetter?.url} marginLeft={0} />}
												/>
											)}
											{data?.approvalStatus === 'rejected' && (
												<LabelDetail
													mb={0}
													mt={0}
													labelWidth={LABEL_WIDTH}
													label="Reason of Rejection"
													childDetail={<Text color="red.400">{t(data?.description)}</Text>}
												/>
											)}
										</SimpleGrid>
										<Divider mb={4} mt={4} />
										<LabelDetail
											mb={0}
											mt={2}
											label="Branch"
											childDetail={
												<Box w="100%" mt={1}>
													<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
														<Table size="sm">
															<Thead>
																<Tr>
																	<TableHead title="Branch Name(Khmer)" />
																	<TableHead title="Branch Name(English)" />
																	<TableHead title="Branch Manager Name(Khmer)" />
																	<TableHead title="Branch Manager Name(English)" />
																</Tr>
															</Thead>
															<Tbody>
																{data?.branches?.map((item, index) => {
																	return (
																		<Tr key={`t-r-${index}`}>
																			<TableData pt={3} pb={3} title={item?.name || item?.nameLatin} />
																			<TableData pt={3} pb={3} title={item?.nameLatin || item?.name} />
																			<TableData pt={3} pb={3} title={item?.managerName || item?.managerNameLatin} />
																			<TableData pt={3} pb={3} title={item?.managerNameLatin || item?.managerName} />
																		</Tr>
																	);
																})}
															</Tbody>
															<Tfoot>
																<Tr>
																	<TableHead title="Branch Name(Khmer)" />
																	<TableHead title="Branch Name(English)" />
																	<TableHead title="Branch Manager Name(Khmer)" />
																	<TableHead title="Branch Manager Name(English)" />
																</Tr>
															</Tfoot>
														</Table>
													</TableContainer>
												</Box>
											}
										/>
									</>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
		</>
	);
}

export default IntermediaryPartnerRequest;
