/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../Other';
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import DistributionSystemAgentList from './DistributionSystemAgentList';
import DistributionSystemAgentPartner from './DistributionSystemAgentPartner';
import c from '../../../constant';
import DistributionSystemAgentListPrint from './DistributionSystemAgentListPrint';
import DistributionSystemAgentPartnerPrint from './DistributionSystemAgentPartnerPrint';

const Q_DETAIL = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			id
			type
		}
	}
`;

function DistributionSystemAgent({ id, ...props }) {
	const { t } = useTranslation();
	const [fetchDetail, { data }] = useLazyQuery(Q_DETAIL);
	const [agents, setAgents] = useState([]);
	const [partners, setPartners] = useState([]);

	useEffect(() => {
		id && fetchDetail({ variables: { id } });
	}, [id]);

	function isAllowedCompany(type) {
		return isAllowedIntermediaryCompany(type) || isAllowedInsuranceCompany(type);
	}

	function isAllowedIntermediaryCompany(type) {
		return type === c.insuranceCompanyType.agent.value;
	}

	function isAllowedInsuranceCompany(type) {
		return type === c.insuranceCompanyType.general.value || type === c.insuranceCompanyType.life.value || type === c.insuranceCompanyType.micro.value;
	}

	return (
		<>
			{isAllowedCompany(data?.getCompanyById?.type) && (
				<>
					<Flex className="hidden-print" mt={6} {...props}>
						<Box width="100%">
							<SectionHeader title={t('Agent')} />
							<Tabs mt={4} defaultIndex={0} variant="enclosed">
								<TabList>
									<Tab>{t('Agent')}</Tab>
									<Tab>
										{isAllowedIntermediaryCompany(data?.getCompanyById?.type) && t('Partner with Insurance Company')}
										{isAllowedInsuranceCompany(data?.getCompanyById?.type) && t('Partner with Intermediary Company')}
									</Tab>
								</TabList>
								<TabPanels>
									<TabPanel pl={2} pr={2} pb={0}>
										<DistributionSystemAgentList id={data?.getCompanyById?.id} onDataChanged={setAgents} />
									</TabPanel>
									<TabPanel pl={2} pr={2} pb={0}>
										<DistributionSystemAgentPartner
											companyId={data?.getCompanyById?.id}
											companyType={data?.getCompanyById?.type}
											onDataChanged={setPartners}
										/>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</Box>
					</Flex>
					{agents && <DistributionSystemAgentListPrint list={agents} />}
					{partners && <DistributionSystemAgentPartnerPrint list={partners} companyType={data?.getCompanyById?.type} />}
				</>
			)}
		</>
	);
}

export default DistributionSystemAgent;
