/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	Badge,
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	SimpleGrid,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tooltip,
	Tr,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyName from '../../../../components/CompanyName';
import DownloadAndViewButton from '../../../../components/Files/DownloadAndViewButton';
import LabelDetail from '../../../../components/LabelDetail';
import Loading from '../../../../components/Loading';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import { useTerminationBranchStore } from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import NoData from '../../../../components/NoData';
import PrintLayout from '../../../../components/Print/PrintLayout';
import c from '../../../../constant';
import useMoment from '../../../../hooks/useMoment';
import useStore from '../../../../store';

const Q_DETAIL = gql`
	query get($id: String!) {
		getTerminationBranchRequestById(id: $id) {
			id
			number
			createdAt
			company {
				id
				name
				nameKhmer
				type
				logo
			}
			adminStatus
			completedAt
			completedLetter
			items {
				id
				order
				requestId
				branch {
					id
					name
					nameLatin
					managerName
					managerNameLatin
				}
				managerName
				managerNameLatin
				terminatedDate
				terminationReason
				partners {
					id
					name
					nameKhmer
					type
					logo
				}
			}
		}
	}
`;

const M_SUBMIT = gql`
	mutation submit($id: String!, $requestDate: String, $solarDate: String, $lunarDate: String) {
		submitTerminationBranchRequestReview(id: $id, requestDate: $requestDate, solarDate: $solarDate, lunarDate: $lunarDate)
	}
`;

function IntermediaryTerminationBranchRequestDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location?.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const setPermitted = useTerminationBranchStore((state) => state.setPermitted);
	const { isPermitted } = useTerminationBranchStore((state) => state.isPermitted);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { t } = useTranslation();
	const { paperLunarDateString, paperSolarDateString, paperGeneralDateString } = useMoment();
	const [detailData, setDetailData] = useState(null);
	const [fetchDetailData, { loading, error, data }] = useLazyQuery(Q_DETAIL);
	const [sumitReviewData, { loading: loadingSubmit }] = useMutation(M_SUBMIT);

	useEffect(() => {
		loadDetailData();
	}, [id]);

	useEffect(() => {
		data?.getTerminationBranchRequestById && setDetailData(data?.getTerminationBranchRequestById);
	}, [data]);

	useEffect(() => {
		loadPermission();
	}, [detailData, user]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	const loadDetailData = () => {
		fetchDetailData({ variables: { id } });
	};

	const loadPermission = () => {
		detailData?.adminStatus && user?.position && setPermitted(detailData?.adminStatus, user?.position);
	};

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Branch Termination',
								path: `/intermediary/branch-termination-request?page=1`,
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex w="100%" justifyContent="space-between" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Detail')}
									</Text>
									<Flex justifyContent="flex-end" alignItems="center">
										<>
											{detailData?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingSubmit}
																isDisabled={detailData?.items?.filter((el) => el?.approvalStatus === null)?.length !== 0}
																rightIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() =>
																	sumitReviewData({
																		variables: {
																			id: detailData?.id,
																			requestDate: paperGeneralDateString(data?.startedAt, 'ឆ្នាំ'),
																			solarDate: paperSolarDateString(),
																			lunarDate: paperLunarDateString(),
																		},
																	})
																		.then(({ data }) => {
																			loadDetailData();
																		})
																		.catch((error) => {
																			toast({
																				title: t(error?.message ? error?.message : 'something went wrong'),
																				status: 'error',
																				isClosable: true,
																				position: 'bottom-right',
																			});
																		})
																}
															>
																{t('Done')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									</Flex>
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{loading && (
									<>
										<Loading />
									</>
								)}
								{!loading && !detailData?.id && (
									<>
										<NoData />
									</>
								)}
								{!loading && detailData?.id && (
									<Box w="100%">
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<LabelDetail mb={1} mt={0} labelWidth={32} direction="row" label="Number" titleDetail={detailData?.number} />
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Created By"
												childDetail={<CompanyName company={detailData?.company} />}
											/>
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Submitted At"
												childDetail={<LocalizedDate dateString={detailData?.createdAt} />}
											/>
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Status"
												childDetail={<IntermediaryStatus status={detailData?.adminStatus} />}
											/>
											{detailData?.completedAt && (
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={32}
													direction="row"
													label="Completed At"
													childDetail={<LocalizedDate dateString={detailData?.completedAt} />}
												/>
											)}
											{detailData?.completedLetter?.url && (
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth=""
													label="Approval Letter"
													childDetail={
														<>
															{detailData?.completedLetter?.url ? (
																<DownloadAndViewButton marginLeft={0} url={c.baseFilePath + detailData?.completedLetter?.url} />
															) : (
																<IntermediaryStatus status="N/A" />
															)}
														</>
													}
												/>
											)}
										</SimpleGrid>
										<Divider mt={4} mb={4} />
										<Box width="100%">
											<TableContainer>
												<Table size="sm">
													<Thead>
														<Tr>
															<Th>{t('Branch Name')}</Th>
															<Th>{t('Manager Name')}</Th>
															<Th>{t('Partnership Termination')}</Th>
															<Th>{t('Termination Date')}</Th>
															<Th isNumeric>{t('Description')}</Th>
														</Tr>
													</Thead>
													<Tbody>
														{!loading && detailData?.items?.length <= 0 && (
															<>
																<Tr>
																	<Td colSpan={100}>
																		<Flex justifyContent="flex-start" alignItems="center">
																			{t('No Data')}
																		</Flex>
																	</Td>
																</Tr>
															</>
														)}
														{!loading &&
															detailData?.items?.map((el, i) => {
																return (
																	<Tr key={i}>
																		<Td>
																			{lang === 'kh'
																				? el?.branch?.name || el?.branch?.nameLatin
																				: el?.branch?.nameLatin || el?.branch?.name}
																		</Td>
																		<Td>
																			{lang === 'kh'
																				? el?.managerName || el?.managerNameLatin
																				: el?.managerNameLatin || el?.managerName}
																		</Td>
																		<Td>
																			<Flex justifyContent="flex-start" alignItems="center">
																				<VStack align="flex-start">
																					{el?.partners?.map((e) => (
																						<Stack align="flex-start">
																							<CompanyName company={e} />
																						</Stack>
																					))}
																				</VStack>
																			</Flex>
																		</Td>
																		<Td>
																			<LocalizedDate dateString={el?.terminatedDate} dateFormat={c.dateOnlyFormat} />
																		</Td>
																		<Td isNumeric>
																			<Flex justifyContent="flex-end" alignItems="center">
																				<Tooltip label={el?.terminationReason}>
																					{el?.terminationReason ? (
																						<>
																							<Text isTruncated width={40}>
																								{el?.terminationReason}
																							</Text>
																						</>
																					) : (
																						<>
																							<Badge>{t('None')}</Badge>
																						</>
																					)}
																				</Tooltip>
																			</Flex>
																		</Td>
																	</Tr>
																);
															})}
													</Tbody>
													<Tfoot>
														<Th>{t('Branch Name')}</Th>
														<Th>{t('Manager Name')}</Th>
														<Th>{t('Partnership Termination')}</Th>
														<Th>{t('Termination Date')}</Th>
														<Th isNumeric>{t('Description')}</Th>
													</Tfoot>
												</Table>
											</TableContainer>
										</Box>
									</Box>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
		</>
	);
}

export default IntermediaryTerminationBranchRequestDetail;
