/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import SaleStaffModal from '../../../containers/CompanySaleProfessional/component/SaleStaffModal';
import Loading from '../../../components/Loading';
import NoData from '../../../components/NoData';
import LocalizedDate from '../../LocalizedDate';
import CompanyName from '../../CompanyName';
import c from '../../../constant';

const Q_INT_SALE_PROFESSIONALS = gql`
	query get($intermediaryId: String!, $pagerInput: PagerInput!) {
		getIntermediarySaleProfessionals(intermediaryId: $intermediaryId, pagerInput: $pagerInput) {
			totalRows
			totalPages
			list {
				id
				intermediary {
					id
					name
					nameKhmer
					type
					logo
				}
				saleProfessional {
					id
					idCardNumber
					firstName
					lastName
					firstNameEn
					lastNameEn
					gender
					dob
					status
					company {
						id
						name
						nameKhmer
						type
						logo
					}
				}
				branch {
					id
					name
					nameLatin
				}
				partner {
					id
					name
					nameKhmer
					type
					logo
				}
				deleted
				deletedAt
				deletedBy
			}
		}
	}
`;

let limit = 10;
let offset = 0;

function CompanyAgentSaleStaff({ id = null }) {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [fetchSaleProfessionals, { loading, data }] = useLazyQuery(Q_INT_SALE_PROFESSIONALS);
	const [openData, setOpenData] = useState(null);

	useEffect(() => {
		loadSaleProfessionals();
	}, [id]);

	function onPageChange(value) {
		offset = value * limit;
		loadSaleProfessionals();
	}

	function loadSaleProfessionals() {
		const variables = {
			intermediaryId: id,
			pagerInput: { limit, offset },
		};
		fetchSaleProfessionals({ variables });
	}

	function getStatus(status) {
		switch (status) {
			case 'active':
				return <Badge colorScheme="green">{t('ACTIVE')}</Badge>;
			case 'terminated':
				return <Badge colorScheme="red">{t('Terminated')}</Badge>;
			default:
				return null;
		}
	}

	return (
		<>
			{loading && (
				<>
					<Loading />
				</>
			)}
			{!loading && data?.getIntermediarySaleProfessionals?.list?.length <= 0 && (
				<>
					<NoData />
				</>
			)}
			{!loading && data?.getIntermediarySaleProfessionals?.list?.length > 0 && (
				<Box width="100%" padding="16px">
					<Table variant="simple" size="md">
						<Thead>
							<Tr>
								<Th>{t('ID Card Number')}</Th>
								<Th>{t('Name')}</Th>
								<Th>{t('Gender')}</Th>
								<Th>{t('DOB')}</Th>
								<Th>{t('Branch')}</Th>
								<Th>{t('Insurance Company Name')}</Th>
								<Th isNumeric>{t('Status')}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.getIntermediarySaleProfessionals?.list?.map((item, index) => {
								return (
									<Tr
										key={index}
										cursor="pointer"
										_hover={{ bg: 'gray.100' }}
										onMouseDown={() => {
											onOpen();
											setOpenData(item?.saleProfessional);
										}}
									>
										<Td>{item?.saleProfessional?.idCardNumber}</Td>
										<Td>{item?.saleProfessional?.lastName + ' ' + item?.saleProfessional?.firstName}</Td>
										<Td>{t(item?.saleProfessional?.gender)}</Td>
										<Td>
											<LocalizedDate dateString={item?.saleProfessional?.dob} dateFormat={c.dateOnlyFormat} />
										</Td>
										<Td>
											<Text>{item?.branch?.name}</Text>
										</Td>
										<Td>
											<CompanyName company={item?.saleProfessional?.company} />
										</Td>
										<Td isNumeric>
											{getStatus(item?.saleProfessional?.status === 'approved' ? 'active' : item?.saleProfessional?.status)}
										</Td>
									</Tr>
								);
							})}
						</Tbody>
						<Tfoot>
							<Tr>
								<Th>{t('ID Card Number')}</Th>
								<Th>{t('Name')}</Th>
								<Th>{t('Gender')}</Th>
								<Th>{t('DOB')}</Th>
								<Th>{t('Branch')}</Th>
								<Th>{t('Insurance Company Name')}</Th>
								<Th isNumeric>{t('Status')}</Th>
							</Tr>
						</Tfoot>
					</Table>
					<Flex justifyContent="flex-end" w="100%" mt="4">
						{data?.getIntermediarySaleProfessionals?.totalPages > 1 && (
							<ReactPaginate
								previousLabel={<ChevronLeftIcon fontSize="20px" />}
								nextLabel={<ChevronRightIcon fontSize="20px" />}
								breakLabel={'...'}
								breakClassName={'break-me'}
								disableInitialCallback={true}
								pageCount={data?.getIntermediarySaleProfessionals?.totalPages}
								marginPagesDisplayed={2}
								pageRangeDisplayed={3}
								onPageChange={({ selected }) => onPageChange(selected)}
								containerClassName={'pagination'}
								activeClassName={'active'}
								initialPage={offset / limit}
							/>
						)}
					</Flex>
					<SaleStaffModal isOpen={isOpen} onClose={() => onClose()} data={openData} />
				</Box>
			)}
		</>
	);
}

export default CompanyAgentSaleStaff;
