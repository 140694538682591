/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Table, TableContainer, Tbody, Text, Tfoot, Thead, Tr, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../../components/Loading';
import NoData from '../../../../../components/NoData';
import TableData from '../../../../../components/TableData';
import TableHead from '../../../../../components/TableHead';
import SaleProfessionalApprovalStatus from './SaleProfessionalApprovalStatus';
import useStore from '../../../../../store';

const Q_GET = gql`
	query get($id: String!) {
		getPartnerDetailSaleProfessionalsByRequestPartnerDetailId(id: $id) {
			id
			requestDetailId
			saleProfessional {
				id
				idCardNumber
				firstName
				lastName
				firstNameEn
				lastNameEn
				gender
			}
			branch {
				id
				name
				nameLatin
			}
			approvalStatus
			rejectionReason
		}
	}
`;

function SaleProfessionalList({ id = null, onCallback = () => null, emptyLabel = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [saleStaffs, setSaleStaffs] = useState(null);
	const [loadData, { error, loading, data }] = useLazyQuery(Q_GET);

	useEffect(() => {
		data && setSaleStaffs(data?.getPartnerDetailSaleProfessionalsByRequestPartnerDetailId);
	}, [data]);

	useEffect(() => {
		id && loadData({ variables: { id } });
	}, [id]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		let isCompleted = saleStaffs?.reduce((pre, cur) => {
			let localPre = pre;
			if (cur?.approvalStatus === null) {
				localPre = false;
			}
			return localPre;
		}, true);
		onCallback({ id, isCompleted });
	}, [saleStaffs]);

	return (
		<>
			{loading && (
				<Box width="100%">
					<Loading />
				</Box>
			)}
			{!loading && saleStaffs?.length <= 0 && (
				<Box width="100%">
					<>
						<>{emptyLabel ? <Text color="gray.500">{t(emptyLabel)}</Text> : <NoData />}</>
					</>
				</Box>
			)}
			{!loading && saleStaffs?.length > 0 && (
				<Box width="100%">
					<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
						<Table variant="simple">
							<Thead>
								<Tr>
									<TableHead title="National ID Card Number" />
									<TableHead title="First Name" />
									<TableHead title="Last Name" />
									<TableHead title="First Name En" />
									<TableHead title="Last Name En" />
									<TableHead title="Branch" />
									<TableHead title="Status" />
									<TableHead title="Description" isNumeric />
								</Tr>
							</Thead>
							<Tbody>
								{saleStaffs?.map((item, index) => {
									return (
										<Tr key={index}>
											<TableData pt={3} pb={3} child={<Text fontWeight="semibold">{item?.saleProfessional?.idCardNumber}</Text>} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.firstName} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.lastName} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.firstNameEn} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.lastNameEn} />
											<TableData
												pt={3}
												pb={3}
												title={
													lang === 'kh'
														? item?.branch?.name || item?.branch?.nameLatin
														: item?.branch?.nameLatin || item?.branch?.name
												}
											/>
											<TableData
												pt={3}
												pb={3}
												child={
													<SaleProfessionalApprovalStatus
														id={item?.id}
														approvalStatus={item?.approvalStatus}
														onCallback={(el) =>
															setSaleStaffs(
																saleStaffs?.reduce((pre, cur) => {
																	let localPre = pre;
																	let localCur = cur?.id === el?.id ? el : cur;
																	localPre.push(localCur);
																	return localPre;
																}, [])
															)
														}
													/>
												}
											/>
											<TableData
												pt={3}
												pb={3}
												title={item?.approvalStatus === false && item?.rejectionReason}
												titleColor="red.400"
												isNumeric
											/>
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot>
								<Tr>
									<TableHead title="National ID Card Number" />
									<TableHead title="First Name" />
									<TableHead title="Last Name" />
									<TableHead title="First Name En" />
									<TableHead title="Last Name En" />
									<TableHead title="Branch" />
									<TableHead title="Status" />
									<TableHead title="Description" isNumeric />
								</Tr>
							</Tfoot>
						</Table>
					</TableContainer>
				</Box>
			)}
		</>
	);
}

export default SaleProfessionalList;
