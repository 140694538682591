/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from '@apollo/client';
import { Box, Button, Flex, Grid, GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import CompanyFormField from '../../components/form/CompanyFormField';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import FileFormField from '../../components/form/FileFormField';
import DateFormField from '../form/DateFormField';
import CompanyPickerModal from '../../components/Modal/CompanyPickerModal';
import c from '../../constant';

const M_CREATE_CERTIFICATE = gql`
	mutation create(
		$date: String!
		$model: String!
		$partnerType: String!
		$partnerId: String!
		$companyId: String!
		$attachmentFile: Upload
		$attachmentSize: Float
	) {
		addCompanyPartner(
			date: $date
			model: $model
			partnerType: $partnerType
			partnerId: $partnerId
			companyId: $companyId
			attachmentFile: $attachmentFile
			attachmentSize: $attachmentSize
		)
	}
`;

const validationSchema = Yup.object().shape({
	companyName: Yup.string().when('selectedCompany', {
		is: (selectedCompany) => !selectedCompany,
		then: Yup.string().required('required'),
		otherwise: Yup.string(),
	}),
	selectedCompany: Yup.string(),
	partnerType: Yup.string(),
	model: Yup.string(),
	date: Yup.string(),
	attachment: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize;
		} else {
			return true;
		}
	}),
});

function AddCompanyPartner({ companyId = null, isInsuranceAgent = false, isOpen = false, onClose = () => null, callback = () => null, partneredList = null, directOnly=false }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [addPartner, { loading, error, data }] = useMutation(M_CREATE_CERTIFICATE);
	const [showCompanyPicker, setShowCompanyPicker] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [companyType, setCompanyType] = useState(null);

	useEffect(() => {
		if (data) {
			if (data?.addCompanyPartner) {
				toast({
					title: t('Company partner addded successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Company partner addded successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function isDisableCompanyType(type) {
		let isDisabled = false;
		partneredList?.map((item) => {
			if (item?.companyType === type) {
				isDisabled = true;
			}
		});
		return isDisabled;
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								partnerType: '',
								model: '',
								date: null,
							}}
							onSubmit={(values) => {
								if (values?.partnerType) {
									const variables = {
										date: values?.date,
										model: values?.model,
										partnerType: values?.partnerType,
										partnerId: values?.selectedCompany,
										companyId: companyId,
										attachmentFile: values?.attachment,
										attachmentSize: values?.attachment?.size,
									};
									addPartner({ variables });
								}
							}}
						>
							{({ values, ...props }) => {
								return (
									<Form>
										<Box mt="3">
											<Grid templateColumns="repeat(1, 1fr)" gap={6}>
												<GridItem w="100%">
													<RadioFormField
														required
														label="Company Type"
														name="partnerType"
														radios={[
															{ label: 'LIFE INSURANCE', value: 'life', isDisabled: isDisableCompanyType('life') },
															{ label: 'GENERAL INSURANCE', value: 'general', isDisabled: isDisableCompanyType('general') },
															{ label: 'MICRO INSURANCE', value: 'micro', isDisabled: isDisableCompanyType('micro') },
														]}
														callbackValueChange={(e) => setCompanyType(e)}
													/>
													{selectedCompany?.id && (
														<CompanyFormField
															{...props}
															label="Company/Institution Name"
															name="selectedCompany"
															required
															callBackClear={() => setSelectedCompany(null)}
															callBackList={() => setShowCompanyPicker(true)}
															selectedCompany={selectedCompany}
														/>
													)}
													{!selectedCompany?.id && (
														<TextFormField
															{...props}
															label="Company/Institution Name"
															name="companyName"
															required
															disabled={true}
															rightElement={
																<Button
																	onClick={() => setShowCompanyPicker(true)}
																	leftIcon={
																		<FontAwesomeIcon
																			style={{
																				fontSize: 16,
																				cursor: 'pointer',
																			}}
																			icon={faList}
																		/>
																	}
																	mt="7px"
																	variant="solid"
																	h="42px"
																	mr="3px"
																	borderRadius={c.borderRadius}
																>
																	{t('List')}
																</Button>
															}
														/>
													)}
													<RadioFormField
														required
														label="Model"
														name="model"
														radios={[
															{ label: 'direct', value: 'direct' },
															{ label: 'Referral', value: 'ref', isDisabled:directOnly },
														]}
													/>
													<DateFormField required name="date" label="Date" />
													<FileFormField name="attachment" label="Attachment" maxFileSize={c.maxFileSize} />
												</GridItem>
											</Grid>
											<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center" float="right">
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													bg="primary.dark"
													_hover={{ bg: 'primary.dark' }}
													color="white"
													type="submit"
													isLoading={loading}
													loadingText={t('Add')}
													spinnerPlacement="start"
												>
													{t(`Add`)}
												</Button>
											</Flex>
										</Box>
									</Form>
								);
							}}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>

			{showCompanyPicker && <CompanyPickerModal onClose={() => setShowCompanyPicker(false)} callBack={(e) => setSelectedCompany(e)} />}
		</>
	);
}

export default AddCompanyPartner;
