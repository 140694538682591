/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Badge, Tooltip } from '@chakra-ui/react';
import { faEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import PrintLayout from '../../components/Print/PrintLayout';
import AccountComponent from '../../components/Company/Account/Account';
import Information from '../../components/Company/News/Information';
import LicensedProIndividual from '../../components/Company/News/LicensedProIndividual';
import Shareholder from '../../components/Company/News/Shareholder';
import Governance from '../../components/Company/News/Governance';
import ContactStaff from '../../components/Company/News/ContactStaff';
import DistributionSystemRegisteredOffice from '../../components/Company/DistributionSystem/DistributionSystemRegisteredOffice';
import DistributionSystemCertificate from '../../components/Company/DistributionSystem/DistributionSystemCertificate';
import DistributionSystemAgent from '../../components/Company/DistributionSystem/DistributionSystemAgent';
import Report from '../../components/Company/Operation/Report';
import ReportInlinePrint from '../../components/Company/Operation/ReportInlinePrint';
import Proposal from '../../components/Company/Operation/Proposal';
import ProposalInlinePrint from '../../components/Company/Operation/ProposalInlinePrint';
import DistributionSystemBrokerPartner from '../../components/Company/DistributionSystem/DistributionSystemBrokerPartner';
import useStore from '../../store';
import c from '../../constant';
import DistributionSystemSaleStaff from '../../components/Company/DistributionSystem/DistributionSystemSaleStaff';

const Q_COUNT = gql`
	query get($companyId: String!, $companyCertificateType: String!) {
		getNearlyDueCompanyCertificateValidityCount(companyId: $companyId, companyCertificateType: $companyCertificateType)
	}
`;

const keyCompanyDetailLastTabIndex = 'companyDetailLastTabIndex';

function Update() {
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const { branchValidityAlertCount } = useStore((state) => state.branchValidityAlertCount);
	const setBranchValidityAlertCount = useStore((state) => state.setBranchValidityAlertCount);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const [reports, setReport] = useState(null);
	const [proposals, setProposals] = useState(null);
	const [getCount, { data }] = useLazyQuery(Q_COUNT);

	useEffect(() => {
		getCount({ variables: { companyId: id, companyCertificateType: 'b' } });
	}, [id]);

	useEffect(() => {
		console.log('branchValidityAlertCounts', branchValidityAlertCount);
	}, [branchValidityAlertCount]);

	useEffect(() => {
		if (data) {
			setBranchValidityAlertCount(data?.getNearlyDueCompanyCertificateValidityCount?.count);
		}
	}, [data]);

	function handlePagePrint() {
		var element1 = document.getElementById('report-print');
		element1.classList.add('hidden-print');

		var element2 = document.getElementById('proposal-print');
		element2.classList.add('hidden-print');

		window.onafterprint = (event) => {
			var element1 = document.getElementById('report-print');
			element1.classList.remove('hidden-print');

			var element2 = document.getElementById('proposal-print');
			element2.classList.remove('hidden-print');
		};
		window.print();
	}

	function handleProposalPrint() {
		var element1 = document.getElementById('page-print');
		element1.classList.add('hidden-print');

		var element2 = document.getElementById('report-print');
		element2.classList.add('hidden-print');

		window.onafterprint = (event) => {
			var element1 = document.getElementById('page-print');
			element1.classList.remove('hidden-print');

			var element2 = document.getElementById('report-print');
			element2.classList.remove('hidden-print');
		};
		window.print();
	}

	function handleReportPrint() {
		var element1 = document.getElementById('page-print');
		element1.classList.add('hidden-print');

		var element2 = document.getElementById('proposal-print');
		element2.classList.add('hidden-print');

		window.onafterprint = (event) => {
			var element1 = document.getElementById('page-print');
			element1.classList.remove('hidden-print');

			var element2 = document.getElementById('proposal-print');
			element2.classList.remove('hidden-print');
		};
		window.print();
	}

	function storeLastTabIndex(value) {
		localStorage.setItem(keyCompanyDetailLastTabIndex, value);
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: t('Insurance Institution'),
								path: '/company',
							},
							{
								name: t('Detail'),
								path: '#',
							},
						]}
					/>
					<Center id="page-print" mt={5}>
						<Box w="100%" bg="white" borderRadius={c.borderRadius} border="1px solid #dbdbdb" boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)">
							<Flex p="4" pt="2" pb="2" mb="4" bg="#FAFAFA" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
								<Text fontSize="x-large" fontWeight="bold">
									{t('Company Detail')}
								</Text>
								<Flex alignItems="center" className="hidden-print">
									<Button
										mr="2"
										onClick={handlePagePrint}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
										<Link to={`/company/update?id=${id}`}>
											<Button
												leftIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
											>
												{t('Edit')}
											</Button>
										</Link>
									)}
								</Flex>
							</Flex>
							<Tabs defaultIndex={0} size="md" variant="enclosed" ml={4} mr={4}>
								<TabList className="hidden-print">
									<Tab onClick={() => storeLastTabIndex('0')}>{t('Information')}</Tab>
									<Tab onClick={() => storeLastTabIndex('1')}>
										<>
											{t('Distribution System')}
											<>
												{branchValidityAlertCount > 0 && (
													<>
														<Tooltip label={t('Need(s) Renewal')}>
															<Badge colorScheme="red" variant="solid" ml={1}>
																{branchValidityAlertCount}
															</Badge>
														</Tooltip>
													</>
												)}
											</>
										</>
									</Tab>
									<Tab onClick={() => storeLastTabIndex('2')}>{t('Operation')}</Tab>
									<Tab onClick={() => storeLastTabIndex('3')}>{t('Complaint')}</Tab>
									<Tab
										cursor={!(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact) ? 'not-allowed' : 'pointer'}
										isDisabled={!(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact)}
										onClick={() => storeLastTabIndex('4')}
									>
										{t('Account')}
									</Tab>
								</TabList>
								<TabPanels p={4} pb={8}>
									<TabPanel>
										<Information id={id} />
										<Shareholder id={id} />
										<Governance id={id} />
										<LicensedProIndividual id={id} />
										<ContactStaff id={id} />
									</TabPanel>
									<TabPanel>
										<DistributionSystemRegisteredOffice id={id} />
										<DistributionSystemCertificate id={id} />
										<DistributionSystemSaleStaff id={id} />
										<DistributionSystemAgent id={id} />
										<DistributionSystemBrokerPartner id={id} />
									</TabPanel>
									<TabPanel>
										<Proposal companyId={id} onPrint={handleProposalPrint} onDataChanged={(data) => setProposals(data)} />
										<Report companyId={id} onPrint={handleReportPrint} onDataChanged={(data) => setReport(data)} />
									</TabPanel>
									<TabPanel>
										<Text>{t('No Data')}</Text>
									</TabPanel>
									<TabPanel>
										<AccountComponent id={id} />
									</TabPanel>
								</TabPanels>
							</Tabs>
						</Box>
					</Center>
				</Container>
			</>
			<Flex id="report-print">
				<ReportInlinePrint data={reports} />
			</Flex>
			<Flex id="proposal-print">
				<ProposalInlinePrint data={proposals} />
			</Flex>
		</PrintLayout>
	);
}

export default Update;
