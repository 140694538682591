import React, { useEffect, useState } from "react";
import {
    Spinner, Box, Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel
} from "@chakra-ui/react";
import axios from "axios"
import ReactJson from 'react-json-view'
import moment from "moment"
import c from "../../../../constant"
function LogsViewer({ logsFile }) {
    const [logs, setLogs] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        moment.locale('en')
        if (logsFile) {
            setLoading(true)
            axios.get(logsFile)
                .then(response => {

                    let temp = []
                    if (response?.data?.level) {
                        temp.push(response?.data)
                    } else {
                        let tempSplit = response?.data.split("}")
                        tempSplit?.map((item) => {
                            try {
                                temp.push(JSON.parse(item + "}"))
                            } catch (error) {

                            }
                        })
                    }
                    setLogs(temp)
                    setLoading(false)
                })
                .catch((err) => console.log(`Error: ${err}`))
        }
    }, [logsFile])

    function getLevelColor(level) {
        switch (level) {
            case "error":
                return '#c0392b'
            case "warn":
                return '#f39c12'
            case "info":
                return '#bdc3c7'
            case "http":
                return '#27ae60'
            case "verbose":
                return '#16a085'
            case "debug":
                return '#2c3e50'
            case "silly":
                return '#8e44ad'
            default:
                break;
        }
    }

    return (
        <>
            {loading && <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />}
            {!loading && <Accordion allowMultiple>
                {(logs?.map((item, index) => {
                    return (
                        <AccordionItem key={index}>
                            <h2>
                                <AccordionButton>
                                    <Flex as='span' flex='1' textAlign='left'>
                                        <Box w="150px">
                                            {moment(item?.timestamp).format(c.excelDateTimeFormat)}
                                        </Box>
                                        <Text w="64px" fontWeight="bold" color={getLevelColor(item?.level)}>
                                            [{item?.level?.toUpperCase()}]
                                        </Text>
                                        <Text color={getLevelColor(item?.level)}>
                                            {
                                                item?.message
                                            }
                                        </Text>
                                        <Text ml="4">
                                            {
                                                item?.username && `username: ${item?.username}`
                                            }
                                        </Text>
                                    </Flex>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <ReactJson src={item} theme="rjv-default" displayDataTypes={false} />
                            </AccordionPanel>
                        </AccordionItem>
                    )
                }))}
            </Accordion>}
        </>
    );
}

export default LogsViewer;

