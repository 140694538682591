/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Button, Center, Container, Flex, Table, TableContainer, Tbody, Tfoot, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight, faPrint, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyName from '../../../../components/CompanyName';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryFilter from '../components/FilterIntermediary';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import IntermediaryTab from '../components/IntermediaryTab';
import NoData from '../../../../components/NoData';
import PrintLayout from '../../../../components/Print/PrintLayout';
import TableData from '../../../../components/TableData';
import TableHead from '../../../../components/TableHead';
import TotalRow from '../../../../components/TotalRow';
import datePickerFromToToDate from '../../../../utils/datePickerFromToToDate';
import c from '../../../../constant';

const Q_COUNT = gql`
	query {
		getCountTerminationBranchRequest
	}
`;

const Q_LIST = gql`
	query get(
		$pagerInput: PagerInput!
		$companyName: String
		$companyId: String
		$number: Float
		$fromDate: DateTime
		$toDate: DateTime
		$adminStatus: String
	) {
		getTerminationBranchRequests(
			pagerInput: $pagerInput
			companyName: $companyName
			companyId: $companyId
			number: $number
			fromDate: $fromDate
			toDate: $toDate
			adminStatus: $adminStatus
		) {
			list {
				id
				number
				createdAt
				adminStatus
				company {
					id
					name
					nameKhmer
					type
					logo
				}
			}
			totalPages
			totalRows
		}
	}
`;

function IntermediaryTerminationBranchRequest() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') || null;
	const companyName = urlParams.get('companyName') || null;
	const companyId = urlParams.get('companyId') || null;
	const number = parseInt(urlParams.get('number'), null);
	const fromDate = urlParams.get('fromDate') || null;
	const toDate = urlParams.get('toDate') || null;
	const adminStatus = urlParams.get('adminStatus') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [fetchCountData, { data: dataCount }] = useLazyQuery(Q_COUNT, { fetchPolicy: 'no-cache' });
	const [fetchListData, { loading, error, data }] = useLazyQuery(Q_LIST, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		loadCount();
	}, []);

	useEffect(() => {
		loadData();
	}, [page, companyName, companyId, number, fromDate, toDate, adminStatus]);

	const loadCount = () => {
		fetchCountData();
	};

	const loadData = () => {
		const limit = 10;
		const offset = parseInt(page ? (page - 1) * limit : 0) || 0;
		const pagerInput = { limit, offset: offset < 0 ? 0 : offset };
		const variables = {
			pagerInput,
			companyName,
			companyId,
			number,
			fromDate: datePickerFromToToDate(fromDate),
			toDate: datePickerFromToToDate(toDate),
			adminStatus,
		};
		fetchListData({ variables });
	};

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function handleStatusChanged(statusValue) {
		let url = `?page=1`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (statusValue) {
			url += `&adminStatus=${statusValue}`;
		}

		push(`/intermediary/branch-termination-request${url}`);
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (adminStatus) {
			url += `&adminStatus=${adminStatus}`;
		}

		push(`/intermediary/branch-termination-request${url}`);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Branch Termination',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" pb="2px" overflow="scroll" minH="54px">
									{dataCount?.getCountTerminationBranchRequest && (
										<IntermediaryTab
											onCallback={handleStatusChanged}
											initTab="all"
											data={[
												{
													label: 'R Office',
													tooltip: 'Reviewing By Office',
													badge: {
														value: dataCount?.getCountTerminationBranchRequest?.countOffice || '0',
													},
													value: 'reviewing_by_office',
													important: 'office',
												},
												{
													label: 'Completed',
													badge: null,
													value: 'completed',
													important: '',
												},
												{
													label: 'All',
													badge: null,
													value: 'all',
													important: '',
												},
											]}
										/>
									)}
								</Flex>
								<Flex justifyContent="center" alignItems="center">
									<Button
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Print')}
									</Button>
									<Button
										onClick={onOpen}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={companyName || companyId || number || (fromDate && toDate) ? 'yellow' : 'gray'}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{!loading && data?.getTerminationBranchRequests?.list?.length <= 0 && (
									<>
										<NoData />
									</>
								)}
								{!loading && data?.getTerminationBranchRequests?.list?.length > 0 && (
									<>
										<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
											<Table variant="simple">
												<Thead>
													<Tr>
														<TableHead title="Number" />
														<TableHead title="Created At" />
														<TableHead title="Company" />
														<TableHead title="Status" isNumeric={true} />
													</Tr>
												</Thead>
												<Tbody>
													{data?.getTerminationBranchRequests?.list?.map((e, i) => (
														<Tr
															_hover={{ bg: 'gray.200' }}
															key={`tr-${i}`}
															cursor="pointer"
															onMouseDown={() => push(`/intermediary/branch-termination-request/detail?id=${e?.id}`)}
														>
															<TableData pt={3} pb={3} title={e?.number} />
															<TableData pt={3} pb={3} child={<LocalizedDate dateString={e?.createdAt} />} />
															<TableData pt={3} pb={3} child={<CompanyName company={e?.company} />} />
															<TableData pt={3} pb={3} child={<IntermediaryStatus status={e?.adminStatus} />} isNumeric={true} />
														</Tr>
													))}
												</Tbody>
												<Tfoot>
													<Tr>
														<TableHead title="Number" />
														<TableHead title="Created At" />
														<TableHead title="Company" />
														<TableHead title="Status" isNumeric={true} />
													</Tr>
												</Tfoot>
											</Table>
										</TableContainer>
										{data?.getTerminationBranchRequests?.totalRows > 0 && (
											<>
												<Flex justifyContent="flex-end" direction="row">
													<TotalRow count={data?.getTerminationBranchRequests?.totalRows} mt={2} />
												</Flex>
											</>
										)}
										{data?.getTerminationBranchRequests?.totalPages > 1 && (
											<>
												<Flex alignItems="flex-end" direction="column" mt={4}>
													<Flex alignItems="flex-end" direction="column">
														<ReactPaginate
															previousLabel={<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 12, cursor: 'pointer' }} />}
															nextLabel={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 12, cursor: 'pointer' }} />}
															breakLabel={'...'}
															breakClassName={'break-me'}
															disableInitialCallback={true}
															pageCount={data?.getTerminationBranchRequests?.totalPages}
															marginPagesDisplayed={2}
															pageRangeDisplayed={3}
															onPageChange={handlePageChanged}
															containerClassName={'pagination'}
															activeClassName={'active'}
															initialPage={(page - 1) | 0}
														/>
													</Flex>
												</Flex>
											</>
										)}
									</>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
			{isOpen && (
				<IntermediaryFilter
					isOpen={true}
					onClose={onClose}
					onCallback={(result) => {
						let url = `?page=1`;

						if (result?.companyName) {
							url += `&companyName=${result?.companyName}`;
						}

						if (result?.companyId) {
							url += `&companyId=${result?.companyId}`;
						}

						if (result?.number) {
							url += `&number=${result?.number}`;
						}

						if (result?.receivedDateFrom && result?.receivedDateTo) {
							url += `&fromDate=${result?.receivedDateFrom}&toDate=${result?.receivedDateTo}`;
						}

						if (adminStatus) {
							url += `&adminStatus=${adminStatus}`;
						}

						push(`/intermediary/branch-termination-request${url}`);
					}}
					companyName={companyName}
					companyId={companyId}
					number={number}
					receivedDateFrom={fromDate}
					receivedDateTo={toDate}
				/>
			)}
		</>
	);
}

export default IntermediaryTerminationBranchRequest;
