import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// import { createBrowserHistory } from "history";
import Layout from './components/Layout';
import LayoutPublic from './components/LayoutPublic';

// pages
import PageNotFound from './containers/PageNotFount';
import Home from './containers/Home/Home';
import Login from './containers/Login/Login';
import CamDigiKeyAuthTokenChecker from './containers/Login/CamDigiKeyAuthTokenChecker';
import Website from './containers/Website/Website';
import ListArticles from './containers/Website/ListArticles';
import NewArticle from './containers/Website/NewArticle';
import EditArticle from './containers/Website/EditArticle';
import PublicServiceList from './containers/Website/PublicServiceList';
import CompanyReportTypeList from './containers/Website/CompanyReportTypeList';
import PublicFormList from './containers/Website/PublicForm/PublicFormList';
import PublicFormDetail from './containers/Website/PublicForm/PublicFormDetail';
import PublicFormAdd from './containers/Website/PublicForm/PublicFormAdd';

import Account from './containers/Account/Account';

// import Users from "./containers/Users/Users"
import CreateNewUser from './containers/Users/Create';
import TransferProposal from './containers/Users/TransferProposal';

import UserList from './containers/Users/List';
import UpdateUser from './containers/Users/Update';

import Complaint from './containers/Complaint/index';
import ComplaintDetail from './containers/Complaint/detail';
import ComplaintReport from './containers/Complaint/report';
import RegisterAdmin from './containers/RegisterAdmin';
import ComplaintType from './containers/Complaint/ComplaintType';
import ComplaintOnInsurance from './containers/ComplaintOnInsurance/index';
import ComplaintOnInsuranceDetail from './containers/ComplaintOnInsurance/detail';

import ChangePassword from './containers/Account/ChangePassword';

import CompanySubmissionReport from './containers/CompanySubmissionReport/index';
import CompanySubmissionReportDetail from './containers/CompanySubmissionReport/detail';

import CompanySubmissionProposal from './containers/CompanySubmissionProposal/index';
import CompanySubmissionProposalListReport from './containers/CompanySubmissionProposal/ListReport';
import CompanySubmissionProposalDetail from './containers/CompanySubmissionProposal/detail';
import AddCompanyProposal from './containers/CompanySubmissionProposal/addCompanyProposal';
import AddCompanyProposalSuccess from './containers/CompanySubmissionProposal/addCompanyProposalSuccess';

import Company from './containers/Company/index';
import CompanyDetail from './containers/Company/detail';
import CompanyUpdate from './containers/Company/update';
import CompanyCreate from './containers/Company/create';

import MailList from './containers/Settings/Mail/index';
import MailContent from './containers/Settings/Mail/content';

import Settings from './containers/Settings/Index';
import DeleteNotification from './containers/Settings/DeleteNotification';
import MaintenanceStatus from './containers/Settings/MaintenanceStatus';

import RequestLeave from './containers/RequestLeave/index';
import RequestLeaveDetail from './containers/RequestLeave/detail';
import RequestLeaveAdd from './containers/RequestLeave/add';
import PDFReport from './containers/RequestLeave/pdfReport';

import LeaveAdjustment from './containers/LeaveAdjustment/index';
import LeaveAdjustmentDetail from './containers/LeaveAdjustment/detail';
import LeaveAdjustmentAdd from './containers/LeaveAdjustment/add';

import Agent from './containers/Agent/index';
import AgentDetail from './containers/Agent/detail';
import AgentRenewalList from './containers/Agent/renewal';

import Payment from './containers/Payment/index';

import ProductList from './containers/Settings/Product/index';
import AddProduct from './containers/Settings/Product/add';

import CreateEmployee from './containers/Employee/Create';
import EmployeeList from './containers/Employee/List';
import EmployeeDetail from './containers/Employee/Detail';
import EmployeeEdit from './containers/Employee/Edit';

import AnnouncementList from './containers/Settings/Announcement/index';
import AddAnnouncement from './containers/Settings/Announcement/add';

import OtherSettingList from './containers/Settings/OtherSetting/index';
import AddOtherSetting from './containers/Settings/OtherSetting/add';
import SalarySetting from './containers/Employee/Salary/Setting';
import SalaryList from './containers/Employee/Salary/List';
import SalaryAdd from './containers/Employee/Salary/Add';
import SalaryEdit from './containers/Employee/Salary/Edit';

import Camdigikey from './containers/Settings/Camdigikey';
import CamdigikeyDetails from './containers/Settings/Camdigikey/details';
import AddLicenseChargeOrFine from './containers/Payment/addLicenseChargeOrFine';
import LicenseOrFineReportDetail from './containers/Payment/licenseOrFineDetail';
import ReportDetail from './containers/Payment/detail';

import WhiteListOrgCamdx from './containers/Settings/WhiteListOrgCamDx/index';
import AddWhiteListOrgCamdx from './containers/Settings/WhiteListOrgCamDx/add';

import DocumentNumber from './containers/DocumentNumber/DocumentNumber';
import ListDocumentWithNumber from './containers/DocumentNumber/ListDocumentWithNumber';
import AddDocumentWithNumber from './containers/DocumentNumber/AddDocumentNumber';
import DocumentNumberDetail from './containers/DocumentNumber/DocumentNumberDetail';
import EditDocumentNumber from './containers/DocumentNumber/EditDocumentNumber';

import TypeOfDocumentNumberList from './containers/Settings/TypeOfDocumentNumber/index';

import TypeOfDocumentNumberAdd from './containers/Settings/TypeOfDocumentNumber/IssuedLetter/add';
import TypeOfDocumentNumberDetail from './containers/Settings/TypeOfDocumentNumber/IssuedLetter/detail';
import TypeOfDocumentNumberEdit from './containers/Settings/TypeOfDocumentNumber/IssuedLetter/edit';

import ReferenceTypeAdd from './containers/Settings/TypeOfDocumentNumber/ReferenceType/add';
import ReferenceTypeDetail from './containers/Settings/TypeOfDocumentNumber/ReferenceType/detail';
import ReferenceTypeEdit from './containers/Settings/TypeOfDocumentNumber/ReferenceType/edit';

import AuditCompanyProposal from './containers/CompanySubmissionProposal/ListAuditCompanyProposal';
//system reports
import PublicServiceReport from './containers/Settings/PublicServiceReport/index';

import ManageAgentPartner from './containers/ManageAgentPartner/index';

import PartnerRequest from './containers/ManageAgentPartner/PartnerRequest/index';
import PartnerRequestDetail from './containers/ManageAgentPartner/PartnerRequest/detail';

import TerminationRequest from './containers/ManageAgentPartner/TerminationRequest/index';
import TerminationRequestDetail from './containers/ManageAgentPartner/TerminationRequest/detail';

import BranchChangeRequest from './containers/ManageAgentPartner/BranchChangeRequest';
import BranchChangeRequestDetail from './containers/ManageAgentPartner/BranchChangeRequest/detail';
import SettingAutoAssignToProposal from './containers/Settings/Proposal/AutoAssignToProposal';
import SettingProposal from './containers/Settings/Proposal/index';
import AgentStatisticReport from './containers/Settings/Agent/agent-statistic-report';

// intemeriary
import IntermediaryPartnerRequest from './containers/ManageAgentPartner/Intermediary/PartnerRequest/index';
import IntermediaryPartnerRequestDetail from './containers/ManageAgentPartner/Intermediary/PartnerRequest/detail';

import IntermediaryTerminationPartnerRequest from './containers/ManageAgentPartner/Intermediary/PartnerTerminationRequest/index';
import IntermediaryTerminationPartnerRequestDetail from './containers/ManageAgentPartner/Intermediary/PartnerTerminationRequest/detail';

import IntermediaryBranchRequest from './containers/ManageAgentPartner/Intermediary/BranchRequest/index';
import IntermediaryBranchRequestDetail from './containers/ManageAgentPartner/Intermediary/BranchRequest/detail';

import IntermediaryBranchManagerTerminationRequest from './containers/ManageAgentPartner/Intermediary/BranchManagerTerminationRequest/index';
import IntermediaryBranchManagerTerminationRequestDetail from './containers/ManageAgentPartner/Intermediary/BranchManagerTerminationRequest/detail';

import IntermediaryBranchTerminationRequest from './containers/ManageAgentPartner/Intermediary/BranchTerminationRequest/index';
import IntermediaryBranchTerminationRequestDetail from './containers/ManageAgentPartner/Intermediary/BranchTerminationRequest/detail';

import IntermediaryBranchManagerChangeRequest from './containers/ManageAgentPartner/Intermediary/BranchManagerChangeRequest/index';
import IntermediaryBranchManagerChangeRequestDetail from './containers/ManageAgentPartner/Intermediary/BranchManagerChangeRequest/detail';

import IntermediaryProfessionalIndividualRequest from './containers/ManageAgentPartner/Intermediary/ProfessionalIndividualRequest/index';
import IntermediaryProfessionalIndividualRequestDetail from './containers/ManageAgentPartner/Intermediary/ProfessionalIndividualRequest/detail';

import IntermediaryProfessionalIndividualBranchChangeRequest from './containers/ManageAgentPartner/Intermediary/ProfessionalIndividualBranchChangeRequest/index';
import IntermediaryProfessionalIndividualBranchChangeRequestDetail from './containers/ManageAgentPartner/Intermediary/ProfessionalIndividualBranchChangeRequest/detail';

import IntermediaryProfessionalIndividualTerminationRequest from './containers/ManageAgentPartner/Intermediary/ProfessionalIndividualTerminationRequest/index';
import IntermediaryProfessionalIndividualTerminationRequestDetail from './containers/ManageAgentPartner/Intermediary/ProfessionalIndividualTerminationRequest/detail';

import IntermediarySaleProfessionalRequest from './containers/ManageAgentPartner/Intermediary/SaleProfessionalRequest/index';
import IntermediarySaleProfessionalDetailRequest from './containers/ManageAgentPartner/Intermediary/SaleProfessionalRequest/detail';

import CompanySaleProfessionalMenu from './containers/CompanySaleProfessional/index';
import CompanySaleProfessionalRequestList from './containers/CompanySaleProfessional/new/index';
import CompanySaleProfessionalRequestDetail from './containers/CompanySaleProfessional/new/detail';

import CompanySaleProfessionalTerminationRequestList from './containers/CompanySaleProfessional/termination/index';
import CompanySaleProfessionalTerminationRequestDetail from './containers/CompanySaleProfessional/termination/detail';

import CompanySaleProfessionalList from './containers/CompanySaleProfessional/list/index';
import AddSaleStaff from './containers/CompanySaleProfessional/list/add-sale-staff';
import LogsFiles from './containers/Settings/LogsFiles/index';

// end pages

const defaultTitle = 'Admin';
const Private = (props) => {
	const { t } = useTranslation();
	return (
		<Route path={props.path} exact={props.exact ? props.exact : false}>
			<>
				<Helmet>
					<title>{t(props?.title ? props.title : defaultTitle)}</title>
				</Helmet>
				<Layout>{props.children}</Layout>
			</>
		</Route>
	);
};

const Public = (props) => {
	const { t } = useTranslation();
	return (
		<Route path={props.path}>
			<>
				<Helmet>
					<title>{t(props?.title ? props.title : defaultTitle)}</title>
				</Helmet>
				<LayoutPublic>{props.children}</LayoutPublic>
			</>
		</Route>
	);
};

const Routes = () => {
	// const browserHistory = createBrowserHistory();

	return (
		// <Router history={browserHistory}>
		<Router>
			<Switch>
				<Public title="Login" path="/login">
					<Login />
				</Public>
				<Public title="CamDigiKeyAuthTokenChecker" path="/camdigikey_auth_token_checker">
					<CamDigiKeyAuthTokenChecker />
				</Public>
				<Public title="Register" path="/rs-first">
					<RegisterAdmin />
				</Public>
				<Private title="Home" path="/" exact={true}>
					<Home />
				</Private>
				<Private title="Website" path="/website" exact={true}>
					<Website />
				</Private>
				<Private title="List Articles" path="/website/articles" exact={true}>
					<ListArticles />
				</Private>
				<Private title="New Articles" path="/website/new-article" exact={true}>
					<NewArticle />
				</Private>
				<Private title="Public Service List" path="/website/public-service-list" exact={true}>
					<PublicServiceList />
				</Private>
				<Private title="Company Report Type List" path="/website/company-report-type-list" exact={true}>
					<CompanyReportTypeList />
				</Private>
				<Private title="Public Form List" path="/website/public-form-list" exact={true}>
					<PublicFormList />
				</Private>
				<Private title="Public Form Detail" path="/website/public-form-detail" exact={true}>
					<PublicFormDetail />
				</Private>
				<Private title="Public Form - Add" path="/website/public-form-add" exact={true}>
					<PublicFormAdd />
				</Private>
				<Private title="Account" path="/account" exact={true}>
					<Account />
				</Private>
				<Private title="Edit Articles" path="/website/edit-article" exact={true}>
					<EditArticle />
				</Private>
				{/* <Private title="Users" path="/users" exact={true}>
          <Users />
        </Private> */}
				<Private title="Create New User" path="/users/create" exact={true}>
					<CreateNewUser />
				</Private>
				<Private title="User List" path="/users" exact={true}>
					<UserList />
				</Private>
				<Private title="Update User" path="/users/update" exact={true}>
					<UpdateUser />
				</Private>
				<Private title="Complaint" path="/complaint" exact={true}>
					<Complaint />
				</Private>
				<Private title="Complaint Detail" path="/complaint/detail" exact={true}>
					<ComplaintDetail />
				</Private>
				<Private title="Complaint Report" path="/complaint/report" exact={true}>
					<ComplaintReport />
				</Private>
				<Private title="Complaint On Insurance" path="/complaint-on-insurance" exact={true}>
					<ComplaintOnInsurance />
				</Private>
				<Private title="Complaint On Insurance detail" path="/complaint-on-insurance/detail" exact={true}>
					<ComplaintOnInsuranceDetail />
				</Private>
				<Private title="Complaint Type" path="/complaint-type" exact={true}>
					<ComplaintType />
				</Private>
				<Private title="Change Password" path="/change-password" exact={true}>
					<ChangePassword />
				</Private>
				<Private title="Company Submission Report" path="/company-submission-report" exact={true}>
					<CompanySubmissionReport />
				</Private>
				<Private title="Company Submission Report - Detail" path="/company-submission-report/detail" exact={true}>
					<CompanySubmissionReportDetail />
				</Private>
				<Private title="Company Submission Proposal" path="/company-submission-proposal" exact={true}>
					<CompanySubmissionProposal />
				</Private>
				<Private title="Company Submission Proposal ListReport" path="/company-submission-proposal-list-report" exact={true}>
					<CompanySubmissionProposalListReport />
				</Private>
				<Private title="Company Submission Proposal - Detail" path="/company-submission-proposal/detail" exact={true}>
					<CompanySubmissionProposalDetail />
				</Private>
				<Private title="Company Submission Proposal - Add" path="/company-submission-proposal/add" exact={true}>
					<AddCompanyProposal />
				</Private>
				<Private title="Company Submission Proposal - Success" path="/company-submission-proposal/success" exact={true}>
					<AddCompanyProposalSuccess />
				</Private>
				<Private title="Company" path="/company" exact={true}>
					<Company />
				</Private>
				<Private title="Company - Detail" path="/company/detail" exact={true}>
					<CompanyDetail />
				</Private>
				<Private title="Company - Update" path="/company/update" exact={true}>
					<CompanyUpdate />
				</Private>
				<Private title="Company - Create" path="/company/create" exact={true}>
					<CompanyCreate />
				</Private>
				<Private title="Settings" path="/settings" exact={true}>
					<Settings />
				</Private>
				<Private title="Delete Notification" path="/settings/delete-notification" exact={true}>
					<DeleteNotification />
				</Private>
				<Private title="RequestLeave" path="/request-leave" exact={true}>
					<RequestLeave />
				</Private>
				<Private title="RequestLeave - Detail" path="/request-leave/detail" exact={true}>
					<RequestLeaveDetail />
				</Private>
				<Private title="RequestLeave - Add" path="/request-leave/add" exact={true}>
					<RequestLeaveAdd />
				</Private>
				<Private title="PDF Report Leave" path="/request-leave/pdf-report" exact={true}>
					<PDFReport />
				</Private>
				<Private title="LeaveAdjustment" path="/leave-adjustment" exact={true}>
					<LeaveAdjustment />
				</Private>
				<Private title="LeaveAdjustment - Detail" path="/leave-adjustment/detail" exact={true}>
					<LeaveAdjustmentDetail />
				</Private>
				<Private title="LeaveAdjustment - Add" path="/leave-adjustment/add" exact={true}>
					<LeaveAdjustmentAdd />
				</Private>
				<Private title="Agent" path="/agent" exact={true}>
					<Agent />
				</Private>
				<Private title="Agent - Detail" path="/agent/detail" exact={true}>
					<AgentDetail />
				</Private>
				<Private title="Agent Renewal" path="/agent-renewal" exact={true}>
					<AgentRenewalList />
				</Private>
				<Private title="Payment" path="/payment" exact={true}>
					<Payment />
				</Private>
				<Private title="Payment" path="/payment/add" exact={true}>
					<AddLicenseChargeOrFine />
				</Private>
				<Private title="Payment Detail" path="/payment/detail" exact={true}>
					<ReportDetail />
				</Private>
				<Private title="Payment Detail" path="/payment/license-or-fine-detail" exact={true}>
					<LicenseOrFineReportDetail />
				</Private>
				<Private title="Product List" path="/product/list" exact={true}>
					<ProductList />
				</Private>
				<Private title="Add Product" path="/product/add" exact={true}>
					<AddProduct />
				</Private>
				<Private title="Add Officials" path="/employee/create" exact={true}>
					<CreateEmployee />
				</Private>
				<Private title="Officials List" path="/employee/list" exact={true}>
					<EmployeeList />
				</Private>
				<Private title="Officials Detail" path="/employee/detail" exact={true}>
					<EmployeeDetail />
				</Private>
				<Private title="Edit Officials" path="/employee/edit" exact={true}>
					<EmployeeEdit />
				</Private>
				<Private title="Announcement List" path="/announcement/list" exact={true}>
					<AnnouncementList />
				</Private>
				<Private title="Add Announcement" path="/announcement/add" exact={true}>
					<AddAnnouncement />
				</Private>
				<Private title="Email" path="/settings/email" exact={true}>
					<MailList />
				</Private>
				<Private title="Email - Content" path="/settings/email/content" exact={true}>
					<MailContent />
				</Private>
				<Private title="Other Setting List" path="/other-setting/list" exact={true}>
					<OtherSettingList />
				</Private>
				<Private title="Add Other Setting" path="/other-setting/add" exact={true}>
					<AddOtherSetting />
				</Private>
				<Private title="Salary Setting" path="/salary/setting" exact={true}>
					<SalarySetting />
				</Private>
				<Private title="Salary List" path="/salary/list" exact={true}>
					<SalaryList />
				</Private>
				<Private title="Salary Add" path="/salary/add" exact={true}>
					<SalaryAdd />
				</Private>
				<Private title="Salary Edit" path="/salary/edit" exact={true}>
					<SalaryEdit />
				</Private>
				<Private title="Maintenance Status" path="/settings/maintenance-status" exact={true}>
					<MaintenanceStatus />
				</Private>
				<Private title="CamDigiKey User List" path="/settings/camdigikey" exact={true}>
					<Camdigikey />
				</Private>
				<Private title="CamDigiKey User" path="/settings/camdigikey/detail" exact={true}>
					<CamdigikeyDetails />
				</Private>
				<Private title="White List" path="/settings/whitelist-org-camdx/list" exact={true}>
					<WhiteListOrgCamdx />
				</Private>
				<Private title="Add White List ORG CamDx" path="/settings/whitelist-org-camdx/add" exact={true}>
					<AddWhiteListOrgCamdx />
				</Private>
				<Private title="Issued Letter" path="/issued-letter" exact={true}>
					<DocumentNumber />
				</Private>
				<Private title="Issued Letter - List" path="/issued-letter/list" exact={true}>
					<ListDocumentWithNumber />
				</Private>
				<Private title="Issued Letter - Add" path="/issued-letter/add" exact={true}>
					<AddDocumentWithNumber />
				</Private>
				<Private title="Issued Letter - Detail" path="/issued-letter/detail" exact={true}>
					<DocumentNumberDetail />
				</Private>
				<Private title="Issued Letter - Edit" path="/issued-letter/edit" exact={true}>
					<EditDocumentNumber />
				</Private>
				<Private title="Type Of Issued Letter - List" path="/type-of-issued-letter" exact={true}>
					<TypeOfDocumentNumberList />
				</Private>
				<Private title="Type Of Issued Letter - Add" path="/type-of-issued-letter/add-type-of-issued-letter" exact={true}>
					<TypeOfDocumentNumberAdd />
				</Private>
				<Private title="Type Of Issued Letter - Detail" path="/type-of-issued-letter/detail-type-of-issued-letter" exact={true}>
					<TypeOfDocumentNumberDetail />
				</Private>
				<Private title="Type Of Issued Letter - Edit" path="/type-of-issued-letter/edit-type-of-issued-letter" exact={true}>
					<TypeOfDocumentNumberEdit />
				</Private>
				<Private title="Reference Type - Add" path="/type-of-issued-letter/add-reference-type" exact={true}>
					<ReferenceTypeAdd />
				</Private>
				<Private title="Reference Type - Detail" path="/type-of-issued-letter/detail-reference-type" exact={true}>
					<ReferenceTypeDetail />
				</Private>
				<Private title="Reference Type - Edit" path="/type-of-issued-letter/edit-reference-type" exact={true}>
					<ReferenceTypeEdit />
				</Private>
				<Private title="Transfer Proposal" path="/users/transfer-proposal" exact={true}>
					<TransferProposal />
				</Private>
				<Private title="Audit Company" path="/audit-company" exact={true}>
					<AuditCompanyProposal />
				</Private>
				<Private title="Audit Company - Detail" path="/audit-company/detail" exact={true}>
					<CompanySubmissionProposalDetail auditServiceOnly={true} />
				</Private>
				<Private title="Public Service Report" path="/system-report/public-service-report" exact={true}>
					<PublicServiceReport auditServiceOnly={true} />
				</Private>
				<Private title="Manage Agent Partner" path="/manage-agent-partner" exact={true}>
					<ManageAgentPartner />
				</Private>
				<Private title="Agent Partner Request" path="/manage-agent-partner/partner-request" exact={true}>
					<PartnerRequest />
				</Private>
				<Private title="Agent Partner Request - Detail" path="/manage-agent-partner/partner-request/detail" exact={true}>
					<PartnerRequestDetail />
				</Private>
				<Private title="Agent Termination Request" path="/manage-agent-partner/termination-request" exact={true}>
					<TerminationRequest />
				</Private>
				<Private title="Agent Termination Request - Detail" path="/manage-agent-partner/termination-request/detail" exact={true}>
					<TerminationRequestDetail />
				</Private>
				<Private title="Branch Change Request" path="/manage-agent-partner/branch-change-request" exact={true}>
					<BranchChangeRequest />
				</Private>
				<Private title="Branch Change Request - Detail" path="/manage-agent-partner/branch-change-request/detail" exact={true}>
					<BranchChangeRequestDetail />
				</Private>
				<Private title="Setting Auto Assign to Proposal" path="/settings/proposal/auto-assign-to-proposal" exact={true}>
					<SettingAutoAssignToProposal />
				</Private>
				<Private title="Setting Proposal" path="/settings/proposal" exact={true}>
					<SettingProposal />
				</Private>
				<Private title="Agent Statistic Report" path="/settings/agent/agent-statistic-report" exact={true}>
					<AgentStatisticReport />
				</Private>
				<Private title="Intermediary Partner Request" path="/intermediary/partner-request" exact={true}>
					<IntermediaryPartnerRequest />
				</Private>
				<Private title="Intermediary Partner Request Detail" path="/intermediary/partner-request/detail" exact={true}>
					<IntermediaryPartnerRequestDetail />
				</Private>
				<Private title="Intermediary Partner Request" path="/intermediary/termination-partner-request" exact={true}>
					<IntermediaryTerminationPartnerRequest />
				</Private>
				<Private title="Intermediary Partner Request Detail" path="/intermediary/termination-partner-request/detail" exact={true}>
					<IntermediaryTerminationPartnerRequestDetail />
				</Private>
				<Private title="Intermediary Branch Request" path="/intermediary/branch-request" exact={true}>
					<IntermediaryBranchRequest />
				</Private>
				<Private title="Intermediary Branch Request Detail" path="/intermediary/branch-request/detail" exact={true}>
					<IntermediaryBranchRequestDetail />
				</Private>
				<Private title="Intermediary Branch Manager Termination" path="/intermediary/branch-manager-termination-request" exact={true}>
					<IntermediaryBranchManagerTerminationRequest />
				</Private>
				<Private title="Intermediary Branch Manager Termination Detail" path="/intermediary/branch-manager-termination-request/detail" exact={true}>
					<IntermediaryBranchManagerTerminationRequestDetail />
				</Private>
				<Private title="Intermediary Branch Termination" path="/intermediary/branch-termination-request" exact={true}>
					<IntermediaryBranchTerminationRequest />
				</Private>
				<Private title="Intermediary Branch Termination Detail" path="/intermediary/branch-termination-request/detail" exact={true}>
					<IntermediaryBranchTerminationRequestDetail />
				</Private>
				<Private title="Intermediary Branch Manager Change Request" path="/intermediary/branch-manager-change-request" exact={true}>
					<IntermediaryBranchManagerChangeRequest />
				</Private>
				<Private title="Intermediary Branch Manager Change Request Detail" path="/intermediary/branch-manager-change-request/detail" exact={true}>
					<IntermediaryBranchManagerChangeRequestDetail />
				</Private>
				<Private title="Intermediary Professional Individual Request" path="/intermediary/professional-individual-request" exact={true}>
					<IntermediaryProfessionalIndividualRequest />
				</Private>
				<Private title="Intermediary Professional Individual Request Detail" path="/intermediary/professional-individual-request/detail" exact={true}>
					<IntermediaryProfessionalIndividualRequestDetail />
				</Private>
				<Private
					title="Intermediary Professional Individual Branch Change Request"
					path="/intermediary/professional-individual-branch-change-request"
					exact={true}
				>
					<IntermediaryProfessionalIndividualBranchChangeRequest />
				</Private>
				<Private
					title="Intermediary Professional Individual Branch Change Request Detail"
					path="/intermediary/professional-individual-branch-change-request/detail"
					exact={true}
				>
					<IntermediaryProfessionalIndividualBranchChangeRequestDetail />
				</Private>
				<Private
					title="Intermediary Professional Individual Termination Request"
					path="/intermediary/professional-individual-termination-request"
					exact={true}
				>
					<IntermediaryProfessionalIndividualTerminationRequest />
				</Private>
				<Private
					title="Intermediary Professional Individual Termination Request Detail"
					path="/intermediary/professional-individual-termination-request/detail"
					exact={true}
				>
					<IntermediaryProfessionalIndividualTerminationRequestDetail />
				</Private>
				<Private title="Intermediary Sale Staff Request" path="/intermediary/sale-professional-request" exact={true}>
					<IntermediarySaleProfessionalRequest />
				</Private>
				<Private title="Intermediary Sale Staff Detail" path="/intermediary/sale-professional-request/detail" exact={true}>
					<IntermediarySaleProfessionalDetailRequest />
				</Private>
				<Private title="Company Sale Staff Menu" path="/company-sale-professional/menu" exact={true}>
					<CompanySaleProfessionalMenu />
				</Private>
				<Private title="Company Sale Staff Request List" path="/company-sale-professional/new/list" exact={true}>
					<CompanySaleProfessionalRequestList />
				</Private>
				<Private title="Company Sale Staff Request Detail" path="/company-sale-professional/new/detail" exact={true}>
					<CompanySaleProfessionalRequestDetail />
				</Private>
				<Private title="Company Sale Staff Termination Request List" path="/company-sale-professional/termination/list" exact={true}>
					<CompanySaleProfessionalTerminationRequestList />
				</Private>
				<Private title="Company Sale Staff Termination Request Detail" path="/company-sale-professional/termination/detail" exact={true}>
					<CompanySaleProfessionalTerminationRequestDetail />
				</Private>
				<Private title="Company Sale Staff List" path="/company-sale-professional/list" exact={true}>
					<CompanySaleProfessionalList />
				</Private>
				<Private title="Add Sale Staff" path="/company-sale-professional/list/add" exact={true}>
					<AddSaleStaff />
				</Private>
				<Private title="Logs Files" path="/logs-files" exact={true}>
					<LogsFiles />
				</Private>

				<Route path="/*" component={PageNotFound} />
			</Switch>
		</Router>
	);
};

export default Routes;
