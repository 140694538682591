/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SectionHeader } from '../../Other';
import { Box, Flex, Button, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Center, Tooltip, Badge, useDisclosure, Text } from '@chakra-ui/react';
import LocalizedDate from '../../LocalizedDate';
import CompanyName from '../../CompanyName';
import CompanyBadge from '../../Other/CompanyBadge';
import NoData from '../../NoData';
import Loading from '../../Loading';
import c from '../../../constant';
import DistributionSystemBrokerPartnerPrint from './DistributionSystemBrokerPartnerPrint';
import AddCompanyPartnerModal from '../../Modal/AddCompanyPartnerModal';
import useStore from '../../../store';
import DownloadAndViewButton from '../../Files/DownloadAndViewButton';

const Q_DETAIL = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			id
			type
		}
	}
`;

const Q_DATA = gql`
	query GET($companyId: String) {
		getCompanyBrokerPartners(companyId: $companyId) {
			id
			model
			company {
				id
				name
				nameKhmer
				type
				logo
			}
			intermediary {
				id
				name
				nameKhmer
				type
				logo
			}
			createdAt
			active
			approvalId
			active
			inactiveAt
			inactiveById
			attachment
		}
	}
`;

function DistributionSystemBroker({ id, ...props }) {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [loadCompany, { data: dataCompany }] = useLazyQuery(Q_DETAIL);
	const [loadData, { loading, data }] = useLazyQuery(Q_DATA);
	const { isOpen: isInserting, onOpen: openInserting, onClose: closeInserting } = useDisclosure();
	const { currentUser } = useStore((state) => state.currentUser);

	useEffect(() => {
		if (id) {
			loadCompany({ variables: { id } });
		}
	}, [id]);

	useEffect(() => {
		isAllowedCompany(dataCompany?.getCompanyById?.type) && loadData({ variables: { companyId: id } });
	}, [dataCompany]);

	function Status({ status = null }) {
		return (
			<>
				<>{status === 'active' && <Badge colorScheme="green">{t('Active')}</Badge>}</>
				<>{status === 'inactive' && <Badge colorScheme="red">{t('Inactive')}</Badge>}</>
			</>
		);
	}

	function Model({ model = null, color = 'orange' }) {
		switch (model) {
			case 'direct':
				return (
					<Badge variant="solid" colorScheme={color}>
						{t('Direct Sale')}
					</Badge>
				);
			case 'ref':
				return (
					<Badge variant="solid" colorScheme={color}>
						{t('Referral')}
					</Badge>
				);
			default:
				return null;
		}
	}

	function isAllowedCompany(type) {
		return isAllowedIntermediaryCompany(type) || isAllowedInsuranceCompany(type);
	}

	function isAllowedIntermediaryCompany(type) {
		return type === c.insuranceCompanyType.broker.value;
	}

	function isAllowedInsuranceCompany(type) {
		return type === c.insuranceCompanyType.general.value || type === c.insuranceCompanyType.life.value || type === c.insuranceCompanyType.micro.value;
	}

	return (
		<>
			{isAllowedCompany(dataCompany?.getCompanyById?.type) && (
				<>
					<Flex mt={6} className="hidden-print" {...props}>
						<Box width="100%">
							{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
								<>
									<SectionHeader title={t('Partnering with Insurance Company')} />
								</>
							)}
							{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
								<>
									<SectionHeader title={t('Partnering with Broker Company')} />
								</>
							)}
							
							<Center mt={4}>
								<Box
									w="100%"
									bg="white"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									pb="32px"
								>
									<Flex
										p={4}
										pt={2}
										pb={2}
										mb={4}
										h="48px"
										bg="#FAFAFA"
										alignItems="center"
										justifyContent="end"
										borderBottom="1px solid #dbdbdb"
									>
										<Flex alignItems="center">
										{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
									<Flex
										userSelect="none"
										onClick={openInserting}
										cursor="pointer"
										alignItems="center"
										justifyContent="center"
										w="32px"
										h="32px"
										borderRadius={c.borderRadius}
										border="1px solid #dbdbdb"
										boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									>
										<Text fontSize="26px" mt="2px" fontWeight="500">
											+
										</Text>
									</Flex>
								)}
										</Flex>
									</Flex>
									<Box p="16px">
										{loading && (
											<>
												<Loading />
											</>
										)}
										{!loading && data?.getCompanyBrokerPartners?.length <= 0 && (
											<>
												<NoData />
											</>
										)}
										{!loading && data?.getCompanyBrokerPartners?.length > 0 && (
											<>
												<Table variant="simple">
													<Thead>
														<Tr>
															{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Insurance Company Name')}</Th>
															)}
															{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Insurance Company Type')}</Th>
															)}
															{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Intermediary Company Name')}</Th>
															)}
															{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Intermediary Company Type')}</Th>
															)}
															<Th>{t('Model')}</Th>
															<Th>{t('Date')}</Th>
															<Th>{t('View Detail')}</Th>
															<Th isNumeric>{t('Status')}</Th>
														</Tr>
													</Thead>
													<Tbody>
														{data?.getCompanyBrokerPartners?.map((item, index) => {
															return (
																<Tr key={`item-${index}`} bg="white">
																	{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
																		<Td>
																			<CompanyName company={item?.company} />
																		</Td>
																	)}
																	{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
																		<Td>
																			<CompanyBadge type={item?.company?.type} />
																		</Td>
																	)}
																	{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
																		<Td>
																			<CompanyName company={item?.intermediary} />
																		</Td>
																	)}
																	{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
																		<Td>
																			<CompanyBadge type={item?.intermediary?.type} />
																		</Td>
																	)}
																	<Td>
																		<Model model={item?.model} />
																	</Td>
																	<Td>
																		<LocalizedDate dateString={item?.createdAt} />
																	</Td>
																	<Td>
																		{item?.approvalId && (
																			<Button onMouseDown={() => push(`/intermediary/partner-request/detail?id=${item?.approvalId}`)}>
																				{t('View Detail')}
																			</Button>
																		)}
																		{item?.attachment?.file && (
																			<DownloadAndViewButton
																				marginLeft={0}
																				url={item?.attachment?.file}
																				size={item?.attachment?.size}
																			/>
																		)}
																	</Td>
																	<Td isNumeric>
																		{item?.active ? (
																			<>
																				<Status status="active" />
																			</>
																		) : (
																			<Tooltip label={<LocalizedDate dateString={item?.inactiveAt} color="white" />}>
																				<Box
																					onMouseDown={() =>
																						push(`/intermediary/partner-request/detail?id=${item?.inactiveById}`)
																					}
																				>
																					<Status status="inactive" />
																				</Box>
																			</Tooltip>
																		)}
																	</Td>
																</Tr>
															);
														})}
													</Tbody>
													<Tfoot>
														<Tr>
															{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Insurance Company Name')}</Th>
															)}
															{isAllowedIntermediaryCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Insurance Company Type')}</Th>
															)}
															{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Intermediary Company Name')}</Th>
															)}
															{isAllowedInsuranceCompany(dataCompany?.getCompanyById?.type) && (
																<Th>{t('Intermediary Company Type')}</Th>
															)}
															<Th>{t('Model')}</Th>
															<Th>{t('Date')}</Th>
															<Th>{t('View Detail')}</Th>
															<Th isNumeric>{t('Status')}</Th>
														</Tr>
													</Tfoot>
												</Table>
											</>
										)}
									</Box>
								</Box>
							</Center>
						</Box>
					</Flex>
					<DistributionSystemBrokerPartnerPrint list={data?.getCompanyBrokerPartners} companyType={dataCompany?.getCompanyById?.type} />

					<AddCompanyPartnerModal
						companyId={id}
						isOpen={isInserting}
						onClose={closeInserting}
						partneredList={data?.getCompanyAgentPartners}
						callback={(e) => {
							loadCompany({ variables: { id } });
						}}
						directOnly={true}
					/>
				
				</>
			)}
		</>
	);
}

export default DistributionSystemBroker;
