/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from '@chakra-ui/react';
import { usePartnerRequestCount } from '../../../contexts/partner-request-count';
import { useEffect } from 'react';
import c from '../../../constant';
import useStore from '../../../store';

const intermediaryBadgeType = {
	newPartner: 'newPartner',
	changePartner: 'changePartner',
	newBranch: 'newBranch',
	changeBranchManager: 'changeBranchManager',
	newProfessional: 'newProfessional',
	changeBranchProfessional: 'changeBranchProfessional',
	terminationProfessional: 'terminationProfessional',
	requestNewSaleProfessional: 'requestNewSaleProfessional',
	requestBranchChangeSaleProfessional: 'requestBranchChangeSaleProfessional',
	requestTerminateSaleProfessional: 'requestTerminateSaleProfessional',
	requestTerminateBranch: 'requestTerminateBranch',
};

function IntermediaryBadgeCount({ type = null }) {
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { loadRequestIntermediaryCount, intermediaryBadgeCount } = usePartnerRequestCount();

	useEffect(() => {
		loadRequestIntermediaryCount();
	}, []);

	return (
		<>
			{type === intermediaryBadgeType.newPartner && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewPartner?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewPartner?.officeCount)}
							</Badge>
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewPartner?.departmentCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewPartner?.departmentCount)}
							</Badge>
						</>
					)}
					{c.stepDirectorGeneralRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewPartner?.directorGeneralCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewPartner?.directorGeneralCount)}
							</Badge>
						</>
					)}
				</>
			)}
			{type === intermediaryBadgeType.changePartner && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestChangePartner?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestChangePartner?.officeCount)}
							</Badge>
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestChangePartner?.departmentCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestChangePartner?.departmentCount)}
							</Badge>
						</>
					)}
					{c.stepDirectorGeneralRoles.includes(user?.position) &&
						parseInt(intermediaryBadgeCount?.requestChangePartner?.directorGeneralCount) > 0 && (
							<>
								<Badge colorScheme="red" variant="solid" ml={1}>
									{parseInt(intermediaryBadgeCount?.requestChangePartner?.directorGeneralCount)}
								</Badge>
							</>
						)}
				</>
			)}
			{type === intermediaryBadgeType.newBranch && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewBranch?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewBranch?.officeCount)}
							</Badge>
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewBranch?.departmentCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewBranch?.departmentCount)}
							</Badge>
						</>
					)}
				</>
			)}
			{type === intermediaryBadgeType.changeBranchManager && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestChangeBranchManager?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestChangeBranchManager?.officeCount)}
							</Badge>
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestChangeBranchManager?.departmentCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestChangeBranchManager?.departmentCount)}
							</Badge>
						</>
					)}
				</>
			)}
			{type === intermediaryBadgeType.newProfessional && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewProfessionalIndividual?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewProfessionalIndividual?.officeCount)}
							</Badge>
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) &&
						parseInt(intermediaryBadgeCount?.requestNewProfessionalIndividual?.departmentCount) > 0 && (
							<>
								<Badge colorScheme="red" variant="solid" ml={1}>
									{parseInt(intermediaryBadgeCount?.requestNewProfessionalIndividual?.departmentCount)}
								</Badge>
							</>
						)}
				</>
			)}
			{type === intermediaryBadgeType.changeBranchProfessional && (
				<>
					{c.stepOfficeRoles.includes(user?.position) &&
						parseInt(intermediaryBadgeCount?.requestChangeBranchProfessionalIndividual?.officeCount) > 0 && (
							<>
								<Badge colorScheme="red" variant="solid" ml={1}>
									{parseInt(intermediaryBadgeCount?.requestChangeBranchProfessionalIndividual?.officeCount)}
								</Badge>
							</>
						)}
					{c.stepDepartmentRoles.includes(user?.position) &&
						parseInt(intermediaryBadgeCount?.requestChangeBranchProfessionalIndividual?.departmentCount) > 0 && (
							<>
								<Badge colorScheme="red" variant="solid" ml={1}>
									{parseInt(intermediaryBadgeCount?.requestChangeBranchProfessionalIndividual?.departmentCount)}
								</Badge>
							</>
						)}
				</>
			)}
			{type === intermediaryBadgeType.terminationProfessional && (
				<>
					{c.stepOfficeRoles.includes(user?.position) &&
						parseInt(intermediaryBadgeCount?.requestTerminateProfessionalIndividual?.officeCount) > 0 && (
							<>
								<Badge colorScheme="red" variant="solid" ml={1}>
									{parseInt(intermediaryBadgeCount?.requestTerminateProfessionalIndividual?.officeCount)}
								</Badge>
							</>
						)}
					{c.stepDepartmentRoles.includes(user?.position) &&
						parseInt(intermediaryBadgeCount?.requestTerminateProfessionalIndividual?.departmentCount) > 0 && (
							<>
								<Badge colorScheme="red" variant="solid" ml={1}>
									{parseInt(intermediaryBadgeCount?.requestTerminateProfessionalIndividual?.departmentCount)}
								</Badge>
							</>
						)}
				</>
			)}
			{type === intermediaryBadgeType?.requestNewSaleProfessional && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestNewSaleProfessional?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestNewSaleProfessional?.officeCount)}
							</Badge>
						</>
					)}
				</>
			)}
			{type === intermediaryBadgeType?.requestBranchChangeSaleProfessional && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestBranchChangeSaleProfessional?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestBranchChangeSaleProfessional?.officeCount)}
							</Badge>
						</>
					)}
				</>
			)}
			{type === intermediaryBadgeType?.requestTerminateSaleProfessional && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestTerminateSaleProfessional?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestTerminateSaleProfessional?.officeCount)}
							</Badge>
						</>
					)}
				</>
			)}
			{type === intermediaryBadgeType?.requestTerminateBranch && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && parseInt(intermediaryBadgeCount?.requestTerminateBranch?.officeCount) > 0 && (
						<>
							<Badge colorScheme="red" variant="solid" ml={1}>
								{parseInt(intermediaryBadgeCount?.requestTerminateBranch?.officeCount)}
							</Badge>
						</>
					)}
				</>
			)}
		</>
	);
}

export { IntermediaryBadgeCount, intermediaryBadgeType };
