import React, { useEffect, useState } from 'react';
import {
	Box,
	Flex,
	Modal,
	Image,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Text,
	Center,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Badge,
} from '@chakra-ui/react';
import c from '../../../constant';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import LocalizedDate from '../../../components/LocalizedDate';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import { Link } from 'react-router-dom';

const QUERY_DATA = gql`
	query get($id: String!) {
		getCompanySaleProfessionalById(id: $id) {
			id
			idCardNumber
			firstName
			lastName
			firstNameEn
			lastNameEn
			gender
			dob
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			status
			baseCertificate
			professionalCertificate
			contract
			requestId
			terminationId
		}
	}
`;

function SaleStaffModal({ isOpen = false, onClose = () => null, data = null }) {
	const { t } = useTranslation();
	const [loadData, { loading, data: detailData }] = useLazyQuery(QUERY_DATA);
	useEffect(() => {
		if (data?.id) {
			loadData({
				variables: {
					id: data?.id,
				},
			});
		}
	}, [data]);

	function getStatus(status, requestId, terminationId) {
		switch (status) {
			case 'active':
				return (
					<Link to={`/company-sale-professional/new/detail?id=${requestId}`}>
						<Badge colorScheme="green">{t('ACTIVE')}</Badge>
					</Link>
				);
			case 'terminated':
				return (
					<Link to={`/company-sale-professional/termination/detail?id=${terminationId}`}>
						<Badge colorScheme="red">{t('Terminated')}</Badge>
					</Link>
				);
			default:
				return null;
		}
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
				}}
				scrollBehavior="outside"
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pt="2">
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody pl={0} pr={0} pb={0}>
						{loading && <Text>{t('Loading')}</Text>}
						<TableContainer>
							{detailData?.getCompanySaleProfessionalById?.id && (
								<Table size="sm">
									<Thead>
										<Tr>
											<Th></Th>
											<Th isNumeric></Th>
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											<Td>{t('ID Card Number')}</Td>
											<Td isNumeric fontWeight="bold">
												{detailData?.getCompanySaleProfessionalById?.idCardNumber}
											</Td>
										</Tr>

										<Tr>
											<Td>{t('First Name')}</Td>
											<Td isNumeric fontWeight="bold" fontFamily="NotoSansKhmer">
												{detailData?.getCompanySaleProfessionalById?.firstName}
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Last Name')}</Td>
											<Td isNumeric fontWeight="bold" fontFamily="NotoSansKhmer">
												{detailData?.getCompanySaleProfessionalById?.lastName}
											</Td>
										</Tr>

										<Tr>
											<Td>{t('First Name En')}</Td>
											<Td isNumeric fontWeight="bold">
												{detailData?.getCompanySaleProfessionalById?.firstNameEn}
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Last Name En')}</Td>
											<Td isNumeric fontWeight="bold">
												{detailData?.getCompanySaleProfessionalById?.lastNameEn}
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Gender')}</Td>
											<Td isNumeric fontWeight="bold">
												{t(detailData?.getCompanySaleProfessionalById?.gender)}
											</Td>
										</Tr>

										<Tr>
											<Td>{t('DOB')}</Td>
											<Td isNumeric fontWeight="bold">
												<LocalizedDate dateString={detailData?.getCompanySaleProfessionalById?.dob} dateFormat={c.dateOnlyFormat} />
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Insurance Education Certificate')}</Td>
											<Td float="right" fontWeight="bold">
												<DownloadAndViewButton
													url={detailData?.getCompanySaleProfessionalById?.baseCertificate?.url}
													size={detailData?.getCompanySaleProfessionalById?.baseCertificate?.size}
												/>
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Specialized Certificate')}</Td>
											<Td float="right" fontWeight="bold">
												<DownloadAndViewButton
													url={detailData?.getCompanySaleProfessionalById?.professionalCertificate?.url}
													size={detailData?.getCompanySaleProfessionalById?.professionalCertificate?.size}
												/>
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Contract')}</Td>
											<Td float="right" fontWeight="bold">
												<DownloadAndViewButton
													url={detailData?.getCompanySaleProfessionalById?.contract?.url}
													size={detailData?.getCompanySaleProfessionalById?.contract?.size}
												/>
											</Td>
										</Tr>

										<Tr>
											<Td>{t('Status')}</Td>
											<Td isNumeric fontWeight="bold">
												{getStatus(
													detailData?.getCompanySaleProfessionalById?.status === 'approved'
														? 'active'
														: detailData?.getCompanySaleProfessionalById?.status,
													detailData?.getCompanySaleProfessionalById?.requestId,
													detailData?.getCompanySaleProfessionalById?.terminationId
												)}
											</Td>
										</Tr>
									</Tbody>
								</Table>
							)}
						</TableContainer>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default SaleStaffModal;
