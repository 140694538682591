/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Badge } from '@chakra-ui/react';
import { usePartnerRequestCount } from '../../../contexts/partner-request-count';
import { useTerminationRequestCount } from '../../../contexts/termination-request-count';
import { useBranchChangeRequestCount } from '../../../contexts/branch-change-request-count';
import useStore from '../../../store';
import c from '../../../constant';

function ManageAgentPartnerBadge() {
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { loadCountPartnerRequest, countAllPartnerRequestPrimary, countAllPartnerRequestSecondary, loadRequestIntermediaryCount, intermediaryBadgeCount } =
		usePartnerRequestCount();
	const {
		loadCountTerminationRequest,
		countPrimaryReview,
		countSecondaryReview,
		loadCountTerminationPartner,
		countTerminationPartnerOfficeReview,
		countTerminationPartnerDepartmentReview,
		loadCountTerminationBranchManager,
		countTerminationBranchManagerOfficeReview,
	} = useTerminationRequestCount();
	const { loadCountBranchChangeRequest, countReview } = useBranchChangeRequestCount();

	useEffect(() => {
		loadCountPartnerRequest();
		loadCountTerminationRequest();
		loadCountBranchChangeRequest();
		loadRequestIntermediaryCount();
		loadCountTerminationPartner();
		loadCountTerminationBranchManager();
	}, []);

	function intermediaryOfficeCount() {
		if (intermediaryBadgeCount) {
			let count = 0;
			Object.keys(intermediaryBadgeCount).forEach((key) => {
				count = count + parseInt(intermediaryBadgeCount[key]?.officeCount || 0);
			});
			return count;
		}
		return 0;
	}

	function intermediaryDepartmentCount() {
		if (intermediaryBadgeCount) {
			let count = 0;
			Object.keys(intermediaryBadgeCount).forEach((key) => {
				count = count + parseInt(intermediaryBadgeCount[key]?.departmentCount || 0);
			});
			return count;
		}
		return 0;
	}

	function intermediaryDirectorGeneralCount() {
		if (intermediaryBadgeCount) {
			let count = 0;
			Object.keys(intermediaryBadgeCount).forEach((key) => {
				count = count + parseInt(intermediaryBadgeCount[key]?.directorGeneralCount || 0);
			});
			return count;
		}
		return 0;
	}

	return (
		<>
			<>
				{c.stepOfficeRoles.includes(user?.position) && (
					<>
						{parseInt(
							countAllPartnerRequestPrimary +
								countPrimaryReview +
								countReview +
								intermediaryOfficeCount() +
								countTerminationPartnerOfficeReview +
								countTerminationBranchManagerOfficeReview
						) > 0 && (
							<>
								<Badge fontSize="x-large" zIndex="99" top="2px" right="2px" position="absolute" colorScheme="red">
									{parseInt(
										countAllPartnerRequestPrimary +
											countPrimaryReview +
											countReview +
											intermediaryOfficeCount() +
											countTerminationPartnerOfficeReview +
											countTerminationBranchManagerOfficeReview
									)}
								</Badge>
							</>
						)}
					</>
				)}
				{c.stepDepartmentRoles.includes(user?.position) && (
					<>
						{parseInt(
							countAllPartnerRequestSecondary + countSecondaryReview + intermediaryDepartmentCount() + countTerminationPartnerDepartmentReview
						) > 0 && (
							<>
								<Badge fontSize="x-large" zIndex="99" top="2px" right="2px" position="absolute" colorScheme="red">
									{parseInt(
										countAllPartnerRequestSecondary +
											countSecondaryReview +
											intermediaryDepartmentCount() +
											countTerminationPartnerDepartmentReview
									)}
								</Badge>
							</>
						)}
					</>
				)}
				{c.stepDirectorGeneralRoles.includes(user?.position) && (
					<>
						{parseInt(intermediaryDirectorGeneralCount()) > 0 && (
							<>
								<Badge fontSize="x-large" zIndex="99" top="2px" right="2px" position="absolute" colorScheme="red">
									{parseInt(intermediaryDirectorGeneralCount())}
								</Badge>
							</>
						)}
					</>
				)}
			</>
		</>
	);
}

export default ManageAgentPartnerBadge;
