/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect } from 'react';

const Q_DETAIL = gql`
	query get($id: String!) {
		getProfessionalIndividualRecordById(id: $id) {
			id
			status
			description
			agent {
				id
				code
				name
			}
		}
	}
`;

export default function EventLoggingProfessionalIndividual({ id = null }) {
	const [fetchData, { data }] = useLazyQuery(Q_DETAIL);

	useEffect(() => {
		id && fetchData({ variables: { id } });
	}, [id]);

	return (
		<>
			{data?.getProfessionalIndividualRecordById?.id && (
				<Tooltip
					label={
						<Box>
							{data?.getProfessionalIndividualRecordById?.agent?.code && (
								<Text color="white" fontWeight="semibold">
									{data?.getProfessionalIndividualRecordById?.agent?.code}
								</Text>
							)}
							{data?.getProfessionalIndividualRecordById?.agent?.name && (
								<Text color="white" fontWeight="normal">
									{data?.getProfessionalIndividualRecordById?.agent?.name}
								</Text>
							)}
						</Box>
					}
				>
					<Text cursor="pointer" fontSize="sm" fontWeight="semibold" color="gray.500">
						{data?.getProfessionalIndividualRecordById?.agent?.code}
					</Text>
				</Tooltip>
			)}
		</>
	);
}
