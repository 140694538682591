import React from "react";
import { RadioGroup, Stack, Radio, FormControl, Box, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const RadioFormField = props => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(props.initValue?props.initValue:"")
    const {
        name,
        label,
        radios,
        required,
        callbackValueChange,
        direction="column"
    } = props;
    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                return (
                <Box mb="4" mt="4">
                <FormControl id={name} >
                    {label&&<FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>}
                    <RadioGroup 
                        onChange={e=>{
                            form.setFieldValue(name, e)
                            setValue(e)
                            if(callbackValueChange){
                                callbackValueChange(e)
                            }
                        }} 
                        value={value}
                    >
                        <Stack direction={direction} ml="4">
                            {
                                radios?.map((item,index)=>{
                                    return <Radio isDisabled={item?.isDisabled?true:false} key={`rd-${index}`} value={item.value}>{t(item.label)}{t(item?.secondLabel?item?.secondLabel:"")}</Radio>
                                })
                            }
                        
                        </Stack>
                    </RadioGroup>
                    {meta.touched && meta.error && (
                        <FormHelperText
                            id="error-message-password"
                            color="red.400"
                            fontSize="12px"
                        >
                            {t(name)}{" "}
                            {t("is")}{" "}
                            {t(meta.error)}
                        </FormHelperText>
                    )}
                </FormControl>

            </Box>
                
            )}}
        </Field>
    );
};

export default RadioFormField;
