/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Container, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import {
	faBuilding,
	faHandshake,
	faHandshakeSlash,
	faRetweet,
	faUser,
	faUserEdit,
	faUserMinus,
	faUserPlus,
	faUsers,
	faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IndividualBadgeCount, individualBadgeType } from '../../components/ManageAgentPartner/Miscellaneous/IndividualBadgeCount';
import { IntermediaryBadgeCount, intermediaryBadgeType } from '../../components/ManageAgentPartner/Miscellaneous/IntermediaryBadgeCount';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import IntermediaryTerminationBranchManagerBadgeCount from '../../components/ManageAgentPartner/Miscellaneous/IntermediaryTerminationBranchManagerBadgeCount';
import IntermediaryTerminationPartnerBadgeCount from '../../components/ManageAgentPartner/Miscellaneous/IntermediaryTerminationPartnerBadgeCount';
import LabelChild from '../../components/ManageAgentPartner/Miscellaneous/LabelChild';
import useStore from '../../store';
import c from '../../constant';

function ManagePartnerRequest() {
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state.currentUser);

	const menu = [
		{
			title: 'Agent Partner Request',
			path: `/manage-agent-partner/partner-request?page=1&status=${
				c.stepOfficeRoles.includes(user?.position) ? 'ReviewingAgent' : 'ReviewingAgentFinal'
			}`,
			icon: faUserPlus,
			badge: <IndividualBadgeCount type={individualBadgeType.newContract} />,
		},
		{
			title: 'Agent Termination Request',
			path: `/manage-agent-partner/termination-request?page=1&status=Reviewing`,
			icon: faUserMinus,
			badge: <IndividualBadgeCount type={individualBadgeType.terminateContract} />,
		},
		{
			title: 'Branch Change Request',
			path: `/manage-agent-partner/branch-change-request?page=1&status=Reviewing`,
			icon: faUserEdit,
			badge: <IndividualBadgeCount type={individualBadgeType.changeBranch} />,
		},
	];

	const intermediaryPartneringMenus = [
		{
			title: 'Request for Partner with Insurance Company',
			path: `/intermediary/partner-request?type=new&page=1`,
			icon: faHandshake,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.newPartner} />,
		},
		{
			title: 'Request for Termination Partner with Insurance Company',
			path: `/intermediary/termination-partner-request?page=1`,
			icon: faHandshakeSlash,
			badge: <IntermediaryTerminationPartnerBadgeCount />,
		},
	];

	const intermediaryBranchingMenus = [
		{
			title: 'Request for New Branch',
			path: `/intermediary/branch-request?page=1`,
			icon: faBuilding,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.newBranch} />,
		},
		{
			title: 'Request for Nomination of Branch Manager',
			path: `/intermediary/branch-manager-change-request?page=1`,
			icon: faUser,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.changeBranchManager} />,
		},
		{
			title: 'Request for Termination of Branch Manager',
			path: `/intermediary/branch-manager-termination-request?page=1`,
			icon: faUserSlash,
			badge: <IntermediaryTerminationBranchManagerBadgeCount />,
		},
		{
			title: 'Request for Termination of Branch',
			path: `/intermediary/branch-termination-request?page=1`,
			icon: faBuilding,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.requestTerminateBranch} />,
		},
	];

	const intermediaryProfessionalMenus = [
		{
			title: 'Request for New Professional Individual',
			path: `/intermediary/professional-individual-request?page=1`,
			icon: faUsers,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.newProfessional} />,
		},
		{
			title: 'Request for Change Branch of Professional Individual',
			path: `/intermediary/professional-individual-branch-change-request?page=1`,
			icon: faRetweet,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.changeBranchProfessional} />,
		},
		{
			title: 'Request for Termination of Professional Individual',
			path: `/intermediary/professional-individual-termination-request?page=1`,
			icon: faUserSlash,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType.terminationProfessional} />,
		},
	];

	const intermediarySaleProfessionalMenus = [
		{
			title: 'Request for New Sale Staff',
			path: `/intermediary/sale-professional-request?page=1&type=new`,
			icon: faUsers,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType?.requestNewSaleProfessional} />,
		},
		{
			title: 'Request for Change Branch of Sale Staff',
			path: `/intermediary/sale-professional-request?page=1&type=change`,
			icon: faRetweet,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType?.requestBranchChangeSaleProfessional} />,
		},
		{
			title: 'Request for Termination of Sale Staff',
			path: `/intermediary/sale-professional-request?page=1&type=terminate`,
			icon: faUserSlash,
			badge: <IntermediaryBadgeCount type={intermediaryBadgeType?.requestTerminateSaleProfessional} />,
		},
	];

	function menuItem({ item, index }) {
		return (
			<Link key={`menu-${index}`} to={item.path}>
				<Flex
					h="64px"
					alignItems="center"
					bg="white"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					p="16px"
					cursor="pointer"
				>
					<FontAwesomeIcon icon={item?.icon} color="#303030" p="2" style={{ fontSize: 20 }} />
					<Text fontSize="md" fontWeight="md" noOfLines={1} ml="4">
						{t(item?.title)}
					</Text>
					<>{item?.badge}</>
				</Flex>
			</Link>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Manage Agent Partner',
							path: '#',
						},
					]}
				/>
				<Box pb="32px" w="100%" mt="5">
					<LabelChild
						label="Individual Insurance Agent"
						child={
							<>
								<SimpleGrid columns={[1, 2, 3]} spacingX={5} spacingY={5}>
									{menu.map((item, index) => (
										<>{menuItem({ item, index })}</>
									))}
								</SimpleGrid>
							</>
						}
					/>
					<Box h="4" />
					<LabelChild
						label="Insurance Agent Company"
						child={
							<>
								<SimpleGrid columns={1} spacingY={5}>
									<SimpleGrid columns={[1, 2, 3]} spacingX={5} spacingY={5}>
										{intermediaryPartneringMenus.map((item, index) => (
											<>{menuItem({ item, index })}</>
										))}
									</SimpleGrid>
									<SimpleGrid columns={[1, 2, 3]} spacingX={5} spacingY={5}>
										{intermediaryBranchingMenus.map((item, index) => (
											<>{menuItem({ item, index })}</>
										))}
									</SimpleGrid>
									<SimpleGrid columns={[1, 2, 3]} spacingX={5} spacingY={5}>
										{intermediaryProfessionalMenus.map((item, index) => (
											<>{menuItem({ item, index })}</>
										))}
									</SimpleGrid>
									<SimpleGrid columns={[1, 2, 3]} spacingX={5} spacingY={5}>
										{intermediarySaleProfessionalMenus.map((item, index) => (
											<>{menuItem({ item, index })}</>
										))}
									</SimpleGrid>
								</SimpleGrid>
							</>
						}
					/>
				</Box>
			</Container>
		</>
	);
}

export default ManagePartnerRequest;
