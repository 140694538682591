/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, Text, useToast, Box } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import UpdateProfessionalStatus from './UpdateProfessionalStatus';
import useStore from '../../../../store';
import c from '../../../../constant';
import Loading from '../../../../components/Loading';
import NoData from '../../../../components/NoData';
import TableHead from '../../../../components/TableHead';
import TableData from '../../../../components/TableData';
import AgentCode from '../../../../components/AgentCode';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryTermination from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryTermination';

const QUERY_DATA = gql`
	query getNotifications($id: String!) {
		getProfessionalIndividualByDetailId(id: $id) {
			id
			status
			specializedCertificate
			agent {
				code
				name
				latinName
			}
			branch {
				name
				nameLatin
			}
			description
			currentBranch
			endDate
			terminationType
			terminationDescription
		}
	}
`;

function ProfessionalIndividualListTermination({ id = null, callback = () => null, type = 'new', from = 'partner' }) {
	const toast = useToast();
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const [profData, setProfData] = useState(null);
	const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA);

	useEffect(() => {
		if (data) {
			setProfData(data?.getProfessionalIndividualByDetailId);
		}
	}, [data]);

	useEffect(() => {
		if (id) {
			loadData({ variables: { id } });
		}
	}, [id]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (profData) {
			let isCompleted = true;
			profData?.forEach((item) => {
				if (!item?.status) {
					isCompleted = false;
				}
			});
			callback({ id, isCompleted });
		}
	}, [profData]);

	function updateProfData(data) {
		const tmp = [];
		profData?.forEach((item) => {
			if (item?.id === data?.id) {
				tmp.push(data);
			} else {
				tmp.push(item);
			}
		});
		setProfData(tmp);
	}

	function getTerminationTypeLabel(type) {
		let label = null;
		reasons?.forEach((r) => {
			if (type === r?.value) {
				label = currentLang === 'kh' ? r?.labelKh : r?.labelEn;
			}
		});
		return label;
	}

	return (
		<>
			{loading && (
				<Box width="100%">
					<Loading />
				</Box>
			)}
			{!loading && profData?.length <= 0 && (
				<Box width="100%">
					<NoData />
				</Box>
			)}
			{!loading && profData?.length > 0 && (
				<Box width="100%">
					<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
						<Table variant="simple">
							<Thead>
								<Tr>
									<TableHead title="Registration Number" />
									<TableHead title="Name" />
									<TableHead title="Branch" />
									<TableHead title="Termination Date" />
									<TableHead title="Type of Termination" />
									<TableHead title="Reason of Termination" isNumeric />
									{/* <TableHead title="Status" />
									<TableHead title="Reason of Rejection" isNumeric={true} /> */}
								</Tr>
							</Thead>
							<Tbody>
								{profData?.map((item, index) => {
									return (
										<Tr key={`pf-i-${index}`}>
											<TableData pt={3} pb={3} child={<AgentCode code={item?.agent?.code} />} />
											<TableData pt={3} pb={3} title={item?.agent?.name} />
											<TableData pt={3} pb={3} title={item?.currentBranch?.name} />
											<TableData pt={3} pb={3} child={<LocalizedDate dateString={item?.endDate} dateFormat={c.dateOnlyFormat} />} />
											<TableData
												pt={3}
												pb={3}
												child={<IntermediaryTermination type={getTerminationTypeLabel(item?.terminationType)} />}
											/>
											<TableData pt={3} pb={3} title={item?.terminationDescription} isNumeric />
											{/* <TableData
												pt={3}
												pb={3}
												child={
													<UpdateProfessionalStatus
														id={item?.id}
														status={item?.status}
														onCallback={(e) => updateProfData(e)}
														type={type}
														from={from}
													/>
												}
											/>
											<TableData
												pt={3}
												pb={3}
												title={item?.status === 'rejected' && item?.description}
												titleColor="red.400"
												isNumeric={true}
											/> */}
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot>
								<Tr>
									<TableHead title="Registration Number" />
									<TableHead title="Name" />
									<TableHead title="Branch" />
									<TableHead title="Termination Date" />
									<TableHead title="Type of Termination" />
									<TableHead title="Reason of Termination" isNumeric />
									{/* <TableHead title="Status" />
									<TableHead title="Reason of Rejection" isNumeric={true} /> */}
								</Tr>
							</Tfoot>
						</Table>
					</TableContainer>
				</Box>
			)}
		</>
	);
}

export default ProfessionalIndividualListTermination;

const reasons = [
	{ labelEn: 'Registration Card Not Renewed', labelKh: 'មិនបានបន្តសុពលភាពបណ្ណចុះបញ្ជី', value: '6' },
	{ labelEn: 'Terminate Contract', labelKh: 'បញ្ចប់កិច្ចសន្យា', value: '5' },
	{ labelEn: 'Resign', labelKh: 'លាឈប់', value: '3' },
	{ labelEn: 'Terminated', labelKh: 'ត្រូវបានបញ្ឈប់', value: '2' },
	{ labelEn: 'Other', labelKh: 'ផ្សេងៗ', value: '1' },
];
