import React from "react";
import {
    Box, Flex, Text,
    Image
} from "@chakra-ui/react";
import "./index.css"
import LOGO from "../../../assets/images/logo.png"
import Underlint from "../../../assets/images/underline.png"
import GeoCodeResoliver from "../../../components/Geo/GeoCodeResolver"
import c from "../../../constant"
import moment from "moment"
import { useTranslation } from "react-i18next";
import DownloadAndViewButtonUserUpload from "../../../components/Files/DownloadAndViewButtonUserUpload"
import getPositionLabel from "../../../utils/getPositionLabel"
import _ from 'lodash';

const headerColor = "#48769A"

const headerFontSize = "14px"
const headerFontFamily = "Moul"

const rightBlankWidth = 12
const p1LWidth = 140
const p1MaxWidth = 500

const p2ALWidth = 180

const p7ALWidth = 100
const p7AMaxWidth = 300

function PrintEmployee({data}) {
    const { t } = useTranslation();

    moment.locale("km")    

    function showText({label,value,lWidth,maxWidth,minWidth}){
        return(
            <Flex alignItems="center" mb="4px" maxW={maxWidth+"px"} minW={minWidth+"px"}>
                <Text width={lWidth+"px"} minW={lWidth+"px"} maxW={lWidth+"px"}>{label}</Text><Text ml="4px" fontWeight="bold">:</Text><Text ml="4px" fontWeight={500} mr="2">{value}</Text>
            </Flex>
        )
    }

    function displayAddress(
        isCurrentAddressManual,
        currentAddressManual,
        currentAddress
    ){
        return isCurrentAddressManual?currentAddressManual:getAddress(currentAddress)
    }

    function getAddress(stringData) {
        if(stringData){
        const data= JSON.parse(stringData)
        return(<GeoCodeResoliver
            province={data?.province}
            district={data?.district}
            commune={data?.commune}
            village={data?.village}
            houseNumber={data?.houseNumber}
            streetNumber={data?.streetNumber}
        />)} else{
            return null
        }
    }

    function viewFile(value){
        const tmpValue = value?JSON.parse(value):null
        return tmpValue?<DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize}/>:"" 
    }

    return (
        <>
            {/* <PrintLayout> */}
                <Box bg="white" className="printEmployeeContainer">
                <Flex>
                    <Flex minW="180px" w="180px" align="center" direction="column">
                        <Image mt="16px" src={LOGO} w="114px" h="114px" />
                        <Text color={headerColor} fontSize="14px" fontFamily="Moul">និយ័តករធានារ៉ាប់រងកម្ពុជា</Text>
                        {/* <Text
                            borderBottom="1px solid #457499"
                            lineHeight="16px"
                            mt="4px" color="#62B3ED" fontWeight="600">លេខ...............................ន.ធ.ក.</Text> */}
                    </Flex>
                    <Flex flexGrow="1" alignItems="center" direction="column">
                        <Text fontSize="14px" fontFamily="Moul" color={headerColor} >
                            ព្រះរាជាណាចក្រកម្ពុជា
                        </Text>
                        <Text mt="4px" color={headerColor} fontWeight="600">
                            ជាតិ សាសនា ព្រះមហាក្សត្រ
                        </Text>
                        <Image src={Underlint} h="auto" w="90px" />
                    </Flex>
                    <Flex w="170px" minW="170px"></Flex>
                </Flex>

                    <Flex justifyContent="center">
                        <Text textAlign="center" fontFamily={headerFontFamily} fontSize={headerFontSize} color={headerColor}>ប្រវត្តិរូបមន្ត្រីរាជការ<br/>និយ័តករធានារ៉ាប់រងកម្ពុជា</Text>
                    </Flex>
                    <Flex justifyContent="flex-end">
                        <Image src={data?.profileImage?.file} mt="-180px" w="128px" h="auto"/>
                    </Flex>
                    <Flex mt="10px">
                        <Text fontFamily={headerFontFamily} fontSize={headerFontSize}>១. ព័ត៌មានផ្ទាល់ខ្លួន</Text>
                    </Flex>
                    <Flex mt="10px">
                        <Flex flex="1" direction="column">
                            {showText({
                                label:"អត្តលេខមន្ត្រីរាជការ",
                                value:data?.govtNumber,
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                            {showText({
                                label:"គោត្តនាម និងនាម",
                                value:data?.fullName,
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                            <Flex>
                                {showText({
                                    label:"ភេទ",
                                    value:t(data?.gender),
                                    minWidth:p1LWidth+rightBlankWidth

                                })}
                                {showText({
                                    label:"ថ្ងៃខែឆ្នាំកំណើត",
                                    value:data?.dob?moment(data?.dob).format(c.excelDateOnlyTextFormat):""
                                })}
                            </Flex>
                        </Flex>
                        <Flex flex="1" direction="column">
                            {showText({
                                label:"លេខប័ណ្ណសម្គាល់មន្ត្រីកសហវ",
                                value:data?.mefNumber
                            })}
                            {showText({
                                label:"ជាអក្សរឡាតាំង",
                                value:data?.fullNameLatin?data?.fullNameLatin?.toUpperCase():""
                            })}
                            {showText({
                                label:"ស្ថានភាពគ្រួសារ",
                                value:t(data?.maritalStatus)
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="column">
                        {showText({
                            label:"កន្លែងកំណើត",
                            value:displayAddress(data?.isPobManual,data?.pobManual,data?.pob),
                            lWidth:p1LWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"អាសយដ្ឋានបច្ចុប្បន្ន",
                            value:displayAddress(data?.isCurrentAddressManual,data?.currentAddressManual,data?.currentAddress),
                            lWidth:p1LWidth,
                            // maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"អុីម៉ែល",
                            value:data?.email,
                            lWidth:p1LWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"លេខទូរសព្ទ",
                            value:data?.phoneNumber,
                            lWidth:p1LWidth,
                            maxWidth:p1MaxWidth
                        })}
                    </Flex>

                    <Flex>
                        <Flex flex="1" direction="column">
                            {showText({
                                label:"អត្តសញ្ញាណប័ណ្ណ",
                                value:data?.idCardNumber,
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                            {showText({
                                label:"លិខិតឆ្លងដែន",
                                value:data?.passportNumber,
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                        </Flex>
                        <Flex flex="1" direction="column">
                            {showText({
                                label:"កាលបរិច្ឆេទផុតកំណត់",
                                value:data?.idCardExpirationDate?moment(data?.idCardExpirationDate).format(c.excelDateOnlyTextFormat):"",
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                            {showText({
                                label:"កាលបរិច្ឆេទផុតកំណត់",
                                value:data?.passportExpirationDate?moment(data?.passportExpirationDate).format(c.excelDateOnlyTextFormat):"",
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                        </Flex>
                        
                    </Flex>
                    <Flex  flex="1" direction="column">
                            {showText({
                                label:"ឯកសារអត្តសញ្ញាណប័ណ្ណ",
                                value:viewFile(data?.documentIDCard),
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                            {showText({
                                label:"ឯកសារលិខិតឆ្លងដែន",
                                value:viewFile(data?.documentPassport),
                                lWidth:p1LWidth,
                                maxWidth:p1MaxWidth
                            })}
                        </Flex>

                    <Box>
                        <Text mt="10px" mb="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>២. ព័ត៌មានអំពីស្ថានភាពមុខងារសាធារណៈ/មន្ត្រីលក្ខន្តិកៈ</Text>
                        <Text mb="10px" ml="22px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ក. ចូលបម្រើការងាររដ្ឋដំបូង</Text>
                    </Box>
                    <Flex>
                        <Flex flex="1" direction="column">
                            {showText({
                                label:"កាលបរិច្ឆេទចូលបំរើការងាររដ្ឋដំបូង",
                                value:data?.firstWorkingPlace?.firstWorkingstartDate?moment(data?.firstWorkingPlace?.firstWorkingstartDate).format(c.excelDateOnlyTextFormat):"",
                                lWidth:p2ALWidth,
                                maxWidth:p1MaxWidth
                            })}
                        </Flex>
                        <Flex flex="1" direction="column">
                            {showText({
                                label:"កាលបរិច្ឆេទតាំងស៊ប់",
                                value:data?.firstWorkingPlace?.firstWorkingPassedDate?moment(data?.firstWorkingPlace?.firstWorkingPassedDate).format(c.excelDateOnlyTextFormat):"",
                                maxWidth:p1MaxWidth
                            })}
                        </Flex>
                    </Flex>
                    <Box>
                        {showText({
                            label:"ក្របខ័ណ្ឌ ឋានន្តរស័ក្តិ និងថ្នាក់",
                            value:data?.firstWorkingPlace?.firstWorkingJobTitle,
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"មុខតំណែង",
                            value:data?.firstWorkingPlace?.firstWorkingPosition,
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"ក្រសួង/ស្ថាប័ន",
                            value:data?.firstWorkingPlace?.firstWorkingInstitution,
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"អង្គភាព",
                            value:data?.firstWorkingPlace?.firstWorkingOrganization,
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"នាយកដ្ឋាន/អង្គភាព/មន្ទីរ",
                            value:data?.firstWorkingPlace?.firstWorkingDepartment,
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"ការិយាល័យ",
                            value:data?.firstWorkingPlace?.firstWorkingOffice,
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                        {showText({
                            label:"ឯកសារយោង",
                            value:viewFile(data?.firstWorkingPlace?.documentFirst),
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                    </Box>
                    <Text mb="10px" ml="22px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ខ. ស្ថានភាពមុខងារបច្ចុប្បន្ន</Text>
                     
                    <Flex>  

                    
                    <Flex flex="1"  direction="column">
                            {showText({
                                label:"ក្របខ័ណ្ឌ ឋានន្តរស័ក្តិ និងថ្នាក់",
                                value:data?.currentWorkingStatus?.currentWorkingStatusJobTitle,
                                lWidth:p2ALWidth,
                                // maxWidth:p1MaxWidth
                            })}</Flex>
                        <Flex flex="1"  direction="column">
                            {showText({
                                label:"កាលបរិច្ឆេទប្តូរក្របខ័ណ្ឌ ឋានន្តរស័កិ្ត និងថ្នាក់ចុងក្រោយ",
                                value:data?.currentWorkingStatus?.currentWorkingStatusDateOfChangeJobTitle?moment(data?.currentWorkingStatus?.currentWorkingStatusDateOfChangeJobTitle).format(c.excelDateOnlyTextFormat):"",
                            })}
                        </Flex>
                    </Flex>
                    <Box>
                        {showText({
                            label:"មុខតំណែង",
                            value:data?.currentWorkingStatus?.currentWorkingStatusPosition?getPositionLabel(data?.currentWorkingStatus?.currentWorkingStatusPosition):"",
                            lWidth:p2ALWidth,
                        })}
                        {showText({
                            label:"កាលបរិច្ឆេទទទួលមុខតំណែងចុងក្រោយ",
                            value:data?.currentWorkingStatus?.currentWorkingStatusDateOfChangePosition?moment(data?.currentWorkingStatus?.currentWorkingStatusDateOfChangePosition).format(c.excelDateOnlyTextFormat):"",
                            lWidth:p2ALWidth+40,
                        })}
                        {showText({
                            label:"អង្គភាព",
                            value:data?.currentWorkingStatus?.currentWorkingStatusOrganization||"និយ័តករធានារ៉ាប់រងកម្ពុជា",
                            lWidth:p2ALWidth,
                        })}
                        {showText({
                            label:"ឯកសារយោង",
                            value:viewFile(data?.currentWorkingStatus?.documentCurrent),
                            lWidth:p2ALWidth,
                            maxWidth:p1MaxWidth
                        })}
                    </Box>
                    <Box className="tableBox">
                        <Text mb="10px" ml="22px" mt="6px" fontFamily={headerFontFamily} fontSize={headerFontSize}>គ. តួនាទីបន្ថែមលើមុខងារបច្ចុប្បន្ន(ឋានៈស្មើ)</Text>
                        <table className="printTableEmployee">
                            <tr>
                                <th className="textCenter" width="40px">ល.រ</th>
                                <th className="textCenter" width="130px">ថ្ងៃខែឆ្នាំ</th>
                                <th>ឯកសារ</th>
                                <th>មុខតំណែង</th>
                                <th>ឋានៈស្មើ</th>
                                <th>អង្គភាព</th>
                            </tr>
                            {data?.additionalRoleOnCurrentJob?.length>0?
                            _.orderBy(data?.additionalRoleOnCurrentJob,[`no`],[`asc`])?.map((item,index)=>{
                                return(
                                    <tr key={`aj-${index}`}>
                                        <td className="textCenter">{item?.no}</td>
                                        <td className="textCenter">{item?.date?moment(item?.date).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td>{viewFile(item?.file)}</td>
                                        <td>{item?.position}</td>
                                        <td>{item?.title}</td>
                                        <td>{item?.division}</td>
                                    </tr>
                                )
                            }):<tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>}
                        </table>
                    </Box>

                    <Box className="tableBox">
                    <Text mb="10px" ml="22px" mt="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ឃ. ស្ថានភាពនៅស្ថិតនៅក្រៅក្របខ័ណ្ឌដើម</Text>
                    <table className="printTableEmployee">
                        <tr>
                            <th className="textCenter" width="40px">ល.រ</th>
                            <th className="textCenter">បរិយាយ</th>
                            <th className="textCenter" width="130px">កាលបរិច្ឆេទចាប់ផ្តើម</th>
                            <th className="textCenter" width="130px">កាលបរិច្ឆេទបញ្ចប់</th>
                        </tr>
                        {data?.situationRemainsWithinTheOriginalFramework?.length>0?
                        _.orderBy(data?.situationRemainsWithinTheOriginalFramework,[`no`],[`asc`])?.map((item,index)=>{
                            return(
                                <tr key={`sf-${index}`}>
                                    <td className="textCenter">{item?.no}</td>
                                    <td>{item?.description}</td>
                                    <td className="textCenter">{item?.startDate?moment(item?.startDate).format(c.excelDateOnlyTextFormat):""}</td>
                                    <td className="textCenter">{item?.endDate?moment(item?.endDate).format(c.excelDateOnlyTextFormat):""}</td>
                                </tr>
                            )
                        }):
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>}
                    </table>
                    </Box>

                    <Box className="tableBox">
                    <Text mb="10px" ml="22px" mt="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ង. ស្ថានភាពនៅភាពទំនេរគ្មានបៀវត្ស</Text>
                    <table className="printTableEmployee">
                        <tr>
                            <th className="textCenter" width="40px">ល.រ</th>
                            <th className="textCenter">បរិយាយ</th>
                            <th className="textCenter" width="130px">កាលបរិច្ឆេទចាប់ផ្តើម</th>
                            <th className="textCenter" width="130px">កាលបរិច្ឆេទបញ្ចប់</th>
                        </tr>
                        {data?.unpaidVacancies?.length>0?
                        _.orderBy(data?.unpaidVacancies,[`no`],[`asc`])?.map((item,index)=>{
                            return(
                        <tr key={`u-${index}`}>
                            <td className="textCenter">{item?.no}</td>
                            <td>{item?.description}</td>
                            <td className="textCenter">{item?.startDate?moment(item?.startDate).format(c.excelDateOnlyTextFormat):""}</td>
                            <td className="textCenter">{item?.endDate?moment(item?.endDate).format(c.excelDateOnlyTextFormat):""}</td>
                        </tr>
                            )
                        }):<tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>}
                    </table>
                    </Box>

                    <Box>
                        <Text mt="10px" mb="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>៣. ប្រវត្តិការងារ</Text>
                    </Box>

                    <Box className="tableBox">
                        <Text mb="10px" ml="22px" mt="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ក. ក្នុងវិស័យមុខងារសាធារណៈ</Text>
                        <table className="printTableEmployee">
                            <thead>
                            <tr>
                                <td className="textCenter" colSpan="2">ថ្ងៃខែឆ្នាំបំពេញការងារ</td>
                                <td className="textCenter" rowSpan="2">ក្រសួង/ស្ថាប័ន</td>
                                <td className="textCenter" rowSpan="2">អង្គភាព</td>
                                <td className="textCenter" rowSpan="2">មុខតំណែង</td>
                                <td className="textCenter" rowSpan="2">ផ្សេងៗ</td>
                            </tr>
                            <tr>
                                <td className="textCenter" width="130px">ចូល</td>
                                <td className="textCenter" width="130px">បញ្ចប់</td>
                            </tr>
                            </thead>
                            <tbody>
                                {data?.inTheFieldOfPublicFunctions?.length>0?
                                data?.inTheFieldOfPublicFunctions?.map((item,index)=>{
                                    return(
                                        <tr key={`ipf-${index}`}>
                                            <td className="textCenter">{item?.startDate?moment(item?.startDate).format(c.excelDateOnlyTextFormat):""}</td>
                                            <td className="textCenter">{item?.endDate?moment(item?.endDate).format(c.excelDateOnlyTextFormat):""}</td>
                                            <td>{item?.institution}</td>
                                            <td>{item?.division}</td>
                                            <td>{item?.position}</td>
                                            <td>{item?.other}</td>
                                        </tr>
                                    )
                                }):<tr>
                                <td className="textCenter"></td>
                                <td className="textCenter"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            </tbody>
                        </table>
                    </Box>

                    <Box className="tableBox">
                        <Text mb="10px" ml="22px" mt="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ខ. ក្នុងវិស័យឯកជន</Text>
                        <table className="printTableEmployee">
                            <thead>
                            <tr>
                                <td className="textCenter" colSpan="2">ថ្ងៃខែឆ្នាំបំពេញការងារ</td>
                                <td className="textCenter" rowSpan="2">គ្រឹះស្ថាន/អង្គភាព</td>
                                <td className="textCenter" rowSpan="2">មុខតំណែង</td>
                                <td className="textCenter" rowSpan="2">ជំនាញ/បច្ចេកទេស</td>
                                <td className="textCenter" rowSpan="2">ផ្សេងៗ</td>
                            </tr>
                            <tr>
                                <td className="textCenter" width="130px">ចូល</td>
                                <td className="textCenter" width="130px">បញ្ចប់</td>
                            </tr>
                            </thead>
                            <tbody>
                                {data?.inThePrivateSector?.length>0?
                                data?.inThePrivateSector?.map((item,index)=>{
                                    return(
                                        <tr key={`ips-${index}`}>
                                    <td className="textCenter">{item?.startDate?moment(item?.startDate).format(c.excelDateOnlyTextFormat):""}</td>
                                    <td className="textCenter">{item?.endDate?moment(item?.endDate).format(c.excelDateOnlyTextFormat):""}</td>
                                    <td>{item?.institutionDivision}</td>
                                    <td>{item?.position}</td>
                                    <td><pre style={{whiteSpace: "pre-wrap"}}>{item?.technicalSkill}</pre></td>
                                    <td>{item?.other}</td>
                                </tr>
                                    )
                                }):<tr>
                                <td className="textCenter"></td>
                                <td className="textCenter"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            </tbody>
                        </table>
                    </Box>

                    <Box>
                        <Text mt="10px" mb="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>៤. គ្រឿងឥស្សរិយយស ប័ណ្ណសរសើរ ឬទណ្ឌកម្មវិន័យ</Text>
                    </Box>
                    <Box className="tableBox">
                        <table className="printTableEmployee">
                            <thead>
                            <tr>
                                <th className="textCenter" width="130px">លេខឯកសារ</th>
                                <th className="textCenter" width="130px">កាលបរិច្ឆេទ</th>
                                <th className="textCenter">ស្ថាប័ន/អង្គភាព(ស្នើសុំ)</th>
                                <th className="textCenter">ខ្លឹមសារ</th>
                                <th className="textCenter">ប្រភេទ</th>
                                <th className="textCenter">ឯកសារ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan="6">គ្រឿងឥស្សរិយយស ប័ណ្ណសរសើរ</td>
                            </tr>
                            {data?.medalCertificateOfAppreciation?.length>0?data?.medalCertificateOfAppreciation?.map((item,index)=>{
                                return(
                                    <tr key={`mca-${index}`}>
                                        <td className="textCenter">{item?.fileNumber}</td>
                                        <td className="textCenter">{item?.date?moment(item?.date).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td>{item?.institution}</td>
                                        <td>{item?.content}</td>
                                        <td>{item?.type}</td>
                                        <td>{viewFile(item?.file)}</td>
                                    </tr>
                                )
                            }):<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            <tr>
                                <td colSpan="6">ទណ្ឌកម្មវិន័យ</td>
                            </tr>
                            {data?.discipline?.length>0?data?.discipline?.map((item,index)=>{
                                return(
                                <tr key={`de-${index}`}>
                                    <td className="textCenter">{item?.fileNumber}</td>
                                    <td className="textCenter">{item?.date?moment(item?.date).format(c.excelDateOnlyTextFormat):""}</td>
                                    <td>{item?.institution}</td>
                                    <td>{item?.content}</td>
                                    <td>{item?.type}</td>
                                    <td>{viewFile(item?.file)}</td>
                                </tr>
                                )
                            }):<tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>}
                            </tbody>
                        </table>
                    </Box>

                    <Box>
                        <Text mt="10px" mb="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>៥. កម្រិត​វប្បធម៌ទូទៅ ការបណ្តុះបណ្តាលវិជ្ជាជីវៈ និងការបណ្តុះបណ្តាលបន្ត</Text>
                    </Box>
                    <Box className="tableBox">
                        <table className="printTableEmployee">
                            <thead>
                            <tr>
                                <th className="textCenter">វគ្គ ឬកម្រិតសិក្សា</th>
                                <th className="textCenter">គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល</th>
                                <th className="textCenter">សញ្ញាបត្រដែលទទួលបាន</th>
                                <th className="textCenter" width="130px">ថ្ងៃខែឆ្នាំចូលសិក្សា</th>
                                <th className="textCenter" width="130px">ថ្ងៃខែឆ្នាំបញ្ចប់សិក្សា</th>
                                <th className="textCenter">ឯកសារ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan="6">កម្រិត​វប្បធម៌ទូទៅ</td>
                            </tr>
                            {data?.generalEducation?.length>0?data?.generalEducation?.map((item,index)=>{
                                return(
                                    <tr key={`ge-${index}`}>
                                        <td>{item?.courseOrLevelOfStudy}</td>
                                        <td>{item?.trainingInstitutions}</td>
                                        <td>{item?.DegreeObtained}</td>
                                        <td className="textCenter">{item?.dateOfEnrollment?moment(item?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td className="textCenter">{item?.dateOfGraduation?moment(item?.dateOfGraduation).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td>{viewFile(item?.file)}</td>
                                    </tr>
                                )
                            }):<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            <tr>
                                <td colSpan="6">កម្រិតសញ្ញាបត្រ</td>
                            </tr>
                            {data?.degree?.length>0?data?.degree?.map((item,index)=>{
                                return(
                                    <tr key={`deg-${index}`}>
                                        <td>{item?.courseOrLevelOfStudy}</td>
                                        <td>{item?.trainingInstitutions}</td>
                                        <td>{item?.DegreeObtained}</td>
                                        <td className="textCenter">{item?.dateOfEnrollment?moment(item?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td className="textCenter">{item?.dateOfGraduation?moment(item?.dateOfGraduation).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td>{viewFile(item?.file)}</td>
                                    </tr>
                                )
                            }):<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            <tr>
                                <td colSpan="6">ជំនាញបច្ចេកទេស</td>
                            </tr>
                            {data?.specialization?.length>0?data?.specialization?.map((item,index)=>{
                                return(
                                    <tr key={`sp-${index}`}>
                                        <td>{item?.courseOrLevelOfStudy}</td>
                                        <td>{item?.trainingInstitutions}</td>
                                        <td>{item?.DegreeObtained}</td>
                                        <td className="textCenter">{item?.dateOfEnrollment?moment(item?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td className="textCenter">{item?.dateOfGraduation?moment(item?.dateOfGraduation).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td>{viewFile(item?.file)}</td>
                                    </tr>
                                )
                            }):<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            <tr>
                                <td colSpan="6">វគ្តបណ្តុះបណ្តាលវិជ្ជាជីវៈ(ក្រោម១២ខែ)</td>
                            </tr>
                            {data?.vocationalTraining?.length>0?data?.vocationalTraining?.map((item,index)=>{
                                return(
                                    <tr key={`vt-${index}`}>
                                        <td>{item?.courseOrLevelOfStudy}</td>
                                        <td>{item?.trainingInstitutions}</td>
                                        <td>{item?.DegreeObtained}</td>
                                        <td className="textCenter">{item?.dateOfEnrollment?moment(item?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td className="textCenter">{item?.dateOfGraduation?moment(item?.dateOfGraduation).format(c.excelDateOnlyTextFormat):""}</td>
                                        <td>{viewFile(item?.file)}</td>
                                    </tr>
                                )
                            }):<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                            </tbody>
                        </table>
                    </Box>

                    <Box>
                        <Text mt="10px" mb="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>៦. សមត្ថភាពភាសាបរទេស</Text>
                    </Box>
                    <Box className="tableBox">
                        <table className="printTableEmployee">
                            <tr>
                                <th className="textCenter" width="40px">ល.រ</th>
                                <th className="textCenter">ភាសា</th>
                                <th className="textCenter">អាន</th>
                                <th className="textCenter">សរសេរ</th>
                                <th className="textCenter">និយាយ</th>
                                <th className="textCenter">ស្តាប់</th>
                            </tr>
                            {data?.foreignLanguage?.length>0?data?.foreignLanguage?.map((item,index)=>{
                                return(
                                    <tr key={`fl-${index}`}>
                                        <td className="textCenter">{item?.no}</td>
                                        <td>{item?.language}</td>
                                        <td className="textCenter">{item?.read}</td>
                                        <td className="textCenter">{item?.write}</td>
                                        <td className="textCenter">{item?.speak}</td>
                                        <td className="textCenter">{item?.listen}</td>
                                    </tr>
                                )
                            }):<tr>
                                <td className="textCenter"></td>
                                <td></td>
                                <td className="textCenter"></td>
                                <td className="textCenter"></td>
                                <td className="textCenter"></td>
                                <td className="textCenter"></td>
                            </tr>}
                        </table>
                    </Box>

                    <Box>
                        <Text mt="10px" mb="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>៧. ស្ថានភាពគ្រួសារ</Text>
                    </Box>
                    <Text mb="10px" ml="22px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ក. ព័ត៌មានឪពុកម្តាយ</Text>
                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"ឈ្មោះឪពុក",
                                value:data?.familyStatus?.fatherName,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"ជាអក្សរឡាតាំង",
                                value:data?.familyStatus?.fatherNameLatin?data?.familyStatus?.fatherNameLatin?.toUpperCase():"",
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            {showText({
                                label:"ស្ថានភាព",
                                value:data?.familyStatus?.fatherStatus,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                    </Flex>

                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"ថ្ងៃខែឆ្នាំកំណើត",
                                value:data?.familyStatus?.fatherDob?moment(data?.familyStatus?.fatherDob).format(c.excelDateOnlyTextFormat):"",
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"សញ្ជាតិ",
                                value:data?.familyStatus?.fatherNationality,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>
                    </Flex>
                    <Flex>
                        {showText({
                            label:"ទីលំនៅបច្ចុប្បន្ន",
                            value:displayAddress(data?.familyStatus?.fatherIsCurrentAddressManual, data?.familyStatus?.fatherCurrentAddressManual, data?.familyStatus?.fatherCurrentAddress),
                            lWidth:p7ALWidth,
                        })}
                    </Flex>

                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"មុខរបរ",
                                value:data?.familyStatus?.fatherJob,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"ស្ថាប័ន/អង្គភាព",
                                value:data?.familyStatus?.fatherJobInstitution,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>
                    </Flex>


                    <Flex flex="1" mt="20px">
                        <Flex flex={1}>
                            {showText({
                                label:"ឈ្មោះម្តាយ",
                                value:data?.familyStatus?.motherName,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"ជាអក្សរឡាតាំង",
                                value:data?.familyStatus?.motherNameLatin?data?.familyStatus?.motherNameLatin?.toUpperCase():"",
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            {showText({
                                label:"ស្ថានភាព",
                                value:data?.familyStatus?.motherStatus,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                    </Flex>

                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"ថ្ងៃខែឆ្នាំកំណើត",
                                value:data?.familyStatus?.motherDob?moment(data?.familyStatus?.motherDob).format(c.excelDateOnlyTextFormat):"",
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"សញ្ជាតិ",
                                value:data?.familyStatus?.motherNationality,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>
                    </Flex>
                    <Flex>
                        {showText({
                            label:"ទីលំនៅបច្ចុប្បន្ន",
                            value:displayAddress(data?.familyStatus?.motherIsCurrentAddressManual, data?.familyStatus?.motherCurrentAddressManual, data?.familyStatus?.motherCurrentAddress),
                            lWidth:p7ALWidth,
                        })}
                    </Flex>

                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"មុខរបរ",
                                value:data?.familyStatus?.motherJob,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"ស្ថាប័ន/អង្គភាព",
                                value:data?.familyStatus?.motherJobInstitution,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>

                    </Flex>

                    <Flex flex={1}>
                        {showText({
                            label:"ឯកសារយោង",
                            value:viewFile(data?.familyStatus?.document),
                            lWidth:p7ALWidth,
                            maxWidth:p7AMaxWidth
                        })}
                    </Flex>


                    <Box className="tableBox">
                        <Text mb="10px" ml="22px" mt="6px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ខ. ព័ត៌មានបងប្អូន</Text>
                        <table className="printTableEmployee">
                            <thead>
                            <tr>
                                <td className="textCenter" width="40px">ល.រ</td>
                                <td className="textCenter">គោត្តនាម និងនាម</td>
                                <td className="textCenter">ជាអក្សរឡាតាំង</td>
                                <td className="textCenter">ភេទ</td>
                                <td className="textCenter" width="130px">ថ្ងៃខែឆ្នាំកំណើត</td>
                                <td className="textCenter">មុខរបរ(អង្គភាព)</td>
                            </tr>
                            </thead>
                            <tbody>
                                {data?.siblingInformation?.length>0?data?.siblingInformation?.map((item,index)=>{
                                    return(
                                        <tr key={`chi-${index}`}>
                                            <td className="textCenter">{item?.no}</td>
                                            <td className="textCenter">{item?.fullName}</td>
                                            <td className="textCenter">{item?.fullNameInLatin?item?.fullNameInLatin?.toUpperCase():""}</td>
                                            <td className="textCenter">{t(item?.gender)}</td>
                                            <td className="textCenter">{item?.dob?moment(item?.dob).format(c.excelDateOnlyTextFormat):""}</td>
                                            <td>{item?.job}</td>
                                        </tr>
                                    )
                                }):<tr>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td></td>
                                </tr>}
                            </tbody>
                        </table>
                    </Box>

                    <Text mb="10px" ml="22px" mt="10px" fontFamily={headerFontFamily} fontSize={headerFontSize}>គ. ព័ត៌មានសហព័ន្ធ</Text>
                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"ឈ្មោះប្តី ឫប្រពន្ធ",
                                value:data?.spouseStatus?.spouseName,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"ជាអក្សរឡាតាំង",
                                value:data?.spouseStatus?.spouseNameLatin?data?.spouseStatus?.spouseNameLatin?.toUpperCase():"",
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            {showText({
                                label:"ស្ថានភាព",
                                value:data?.spouseStatus?.spouseStatus,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                    </Flex>
                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"ថ្ងៃខែឆ្នាំកំណើត",
                                value:data?.spouseStatus?.spouseDob?moment(data?.spouseStatus?.spouseDob).format(c.excelDateOnlyTextFormat):"",
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"សញ្ជាតិ",
                                value:data?.spouseStatus?.spouseNationality,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>
                    </Flex>
                    <Flex>
                        {showText({
                            label:"ទីលំនៅបច្ចុប្បន្ន",
                            value: displayAddress(data?.spouseStatus?.spouseIsCurrentAddressManual, data?.spouseStatus?.spouseCurrentAddressManual, data?.spouseStatus?.spouseCurrentAddress),
                            lWidth:p7ALWidth,
                        })}
                    </Flex>

                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"មុខរបរ",
                                value:data?.spouseStatus?.spouseJob,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"ស្ថាប័ន/អង្គភាព",
                                value:data?.spouseStatus?.spouseJobInstitution,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>
                    </Flex>

                    <Flex flex="1">
                        <Flex flex={1}>
                            {showText({
                                label:"ប្រាក់ឧបត្ថម្ភ",
                                value:data?.spouseStatus?.spouseAllowance,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex flex={1}>
                            {showText({
                                label:"លេខទូរសព្ទ",
                                value:data?.spouseStatus?.spousePhoneNumber,
                                lWidth:p7ALWidth,
                                maxWidth:p7AMaxWidth
                            })}
                        </Flex>
                        <Flex w="180px">
                            
                        </Flex>
                    </Flex>
                    <Flex flex={1}>
                        {showText({
                            label:"ឯកសារយោង",
                            value:viewFile(data?.spouseStatus?.document),
                            lWidth:p7ALWidth,
                            maxWidth:p7AMaxWidth
                        })}
                    </Flex>


                    <Box className="tableBox">
                        <Text mb="10px" ml="22px" mt="6px" fontFamily={headerFontFamily} fontSize={headerFontSize}>ឃ. ព័ត៌មានកូន</Text>
                        <table className="printTableEmployee">
                            <thead>
                            <tr>
                                <td className="textCenter" width="40px">ល.រ</td>
                                <td className="textCenter">គោត្តនាម និងនាម</td>
                                <td className="textCenter">ជាអក្សរឡាតាំង</td>
                                <td className="textCenter">ភេទ</td>
                                <td className="textCenter" width="130px">ថ្ងៃខែឆ្នាំកំណើត</td>
                                <td className="textCenter">មុខរបរ</td>
                                <td className="textCenter">ប្រាក់ឧបត្ថម្ភ</td>
                                <td className="textCenter">ឯកសារយោង</td>
                            </tr>
                            </thead>
                            <tbody>
                                {data?.childInformation?.length>0?data?.childInformation?.map((item,index)=>{
                                    return(
                                        <tr key={`chinf-${index}`}>
                                            <td className="textCenter">{item?.no}</td>
                                            <td className="textCenter">{item?.fullName}</td>
                                            <td className="textCenter">{item?.fullNameInLatin?item?.fullNameInLatin?.toUpperCase():""}</td>
                                            <td className="textCenter">{t(item?.gender)}</td>
                                            <td className="textCenter">{item?.dob?moment(item?.dob).format(c.excelDateOnlyTextFormat):""}</td>
                                            <td>{item?.job}</td>
                                            <td>{item?.allowance}</td>
                                            <td>{viewFile(item?.file)}</td>
                                        </tr>
                                    )
                                }):<tr>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td className="textCenter"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>}
                            </tbody>
                        </table>
                    </Box>
                    
                </Box>
            {/* </PrintLayout> */}
        </>
    );
}

export default PrintEmployee;
