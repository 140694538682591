import React, { useEffect, useState } from 'react';
import {
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Center,
	Box
} from '@chakra-ui/react';
import c from '../../../constant';

function KhmerNameModal({ isOpen = false, onClose = () => null, data = null }) {

	const [nameArr, setNameArr] = useState([])

	useEffect(() => {
		if (data) {
			let tempData = data?.trim()
			let tempName = tempData?.split("")
			setNameArr(tempName)
		}
	}, [data])

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
				}}
				scrollBehavior="outside"
				size="8xl"
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pt="2">
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody pl={0} pr={0} pb={0}>
						<Center pb="4">
							<Box>
								<Flex>
									{nameArr?.map((_, index) => <Flex m="1px" fontSize="28px" justifyContent="center" alignItems="center" border="1px solid black" p="0" w="50px !important" maxW="50px" maxH="50px" h="50px" key={index}>{index + 1}</Flex>)}
								</Flex>
								<Flex>
									{nameArr?.map((item, index) => <Flex fontFamily="NotoSansKhmer" m="1px" fontSize="28px" justifyContent="center" alignItems="center" border="1px solid black" p="0" w="50px !important" maxW="50px" maxH="50px" h="50px" key={index}>{item}</Flex>)}
								</Flex>
							</Box>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default KhmerNameModal;
