import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Table,
    Button,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td, Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingTable from "../../../components/Table/Loading";
import moment from "moment"
import useStore from "../../../store";
import { useLocation, useHistory } from "react-router-dom";
import c from "../../../constant"
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import LogsViewer from "./components/LogsViewer"

const QUERY_DATA = gql`
    query get($limit: Float
		$offset: Float){
        getLogsFiles(
            pagerInput: { limit: $limit, offset: $offset }
        ){
        logsFiles{
            createdAt
            file
            id
        }
        totalRows
        totalPages
        }
    }
`


const MUTATION_SYNC = gql`
    mutation{
        syncLogFile
    }
`

function ProductList() {
    const { t } = useTranslation();
    const { currentLang } = useStore((state) => state.currentLang);
    const history = useHistory();
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);

    const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [logsFile, setLogsFile] = useState(null)

    const [syncLog, { loading: loadingSync, data: dataSync }] = useMutation(MUTATION_SYNC)
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    useEffect(() => {
        if (dataSync?.syncLogFile) {
            loadData({
                variables: {
                    offset: currentPage ? (currentPage - 1) * 10 : 0,
                    limit: 10
                }
            })
        }
    }, [dataSync])

    useEffect(() => {
        loadData({
            variables: {
                offset: currentPage ? (currentPage - 1) * 10 : 0,
                limit: 10
            }
        })
        window.scrollTo(0, 0);
    }, [currentPage]);

    function handlePageClick(data) {
        let selected = data.selected;
        const page = selected + 1;
        let url = `?page=${page}`;
        history.push(`/logs-files${url}`);
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Settings",
                            path: "/settings",
                        },
                        {
                            name: "Logs Files",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5" >

                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        p="2"
                        pb="32px"
                    >
                        <Flex alignItems="center" justifyContent="space-between" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" borderBottom="1px solid #dbdbdb">
                            <Flex justifyContent="center" alignItems="center">
                                <Text
                                    fontSize="x-large"
                                    fontWeight="bold"
                                    mr="4"
                                >
                                    {t("Logs Files")}
                                </Text>
                                <Button isLoading={loadingSync} onClick={() => syncLog()} size="xs" colorScheme="blue">
                                    Sync
                                </Button>
                            </Flex>
                        </Flex>
                        <Table variant="simple" size="sm">
                            <Tbody>
                                <Tr className="td-as-th">
                                    <Th>{t("ID")}</Th>
                                    <Th>{t("Created At")}</Th>
                                    <Th>{t("Actions")}</Th>
                                </Tr>
                                {loading && <LoadingTable column={3} />}
                                {
                                    data?.getLogsFiles?.logsFiles?.length <= 0 && <Tr>
                                        <Td isNumeric><Text>{t("No Data")}</Text></Td>
                                    </Tr>
                                }
                                {data?.getLogsFiles?.logsFiles?.map((item, index) => {
                                    return (
                                        <Tr
                                            cursor="pointer" _hover={{ bg: "gray.200" }} key={`item-${index}`} >
                                            <Td
                                                onClick={() => {
                                                    onOpen()
                                                    setLogsFile(item?.file)
                                                }}
                                            >{item?.id}</Td>
                                            <Td
                                                onClick={() => {
                                                    onOpen()
                                                    setLogsFile(item?.file)
                                                }}
                                            >{moment(item?.createdAt).format("LLLL")}</Td>
                                            <Td>
                                                <a href={item?.file} target="_blank">
                                                    <Button colorScheme='teal' size='xs'>
                                                        Download
                                                    </Button>
                                                </a>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                            <Tfoot className="hidden-print">
                                <Tr>
                                    <Th>{t("ID")}</Th>
                                    <Th>{t("Created At")}</Th>
                                    <Th>{t("Actions")}</Th>
                                </Tr>
                                <Tr>
                                    <Th></Th>
                                    <Th></Th>
                                    <Th textAlign="right">{t("Total:")}{data?.getLogsFiles?.totalRows}</Th>
                                </Tr>
                            </Tfoot>
                        </Table>

                        <Flex className="hidden-print" justifyContent="flex-end" w="100%" mt="4">
                            {data?.getLogsFiles?.totalPages > 1 && (
                                <ReactPaginate
                                    previousLabel={<ChevronLeftIcon fontSize="20px" />}
                                    nextLabel={<ChevronRightIcon fontSize="20px" />}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    disableInitialCallback={true}
                                    pageCount={data?.getLogsFiles?.totalPages}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    initialPage={(currentPage - 1) | 0}
                                />
                            )}
                        </Flex>

                        <Modal size="full" isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent borderRadius={0} >
                                <ModalHeader>Logs</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <LogsViewer logsFile={logsFile} />
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default ProductList;

