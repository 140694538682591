/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Badge,
	Image,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	InputGroup,
	Input,
	InputRightElement,
	Button,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Textarea,
	IconButton,
	useClipboard,
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { faEllipsisV, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaCheck, FaCopy } from 'react-icons/fa';
import useStore from '../../store';
import LogoPlaceholder from '../../assets/images/logo-placeholder.png';
import LoadingResponsive from '../../components/Table/LoadingResponsive';
import CompanyExportModal from '../../components/Modal/CompanyExportModal';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';

const limit = 10;
const QUERY_REPORTS = gql`
  query getCompanies(
        $offset:Float,
        $companyName: String
        $companyType: InsuranceInstitutionsType
    ){
        getCompanies(
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
        companyName: $companyName
        companyType: $companyType
    ){
      
        companies{
            id
            name
            nameKhmer
            type
            active
            logo
            type
          }
          totalPages
          totalRows
    }
  }
`;

const UPDATE_COMPANY_STATUS_MUTATION = gql`
	mutation updateCompanyStatusById($id: String!, $active: Boolean!) {
		updateCompanyStatusById(id: $id, active: $active)
	}
`;

const M_GET_COMPANY_SIGNIN = gql`
	mutation submit($companyId: String!) {
		getCompanySignInByCompanyId(companyId: $companyId) {
			accessToken
			expAt
			loginType
			email
		}
	}
`;

function Report() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [companyName, setCompanyName] = useState('');
	const companyType = urlParams.get('companyType') ? urlParams.get('companyType') : null;
	const [openExportModal, setOpenExportModal] = useState(false);
	const [updateCompanyStatusMutation, { error: errorUpdate, data: dataUpdate }] = useMutation(UPDATE_COMPANY_STATUS_MUTATION);
	const [loadCompanies, { loading, data }] = useLazyQuery(QUERY_REPORTS);
	const [createCompanyCredential, { data: credentialData }] = useMutation(M_GET_COMPANY_SIGNIN);

	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	useEffect(() => {
		window.scrollTo(0, 0);
		loadCompanyWithVar();
	}, []);

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			loadCompanyWithVar();
		}
	}, [currentPage, companyType]);

	useEffect(() => {
		if (dataUpdate?.updateCompanyStatusById) {
			loadCompanyWithVar();
			toast({
				title: t('Company updated successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [dataUpdate]);

	useEffect(() => {
		if (errorUpdate) {
			toast({
				title: t('Company update error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorUpdate]);

	function getCompanyType(type) {
		return type === 'ASSOCIATION' ? (
			<Badge colorScheme="messenger">{t('ASSOCIATION')}</Badge>
		) : type === 'GENERAL_INSURANCE' ? (
			<Badge colorScheme="orange">{t('GENERAL INSURANCE')}</Badge>
		) : type === 'LIFE_INSURANCE' ? (
			<Badge colorScheme="green">{t('LIFE INSURANCE')}</Badge>
		) : type === 'MICRO_INSURANCE' ? (
			<Badge colorScheme="yellow">{t('MICRO INSURANCE')}</Badge>
		) : type === 'REINSURANCE' ? (
			<Badge colorScheme="teal">{t('REINSURANCE')}</Badge>
		) : type === 'INSURANCE_BROKER' ? (
			<Badge colorScheme="purple">{t('INSURANCE BROKER')}</Badge>
		) : type === 'INSURANCE_AGENT' ? (
			<Badge colorScheme="gray">{t('Insurance Agent Co Ltd')}</Badge>
		) : type === 'INSURANCE_LOSS_ADJUSTER' ? (
			<Badge colorScheme="red">{t('Insurance Loss Adjuster Co Ltd')}</Badge>
		) : type === 'AUDIT_SERVICE' ? (
			<Badge colorScheme="cyan">{t('INSURANCE AUDIT SERVICE')}</Badge>
		) : type === 'INSURANCE_CLAIM_MANAGEMENT' ? (
			<Badge colorScheme="blue">{t('INSURANCE CLAIM MANAGEMENT')}</Badge>
		) : type === 'ACTUARIAL_FIRM' ? (
			<Badge colorScheme="teal">{t('INSURANCE ACTUARIAL FIRM')}</Badge>
		) : null;
	}

	function actionMenu({ id, active }) {
		return (
			<Menu>
				<MenuButton cursor="pointer">
					<Flex pl="2" pr="2">
						<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
					</Flex>
				</MenuButton>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					{currentUser?.role === 'admin' && (
						<MenuItem
							onClick={() => {
								createCompanyCredential({ variables: { companyId: id } })
									.then(({ data }) => {
										if (data?.getCompanySignInByCompanyId) {
											onOpen();
										} else {
											toast({
												title: t('something went wrong'),
												status: 'error',
												isClosable: true,
												position: 'bottom-end',
											});
										}
									})
									.catch((error) => {
										toast({
											title: t(error?.message ? error?.message : 'something went wrong'),
											status: 'error',
											isClosable: true,
											position: 'bottom-end',
										});
									});
							}}
						>
							<Text fontWeight="bold" color="blue.500">
								{t('Get Token For Website')}
							</Text>
						</MenuItem>
					)}
					<MenuItem color={active ? 'red' : 'green'} onClick={() => updateCompanyStatus({ id, active })}>
						{active ? t('Deactivate') : t('Activate')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}

	const CredentialModal = ({ isOpen = false, clipboard = '' }) => {
		const { onCopy, hasCopied } = useClipboard(clipboard);

		return (
			<Modal size="xl" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader />
					<ModalCloseButton />
					<ModalBody>
						<Textarea value={clipboard} height={44} mt={4} mb={2} isReadOnly />
						<Flex justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
							<IconButton size="sm" colorScheme="gray" icon={hasCopied ? <FaCheck color="green" /> : <FaCopy />} onMouseDown={onCopy} />
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	};

	function updateCompanyStatus({ id, active }) {
		updateCompanyStatusMutation({
			variables: {
				id,
				active: !active,
			},
		});
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handlePageClick({ selected: 0 });
			loadCompanyWithVar();
		}
	};

	function handleSearch() {
		handlePageClick({ selected: 0 });
		loadCompanyWithVar();
	}

	function loadCompanyWithVar() {
		loadCompanies({
			variables: {
				offset: currentPage ? (currentPage - 1) * limit : 0,
				companyName: companyName ? companyName : null,
				companyType: companyType ? (companyType === 'All' ? null : companyType) : null,
			},
		});
	}

	function panelTabs({ name, value }) {
		let active = companyType ? companyType : 'All';
		return (
			<Flex
				userSelect="none"
				onClick={() => handleCompanyTypeChange(value)}
				cursor="pointer"
				mb={'-2px'}
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text noOfLines={1} display="inline">
					{t(name)}
				</Text>
			</Flex>
		);
	}

	function handleCompanyTypeChange(companyTypeValue) {
		const page = 1;
		let url = `?page=${page}`;
		if (companyTypeValue) {
			url += `&companyType=${companyTypeValue}`;
		}
		history.push(`/company${url}`);
	}

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;
		let url = `?page=${page}`;
		if (companyType) {
			url += `&companyType=${companyType}`;
		}
		history.push(`/company${url}`);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Insurance Institution',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Box
							bg="#FAFAFA"
							p="16px"
							pt="0"
							pb="0"
							mb="16px"
							alignItems="center"
							justifyContent="space-between"
							borderBottom="1px solid #dbdbdb"
							display={{ xl: 'flex' }}
						>
							<Box display={{ md: 'flex' }}>
								{insuranceCompanyType.map((item) => {
									return panelTabs({ name: item.label, value: item.value });
								})}
							</Box>
							<Flex bg="#FAFAFA" ml={{ base: 0, xl: 4 }} mt={{ base: 4, xl: 0 }} mb={{ base: 4, xl: 0 }}>
								<InputGroup mr={2} size="sm">
									<Input
										onKeyDown={handleKeyDown}
										pr="4.5rem"
										placeholder={t('Name')}
										value={companyName}
										onChange={(e) => setCompanyName(e.target.value)}
									/>
									<InputRightElement zIndex={0} width="4.5rem">
										<Button h="1.75rem" size="sm" onClick={handleSearch}>
											{t('Search')}
										</Button>
									</InputRightElement>
								</InputGroup>
								<Button
									mr="2"
									onClick={() => setOpenExportModal(true)}
									leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
									colorScheme="gray"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
									pl={6}
									pr={6}
								>
									{t('Export')}
								</Button>
								{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
									<Link to={`/company/create`}>
										<Button
											leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Add')}
										</Button>
									</Link>
								)}
							</Flex>
						</Box>
						<Box pt={0} pr={4} pb={4} pl={4}>
							<Table variant="simple" className="table-company-proposal-list responsive-table-container">
								<Thead>
									<Tr className="td-as-th">
										<Th align="left">{t('Logo')}</Th>
										<Th align="left">{t('Name In Latin')}</Th>
										<Th align="left">{t('Name In Khmer')}</Th>
										<Th align="left">{t('Type')}</Th>
										<Th align="right">{t('Status')}</Th>
										{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && <Th align="right">{t('Actions')}</Th>}
									</Tr>
								</Thead>
								<Tbody>
									{loading && <LoadingResponsive column={currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing ? 6 : 5} />}
									{data?.getCompanies?.companies.length <= 0 && (
										<Tr>
											<Td>
												<Text>{t('No Data')}</Text>
											</Td>
										</Tr>
									)}
									{data?.getCompanies?.companies?.map((item, index) => {
										return (
											<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
												<Td align="left" onClick={() => history.push(`/company/detail?id=${item.id}`)} pt="0" pb="0">
													<Image
														src={item.logo ? c.baseFilePath + item.logo : ''}
														w="auto"
														minW="40px"
														minH="40px"
														h="40px"
														fallbackSrc={LogoPlaceholder}
													/>
												</Td>
												<Td align="left" onClick={() => history.push(`/company/detail?id=${item.id}`)}>
													{item.name}
												</Td>
												<Td align="left" onClick={() => history.push(`/company/detail?id=${item.id}`)}>
													{item.nameKhmer}
												</Td>
												<Td align="left" onClick={() => history.push(`/company/detail?id=${item.id}`)}>
													{getCompanyType(item.type)}
												</Td>
												<Td align="right" onClick={() => history.push(`/company/detail?id=${item.id}`)}>
													{item.active ? (
														<Badge colorScheme="green">{t('ACTIVE')}</Badge>
													) : (
														<Badge colorScheme="red">{t('INACTIVE')}</Badge>
													)}
												</Td>
												{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
													<Td align="right">{actionMenu({ id: item.id, active: item.active })}</Td>
												)}
											</Tr>
										);
									})}
								</Tbody>
							</Table>
							<Flex justifyContent="flex-end" alignItems="center" pr={{ base: 0, md: 6 }}>
								<Text fontWeight="bold" mb={6} mt={4}>
									{t('Total Rows')}: {data?.getCompanies?.totalRows}
								</Text>
							</Flex>
							<Flex justifyContent="flex-end" w="100%" mt="4">
								{data?.getCompanies?.totalPages > 1 && (
									<ReactPaginate
										previousLabel={<ChevronLeftIcon fontSize="20px" />}
										nextLabel={<ChevronRightIcon fontSize="20px" />}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={data?.getCompanies?.totalPages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={'pagination'}
										activeClassName={'active'}
										initialPage={(currentPage - 1) | 0}
									/>
								)}
							</Flex>
						</Box>
					</Box>
				</Center>
			</Container>
			<CredentialModal isOpen={isOpen} clipboard={JSON.stringify(credentialData?.getCompanySignInByCompanyId)} />
			{openExportModal && <CompanyExportModal isOpen={true} onClose={() => setOpenExportModal(false)} companyType={companyType} />}
		</>
	);
}

export default Report;

const insuranceCompanyType = [
	{ label: 'ALL', value: 'All' },
	{ label: 'GENERAL', value: 'GENERAL_INSURANCE' },
	{ label: 'LIFE', value: 'LIFE_INSURANCE' },
	{ label: 'MICRO', value: 'MICRO_INSURANCE' },
	{ label: 'RE', value: 'REINSURANCE' },
	{ label: 'BROKER', value: 'INSURANCE_BROKER' },
	{ label: 'AGENT', value: 'INSURANCE_AGENT' },
	{ label: 'ASSOCIATION', value: 'ASSOCIATION' },
	{ label: 'LOSS ADJUSTER', value: 'INSURANCE_LOSS_ADJUSTER' },
	{ label: 'AUDIT', value: 'AUDIT_SERVICE' },
	{ label: 'CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
	{ label: 'ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
];
