/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect } from 'react';

const Q_DETAIL = gql`
	query get($id: String!) {
		getPartnerDetailSaleProfessionalById(id: $id) {
			id
			saleProfessional {
				id
				idCardNumber
				lastName
				firstName
			}
		}
	}
`;

export default function EventLoggingIntermediarySaleStaff({ id = null }) {
	const [fetchData, { data }] = useLazyQuery(Q_DETAIL);

	useEffect(() => {
		id && fetchData({ variables: { id } });
	}, [id]);

	return (
		<>
			{data?.getPartnerDetailSaleProfessionalById?.id && (
				<Tooltip
					label={
						<Box>
							{data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.idCardNumber && (
								<Text color="white" fontWeight="semibold">
									{data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.idCardNumber}
								</Text>
							)}
							{data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.lastName &&
								data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.firstName && (
									<Text color="white" fontWeight="normal">
										{data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.lastName +
											' ' +
											data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.firstName}
									</Text>
								)}
						</Box>
					}
				>
					<Text cursor="pointer" fontSize="sm" fontWeight="semibold" color="gray.500">
						{data?.getPartnerDetailSaleProfessionalById?.saleProfessional?.idCardNumber}
					</Text>
				</Tooltip>
			)}
		</>
	);
}
