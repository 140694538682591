import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Box, Flex, Text, SimpleGrid, Divider } from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faNewspaper, faBriefcase, faFileInvoice, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import c from "../../constant"
function Website() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function menuItem({item,index}) {
        return (
            <Link key={`menu-${index}`} to={item.path}>
            <Flex
                h="64px"
                alignItems="center"
                bg="white"
                borderRadius={c.borderRadius}
                border="1px solid #dbdbdb"
                boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                p="16px"
                cursor="pointer"
                // mb="4"
            >
                <FontAwesomeIcon
                    icon={item?.icon?item?.icon: faNewspaper}
                    color="#303030"
                    p="2"
                    style={{ fontSize: 20 }}
                />
                <Text fontWeight="500" ml="4">{t(item.title)}</Text>
            </Flex>
            </Link>
        );
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "WEBSITE",
                            path: "#",
                        },
                    ]}
                />
                <Box pb="32px" w="100%" mt="5">
                    <SimpleGrid columns={[2, null, 3]} spacing='8px'>
                        {menu.map((item,index)=>menuItem({item,index}))}
                    </SimpleGrid>
                </Box>
                <Divider/>
                <Box pb="32px" w="100%" mt="5">
                    <SimpleGrid columns={[2, null, 3]} spacing='8px'>
                        {menu2.map((item,index)=>menuItem({item,index,}))}
                    </SimpleGrid>
                </Box>
                <Box pb="32px" w="100%" mt="5">
                    <SimpleGrid columns={[2, null, 3]} spacing='8px'>
                        {sandboxMenu.map((item,index)=>menuItem({item,index}))}
                    </SimpleGrid>
                </Box>
                <Divider/>
            </Container>
        </>
    );
}

export default Website;


const menu=[
    {
        title: "News",
        path:"/website/articles?category=news&page=1"
    },
    {
        title: "Announcement",
        path:"/website/articles?category=announcement&page=1"
    },
    {
        title: "Knowledge sharing",
        path:"/website/articles?category=knowledge-sharing&page=1"
    },
    {
        title: "Educational broadcasting",
        path:"/website/articles?category=educational-broadcasting&page=1"
    },
    {
        title: "Social programs",
        path:"/website/articles?category=social-programs&page=1"
    },
    {
        title: "Statistics - Monthly",
        path:"/website/articles?category=statistics-monthly&page=1"
    },
    {
        title: "Statistics - Quarterly",
        path:"/website/articles?category=statistics-quarterly&page=1"
    },
    {
        title: "Statistics - Annually",
        path:"/website/articles?category=statistics-annually&page=1"
    },
    {
        title: "Laws",
        path:"/website/articles?category=laws&page=1"
    },
    {
        title: "Sub decrees",
        path:"/website/articles?category=sub-decrees&page=1"
    },
    {
        title: "Prakas",
        path:"/website/articles?category=prakas&page=1"
    },
    {
        title: "Guidance",
        path:"/website/articles?category=guidance&page=1"
    },
    {
        title: "Other",
        path:"/website/articles?category=other&page=1"
    },
    {
        title: "Hidden",
        path:"/website/articles?category=hidden&page=1"
    },
    {
        title: "Publication",
        path:"/website/articles?category=publication&page=1"
    },
    {
        title: "Public Service",
        path:"/website/articles?category=public-service&page=1"
    }
]


const sandboxMenu=[
    {
        title: "Inprogress Sandbox",
        path:"/website/articles?category=inprogress-insurance-regulatory-sandbox&page=1"
    },
    {
        title: "Successed Sandbox",
        path:"/website/articles?category=successed-insurance-regulatory-sandbox&page=1"
    },
    {
        title: "Sandbox Info",
        path:"/website/articles?category=sandbox-info&page=1"
    }
]

const menu2=[
    {
        title: "Public Service List",
        path:"/website/public-service-list",
        icon: faBriefcase
    },
    {
        title: "Company Report Type List",
        path:"/website/company-report-type-list",
        icon: faChartPie
    },
    {
        title: "Public Form",
        path:"/website/public-form-list",
        icon: faFileInvoice
    },
    {
        title: "Admin Announcement",
        path:"/website/articles?category=admin-announcement&page=1"
    }
]