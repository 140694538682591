/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext } from 'react';

const Q_GET_REQUEST = gql`
	query get {
		getCountAgentTerminationRequest {
			countPrimaryReview
			countSecondaryReview
			countCompleted
			countAll
		}
	}
`;

const Q_COUNT_TERMINATION_PARTNER = gql`
	query {
		getCountTerminationPartnerRequest
	}
`;

const Q_COUNT_TERMINATION_BRANCH_MANAGER = gql`
	query {
		getCountTerminationBranchManagerRequest
	}
`;

const TerminationRequestCountContext = createContext();

export const TerminationRequestCountProvider = ({ children }) => {
	const [load, { data }] = useLazyQuery(Q_GET_REQUEST);
	const [fetchCountTerminationPartner, { data: dataCountTerminationPartner }] = useLazyQuery(Q_COUNT_TERMINATION_PARTNER);
	const [fetchCountTerminationBranchManager, { data: dataCount }] = useLazyQuery(Q_COUNT_TERMINATION_BRANCH_MANAGER);

	const loadCountTerminationRequest = () => {
		load();
	};

	const loadCountTerminationPartner = () => {
		fetchCountTerminationPartner();
	};

	const loadCountTerminationBranchManager = () => {
		fetchCountTerminationBranchManager();
	};

	return (
		<TerminationRequestCountContext.Provider
			value={{
				loadCountTerminationRequest,
				countPrimaryReview: parseInt(data?.getCountAgentTerminationRequest?.countPrimaryReview),
				countSecondaryReview: parseInt(data?.getCountAgentTerminationRequest?.countSecondaryReview),
				countCompleted: parseInt(data?.getCountAgentTerminationRequest?.countCompleted),
				countAll: parseInt(data?.getCountAgentTerminationRequest?.countAll),
				loadCountTerminationPartner,
				countTerminationPartnerOfficeReview: parseInt(dataCountTerminationPartner?.getCountTerminationPartnerRequest?.countOffice),
				countTerminationPartnerDepartmentReview: parseInt(dataCountTerminationPartner?.getCountTerminationPartnerRequest?.countDepartment),
				loadCountTerminationBranchManager,
				countTerminationBranchManagerOfficeReview: parseInt(dataCount?.getCountTerminationBranchManagerRequest?.countOffice),
			}}
		>
			{children}
		</TerminationRequestCountContext.Provider>
	);
};

export const useTerminationRequestCount = () => {
	return useContext(TerminationRequestCountContext);
};
