/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { gql, useLazyQuery } from '@apollo/client';
import TextFormField from '../form/TextFormField';
import RadioFormField from '../form/RadioFormField';
import SelectSearchFormField from '../form/SelectSearchFormField';
import useStore from '../../store';
import c from '../../constant';
import * as Yup from 'yup';

const Q_GET_BRANCHES = gql`
	query get($id: String!, $offset: Float, $limit: Float) {
		getCompanyCertificate(companyId: $id, pagerInput: { offset: $offset, limit: $limit }) {
			totalRows
			totalPages
			branches {
				id
				number
				name
				nameLatin
				managerName
				managerNameLatin
				validFrom
				validTo
				file
				streetNumber
				houseNumber
				geoManual
				isGeoManual
				provinceObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				districtObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				communeObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				villageObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
			}
		}
	}
`;

const validationSchema = Yup.object().shape({
	name: Yup.string().nullable(),
	branch: Yup.object().nullable(),
	type: Yup.string().nullable(),
});

function CompanyDetailAgentFilter({ onApply = () => null, onClose = () => null, isOpen = false, id = null, name = null, branch = null, type = null }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [getBranches, { data }] = useLazyQuery(Q_GET_BRANCHES);

	useEffect(() => {
		if (id) {
			const variables = { id, offset: 0, limit: 999 };
			getBranches({ variables });
		}
	}, [id]);

	function manipulateBranches() {
		let branches = [];
		if (data) {
			branches = data?.getCompanyCertificate?.branches.map((branch) => {
				return { label: lang === 'kh' ? branch?.name || branch?.nameLatin || 'N/A' : branch?.nameLatin || branch?.name || 'N/A', value: branch?.id };
			});
		}
		return branches;
	}

	return (
		<>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								name,
								branch,
								type,
							}}
							onSubmit={(values) => {
								onApply({
									name: values?.name,
									branch: values?.branch,
									type: values?.type,
								});
								onClose();
							}}
						>
							{(props) => (
								<Box pb="4">
									<Form>
										<TextFormField {...props} label="Name" name="name" />
										<SelectSearchFormField
											{...props}
											name="branch"
											label="In Charge Branch"
											placeholder="Select a branch"
											options={manipulateBranches()}
											callback={(e) => null}
										/>
										<RadioFormField
											name="type"
											label="type"
											radios={[
												{ label: 'Direct Agent', value: 'DIRECT_AGENT' },
												{ label: 'Bancassurance', value: 'PROFESSIONAL_SALE' },
												{ label: 'Professional Individual based on Licensing', value: 'PROFESSIONAL' },
											]}
											initValue={type}
										/>
										<Flex mt={8} justifyContent="flex-end">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												color="#3498db"
											>
												{t('Apply')}
											</Button>
											<Button
												size="md"
												h="42px"
												ml={4}
												w="128px"
												onClick={() => {
													onApply({
														name: null,
														branch: null,
														type: null,
													});
													onClose();
												}}
												variant="ghost"
											>
												{t('Clear')}
											</Button>
										</Flex>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default CompanyDetailAgentFilter;
