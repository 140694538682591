/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@chakra-ui/react';
import CompanyName from '../../CompanyName';

function DistributionSystemCertificateSalePartner({ partner = null, ...props }) {
	return (
		<Box>
			<Box mt={1}>
				<CompanyName company={partner} isExtraSmall />
			</Box>
		</Box>
	);
}

export default DistributionSystemCertificateSalePartner;
