import React from 'react';
import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function PartnerTypeBadge({ type }) {
	const { t } = useTranslation();

	switch (type) {
		case 'general':
			return <Badge colorScheme="orange">{t('GENERAL INSURANCE')}</Badge>;
		case 'life':
			return <Badge colorScheme="green">{t('LIFE INSURANCE')}</Badge>;
		case 'micro':
			return <Badge colorScheme="yellow">{t('MICRO INSURANCE')}</Badge>;
		default:
			return null;
	}
}

export default PartnerTypeBadge;
