import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box } from '@chakra-ui/react';
import c from '../../constant';
import './style.css';
import { useTranslation } from 'react-i18next';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import 'moment/locale/en-gb';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ExcelExportModal({ isOpen = false, onClose = () => null, data = null, title = '' }) {
	moment.locale('en-gb');

	const { t } = useTranslation();
	const [colData, setColData] = useState([]);

	function convertCamelCaseToCaseText(text) {
		const result = text?.replace(/([A-Z])/g, ' $1');
		return result?.charAt(0).toUpperCase() + result?.slice(1);
	}

	useEffect(() => {
		if (data?.length > 0) {
			const row = data[0];
			const rowData = [];
			for (var k in row) {
				if (row.hasOwnProperty(k)) {
					rowData.push({ label: convertCamelCaseToCaseText(k), value: k });
				}
			}
			setColData(rowData);
		}
	}, [data]);
	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
			}}
			size="md"
		>
			<ModalOverlay />
			<ModalContent pb="4" borderRadius={c.borderRadius}>
				<ModalHeader>{t('Export To Excel')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box w="100%" pt="16px" pb="16px">
						{data.length > 0 && (
							<ExcelFile
								filename={`${title} - Generated At ${moment().format('DD MM YYYY hh mm ss')}`}
								element={
									<Button variant="solid" size="md" h="42px" w="128px" type="submit" borderRadius={c.borderRadius} color="#3498db">
										{t('Download')}
									</Button>
								}
							>
								<ExcelSheet data={data} name={`Table`}>
									{colData?.map((item, index) => (
										<ExcelColumn key={`k-${index}`} label={item?.label} value={item?.value} />
									))}
								</ExcelSheet>
							</ExcelFile>
						)}
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default ExcelExportModal;
