/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTerminationRequestCount } from '../../../contexts/termination-request-count';
import useStore from '../../../store';
import c from '../../../constant';

function IntermediaryTerminationPartnerBadgeCount() {
	const { currentUser: user } = useStore((state) => state.currentUser);
	const {
		loadCountTerminationPartner: load,
		countTerminationPartnerOfficeReview: countOffice,
		countTerminationPartnerDepartmentReview: countDepartment,
	} = useTerminationRequestCount();

	useEffect(load, []);

	return (
		<>
			{c.stepOfficeRoles.includes(user?.position) && parseInt(countOffice) > 0 && (
				<>
					<Badge colorScheme="red" variant="solid" ml={1}>
						{parseInt(countOffice)}
					</Badge>
				</>
			)}
			{c.stepDepartmentRoles.includes(user?.position) && parseInt(countDepartment) > 0 && (
				<>
					<Badge colorScheme="red" variant="solid" ml={1}>
						{parseInt(countDepartment)}
					</Badge>
				</>
			)}
		</>
	);
}

export default IntermediaryTerminationPartnerBadgeCount;
