/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Flex, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { AgentBadge, SectionHeader } from '../../Other';
import { useTranslation } from 'react-i18next';
import c from '../../../constant';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';

function DistributionSystemAgentListPrint({ list = [], ...props }) {
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang: lang } = useStore((state) => state.currentLang);

	function getCode(input) {
		return input?.code;
	}

	function getName(input) {
		return lang === 'kh' ? input?.name : input?.latinName;
	}

	function getDate(input) {
		return toDateString(input, c.dateOnlyFormat);
	}

	function getValid(from, to) {
		return `${getDate(from)} - ${getDate(to)}`;
	}

	return (
		<>
			<Flex {...props}>
				<Box className="show-only-printing" width="100%">
					<Center>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex
								p={4}
								pt={2}
								pb={2}
								mb={4}
								h="48px"
								bg="#FAFAFA"
								alignItems="center"
								justifyContent="flex-start"
								borderBottom="1px solid #dbdbdb"
							>
								<SectionHeader title={t('Agent')} fontSize="lg" />
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Registration Number')}</Th>
											<Th>{t('Name')}</Th>
											<Th>{t('DOB')}</Th>
											<Th>{t('Start Working Date')}</Th>
											<Th>{t('Validity')}</Th>
											<Th isNumeric>{t('Type of Agent')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{list?.map((item, index) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
													<Td>{getCode(item)}</Td>
													<Td>{getName(item)}</Td>
													<Td>{getDate(item?.dob)}</Td>
													<Td>{getDate(item?.agentCompany?.startDate)}</Td>
													<Td>{getValid(item?.validFrom, item?.expireAt)}</Td>
													<Td isNumeric>
														<AgentBadge type={item?.agentCompany.insuranceAgentCompanyType} />
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Registration Number')}</Th>
											<Th>{t('Name')}</Th>
											<Th>{t('DOB')}</Th>
											<Th>{t('Start Working Date')}</Th>
											<Th>{t('Validity')}</Th>
											<Th isNumeric>{t('Type of Agent')}</Th>
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
		</>
	);
}

export default DistributionSystemAgentListPrint;
