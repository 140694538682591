/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import EventLoggingName from './EventLoggingName';
import EventLoggingCandidatePartnerRequest from './EventLoggingCandidatePartnerRequest';
import EventLoggingCandidateTerminationRequest from './EventLoggingCandidateTerminationRequest';
import EventLoggingCandidateBranchChangeRequest from './EventLoggingCandidateBranchChangeRequest';
import EventLoggingSaleProfessional from './EventLoggingSaleProfessional';
import EventLoggingTerminatedSaleProfessional from './EventLoggingTerminatedSaleProfessional';
import EventLoggingProfessionalIndividual from './EventLoggingProfessionalIndividual';
import EventLoggingPartnerDetailSaleStaff from './EventLoggingPartnerDetailSaleStaff';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';
import useStore from '../../../store';

const categoryMapping = {
	1: { titleEn: 'Agent', titleKh: 'ភ្នាក់ងារ' },
	2: { titleEn: 'Sale Staff', titleKh: 'បុគ្គលិកលក់' },
	3: { titleEn: 'Intermediary', titleKh: 'ក្រុមហ៊ុនអន្តរការី' },
};

const subCategoryMapping = {
	1: { titleEn: 'Natural Person', titleKh: 'រូបវន្តបុគ្គល' },
	2: { titleEn: 'Legal Person', titleKh: 'និតិបុគ្គល' },
};

const eventTypeMapping = {
	1: { titleEn: 'Partnering', titleKh: 'ការចាប់ដៃគូរ' },
	2: { titleEn: 'Termination', titleKh: 'ការបញ្ឈប់' },
	3: { titleEn: 'Branch Change', titleKh: 'ការផ្លាស់ប្តូរសាខា' },
	4: { titleEn: 'Nominate Professional Individual', titleKh: 'តែងតាំងបុគ្គលវិជ្ជាជីវៈលក់' },
	5: { titleEn: 'Nominate Sale Staff', titleKh: 'តែងតាំងបុគ្គលិកលក់' },
};

const eventActionMapping = {
	1: { titleEn: 'Start', titleKh: 'ចាប់ផ្តើម', color: 'gray.600' },
	2: { titleEn: 'Approved', titleKh: 'បានអនុញ្ញាត', color: 'green.400' },
	3: { titleEn: 'Rejected', titleKh: 'បានបដិសេដ', color: 'red.400' },
	4: { titleEn: 'Reset Status', titleKh: 'បានលុបស្ថានភាព', color: 'orange.400' },
	5: { titleEn: 'Submitted Review', titleKh: 'បានបញ្ជូនការពិនិត្យ', color: 'green.400' },
	6: { titleEn: 'Approved Contract', titleKh: 'បានអនុញ្ញាតកុងត្រា', color: 'green.400' },
	7: { titleEn: 'Rejected Contract', titleKh: 'បានបដិសេដកុងត្រា', color: 'red.400' },
	8: { titleEn: 'Reset Contract Status', titleKh: 'បានលុបស្ថានភាពកុងត្រា', color: 'orange.400' },
	9: { titleEn: 'Submitted Contract Review', titleKh: 'បានបញ្ជូនការពិនិត្យកុងត្រា', color: 'green.400' },
	10: { titleEn: 'Completed', titleKh: 'បានបញ្ចប់ស្ថាពរ', color: 'gray.600' },
	11: { titleEn: 'Expired', titleKh: 'បានផុតកំណត់', color: 'gray.600' },
};

const Q_EVENT_LOGS = gql`
	query get($pagerInput: PagerInput!, $typeObjectId: String!, $actionObjectId: String) {
		getEventLogs(pagerInput: $pagerInput, typeObjectId: $typeObjectId, actionObjectId: $actionObjectId) {
			list {
				id
				date
				userId
				category
				subCategory
				type
				typeObjectId
				action
				actionObjectId
				actionValue
				actionDescription
			}
			totalPages
			totalRows
		}
	}
`;

function EventLoggingModal({ typeObjectId = null, actionObjectId = null, isOpen = false, onClose = () => null }) {
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang: lang } = useStore((state) => state?.currentLang);
	const [getEventLogs, { data, loading }] = useLazyQuery(Q_EVENT_LOGS);

	useEffect(() => {
		if (typeObjectId) {
			const variables = { typeObjectId, actionObjectId, pagerInput: { limit: 1000, offset: 0 } };
			getEventLogs({ variables });
		}
	}, [typeObjectId, actionObjectId]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="sm">
				<ModalOverlay />
				<ModalContent pb={6}>
					<ModalHeader>{t('Logs')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<>
							{loading && (
								<>
									<Text fontSize="sm" fontWeight="semibold" color="gray.600" mr={1}>
										{t('Loading')}
									</Text>
								</>
							)}
						</>
						<>
							{!loading && data?.getEventLogs?.list?.length === 0 && (
								<>
									<Text fontSize="sm" fontWeight="semibold" color="gray.600" mr={1}>
										{t('No Data')}
									</Text>
								</>
							)}
						</>
						<>
							{!loading && data?.getEventLogs?.list?.length !== 0 && (
								<>
									{data?.getEventLogs?.list?.map((e, i) => {
										if (['1', '2', '3'].includes(e?.category)) {
											if (['1', '2'].includes(e?.subCategory)) {
												if (['1', '2', '3', '4', '5'].includes(e?.type)) {
													return (
														<Flex
															key={`log-item-${i}`}
															w="100%"
															bg="white"
															direction="column"
															border="1px dotted #dbdbdb"
															borderRadius={c.borderRadius}
															pl={4}
															pr={4}
															pt={2}
															pb={2}
															mt={2}
														>
															{['2', '3', '4', '5', '6', '7', '8', '9'].includes(e?.action) && (
																<>
																	<>
																		<Flex direction="column">
																			<Flex alignItems="center">
																				<Text fontSize="sm" fontWeight="semibold" color="gray.600" mr={1}>
																					{i + 1}
																				</Text>
																				<EventLoggingName id={e?.userId} />
																				<Text
																					fontSize="sm"
																					fontWeight="normal"
																					color={eventActionMapping[e?.action]['color']}
																					ml={1}
																					mr={1}
																				>
																					{lang === 'kh'
																						? eventActionMapping[e?.action]?.titleKh
																						: eventActionMapping[e?.action]?.titleEn}
																				</Text>
																				{e?.category === '1' && e?.type === '1' && (
																					<>
																						<EventLoggingCandidatePartnerRequest id={e?.actionObjectId} />
																					</>
																				)}
																				{e?.category === '1' && e?.type === '2' && (
																					<>
																						<EventLoggingCandidateTerminationRequest id={e?.actionObjectId} />
																					</>
																				)}
																				{e?.category === '1' && e?.type === '3' && (
																					<>
																						<EventLoggingCandidateBranchChangeRequest id={e?.actionObjectId} />
																					</>
																				)}
																				{e?.category === '2' && e?.type === '1' && (
																					<>
																						<EventLoggingSaleProfessional id={e?.actionObjectId} />
																					</>
																				)}
																				{e?.category === '2' && e?.type === '2' && (
																					<>
																						<EventLoggingTerminatedSaleProfessional id={e?.actionObjectId} />
																					</>
																				)}
																				{e?.category === '3' && e?.type === '4' && (
																					<>
																						<EventLoggingProfessionalIndividual id={e?.actionObjectId} />
																					</>
																				)}
																				{e?.category === '3' && e?.type === '5' && (
																					<>
																						<EventLoggingPartnerDetailSaleStaff id={e?.actionObjectId} />
																					</>
																				)}
																			</Flex>
																			<Flex alignItems="center">
																				<>
																					{e?.actionDescription && (
																						<>
																							<Text fontSize="sm" fontWeight="md" color="gray.600">
																								{e?.actionDescription}
																							</Text>
																						</>
																					)}
																				</>
																			</Flex>
																			<Flex alignItems="center">
																				<Text fontSize="sm" fontWeight="normal" color="gray.500" ml={0}>
																					{toDateString(e?.date, c.loggingDateTimeFormat)}
																				</Text>
																			</Flex>
																		</Flex>
																	</>
																</>
															)}
															{['1', '10', '11'].includes(e?.action) && (
																<>
																	<Flex direction="column">
																		<Flex alignItems="center">
																			<Text fontSize="sm" fontWeight="semibold" color="gray.600" mr={1}>
																				{i + 1}
																			</Text>
																			<Text
																				fontSize="sm"
																				fontWeight="normal"
																				color={eventActionMapping[e?.action]['color']}
																			>
																				{lang === 'kh'
																					? eventActionMapping[e?.action]?.titleKh
																					: eventActionMapping[e?.action]?.titleEn}
																			</Text>
																		</Flex>
																		<Flex alignItems="center">
																			<Text fontSize="sm" fontWeight="normal" color="gray.500" ml={0}>
																				{toDateString(e?.date, c.loggingDateTimeFormat)}
																			</Text>
																		</Flex>
																	</Flex>
																</>
															)}
														</Flex>
													);
												}
											}
										}
										return null;
									})}
								</>
							)}
						</>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default EventLoggingModal;
