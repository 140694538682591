/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Center, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../Other';
import CompanyAgentSaleStaff from './CompanyAgentSaleStaff';
import CompanyInsuranceSaleStaff from './CompanyInsuranceSaleStaff';
import c from '../../../constant';

const Q_GET_COMPANY = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			type
		}
	}
`;

function DistributionSystemSaleStaff({ id, ...props }) {
	const { t } = useTranslation();
	const [getCompanyDetail, { loading, data }] = useLazyQuery(Q_GET_COMPANY);

	useEffect(() => {
		getCompanyDetail({ variables: { id } });
	}, [id]);

	function isCompanyInsurance() {
		return ['GENERAL_INSURANCE', 'LIFE_INSURANCE', 'MICRO_INSURANCE'].includes(data?.getCompanyById?.type);
	}

	function isCompanyAgent() {
		return ['INSURANCE_AGENT'].includes(data?.getCompanyById?.type);
	}

	return (
		<>
			{!loading && data?.getCompanyById && (isCompanyInsurance() || isCompanyAgent()) && (
				<Flex mt={6} {...props}>
					<Box className="hidden-print" width="100%">
						<SectionHeader title={t('Sale Staff')} />
						<Center mt={4}>
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									p={4}
									pt={2}
									pb={2}
									mb={4}
									h="48px"
									bg="#FAFAFA"
									alignItems="center"
									justifyContent="end"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex alignItems="center" />
								</Flex>
								{isCompanyInsurance() && <CompanyInsuranceSaleStaff id={id} />}
								{isCompanyAgent() && <CompanyAgentSaleStaff id={id} />}
							</Box>
						</Center>
					</Box>
				</Flex>
			)}
		</>
	);
}

export default DistributionSystemSaleStaff;
