/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Tbody, Tfoot, Tr, Th, Td, TableContainer, Thead, Button, SimpleGrid, Divider } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import PrintLayout from '../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import useStore from '../../../store';
import c from '../../../constant';
import SmallDownloadAndViewButton from '../../../components/Files/SmallDownloadAndViewButton';
import UpdateProfessionalTerminationApproval from '../component/UpdateProfessionalTerminationApproval';
import CompanyName from '../../../components/CompanyName';
import LabelDetail from '../../../components/LabelDetail';
import LocalizedDate from '../../../components/LocalizedDate';
import IntermediaryStatus from '../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import EventLoggingButton from '../../../components/ManageAgentPartner/Modal/EventLoggingButton';

const Q_GET_DATA_DETAIL = gql`
	query get($id: String!) {
		getCompanySaleProfessionalTerminationRequestById(id: $id) {
			id
			number
			createdAt
			createdBy
			completedAt
			status
			adminStatus
			statusUpdatedAt
			statusUpdatedBy
			company {
				id
				name
				nameKhmer
				logo
			}
		}
	}
`;

const Q_GET_DATA_LIST = gql`
	query get($id: String!) {
		getCompanySaleProfessionalsTerminationByRequestById(id: $id) {
			id
			createdAt
			createdBy
			status
			approved
			rejectDescription
			endDate
			document
			staff {
				id
				idCardNumber
				firstName
				lastName
				firstNameEn
				lastNameEn
				gender
				dob
				baseCertificate
				professionalCertificate
				contract
				createdAt
				createdBy
				status
				approved
				rejectDescription
			}
		}
	}
`;

const MUTATION_NEXT = gql`
	mutation update($id: String!) {
		submitProfessionalTerminationApproval(id: $id) {
			id
			number
			createdAt
			createdBy
			completedAt
			status
			adminStatus
			statusUpdatedAt
			statusUpdatedBy
			company {
				id
				name
				nameKhmer
				logo
			}
		}
	}
`;

const LABEL_WIDTH = '130px';

function Report() {
	const { t } = useTranslation();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentUser } = useStore((state) => state.currentUser);
	const [loadData, { loading, data }] = useLazyQuery(Q_GET_DATA_DETAIL);
	const [loadDataList, { loading: loadingList, data: list }] = useLazyQuery(Q_GET_DATA_LIST);
	const [mutationNext, { loading: loadingNext, data: dataNext }] = useMutation(MUTATION_NEXT);
	const [dataState, setDataState] = useState(null);
	const [listState, setListState] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData({
			variables: {
				id,
			},
		});

		loadDataList({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (list?.getCompanySaleProfessionalsTerminationByRequestById) {
			setListState(list?.getCompanySaleProfessionalsTerminationByRequestById);
		}
	}, [list]);

	useEffect(() => {
		if (data?.getCompanySaleProfessionalTerminationRequestById) {
			setDataState(data?.getCompanySaleProfessionalTerminationRequestById);
		}
	}, [data]);

	useEffect(() => {
		if (dataNext?.submitProfessionalTerminationApproval) {
			setDataState(dataNext?.submitProfessionalTerminationApproval);
		}
	}, [dataNext]);

	const isPermitted = true;
	const [isFormCompleted, setIsFormCompleted] = useState(false);

	useEffect(() => {
		let reviewCompleted = true;
		listState?.map((item) => {
			if (item?.approved === null) {
				reviewCompleted = false;
			}
		});
		setIsFormCompleted(reviewCompleted);
	}, [listState]);

	function checkDisabledApproval() {
		switch (dataState?.adminStatus) {
			case 'reviewing_by_office':
				return parseInt(currentUser?.position) >= 5 ? false : true;
			case 'reviewing_by_department':
				return parseInt(currentUser?.position) <= 4 ? false : true;
			default:
				return true;
		}
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Insurance Sale Staff',
								path: '/company-sale-professional/menu',
							},
							{
								name: 'TERMINATE_CONTRACT',
								path: '/company-sale-professional/termination/list?page=1',
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('TERMINATE_CONTRACT')}
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Detail')}
								</Text>
								<Flex alignItems="center">
									<EventLoggingButton label="Logs" typeObjectId={id} />
									{dataState?.id && (
										<>
											{dataState?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingNext}
																isDisabled={!isFormCompleted || checkDisabledApproval()}
																rightIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() => {
																	if (isFormCompleted) {
																		mutationNext({
																			variables: {
																				id,
																			},
																		});
																	}
																}}
															>
																{t('Done')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									)}
								</Flex>
							</Flex>
							{(loading || loadingList) && <Text p="4">{t('Loading')}</Text>}
							{!loading && !loadingList && (
								<>
									{dataState?.id && (
										<Box p="16px">
											<>
												<SimpleGrid minH="75px" columns={[1, 1, 2]} w="100%" bg="white" spacing={0} mb={4}>
													<LabelDetail labelWidth={LABEL_WIDTH} direction="row" label="Number" titleDetail={dataState?.number} />
													<LabelDetail
														labelWidth={LABEL_WIDTH}
														direction="row"
														label="Created By"
														childDetail={<CompanyName company={dataState?.company} />}
													/>
													<LabelDetail
														labelWidth={LABEL_WIDTH}
														direction="row"
														label="Created At"
														childDetail={<LocalizedDate dateString={dataState?.createdAt} />}
													/>
													<LabelDetail
														labelWidth={LABEL_WIDTH}
														direction="row"
														label="Status"
														childDetail={<IntermediaryStatus status={dataState?.adminStatus} />}
													/>

													<LabelDetail
														labelWidth={LABEL_WIDTH}
														direction="row"
														label="Completed At"
														childDetail={<LocalizedDate dateString={dataState?.completedAt} />}
													/>
												</SimpleGrid>
												<Divider mb={4} mt={4} />
											</>
											<TableContainer>
												<Table size="sm">
													<Thead>
														<Tr>
															<Th>{t('ID Card Number')}</Th>
															<Th>{t('First Name')}</Th>
															<Th>{t('Last Name')}</Th>
															<Th>{t('End At')}</Th>
															<Th isNumeric>{t('Document')}</Th>
															<Th isNumeric>{t('Status')}</Th>
														</Tr>
													</Thead>
													<Tbody>
														{loadingList && (
															<>
																<Tr>
																	<Td colSpan={20} minH="100px">
																		<Box>{t('Loading')}</Box>
																	</Td>
																</Tr>
															</>
														)}
														{!loadingList && listState?.length <= 0 && (
															<>
																<Tr>
																	<Td colSpan={6}>
																		<Flex justifyContent="flex-start" alignItems="center">
																			{t('No Data')}
																		</Flex>
																	</Td>
																</Tr>
															</>
														)}
														{!loadingList &&
															listState?.map((item, index) => {
																return (
																	<Tr key={index}>
																		<Td>{item?.staff?.idCardNumber}</Td>
																		<Td fontFamily="NotoSansKhmer">{item?.staff?.firstName}</Td>
																		<Td fontFamily="NotoSansKhmer">{item?.staff?.lastName}</Td>
																		<Td>
																			<LocalizedDate dateString={item?.endDate} dateFormat={c.dateOnlyFormat} />
																		</Td>
																		<Td>
																			<Flex justifyContent="flex-end">
																				<SmallDownloadAndViewButton
																					url={item?.document?.url}
																					size={item?.document?.size}
																					marginLeft={0}
																				/>
																			</Flex>
																		</Td>
																		<Td isNumeric>
																			<Box float="right">
																				<UpdateProfessionalTerminationApproval
																					isDisabled={checkDisabledApproval()}
																					item={item}
																					updateCallback={(e) => {
																						let tempList = [...listState];
																						tempList[index] = {
																							...item,
																							approved: e?.approved,
																							rejectDescription: e?.rejectDescription,
																						};
																						setListState(tempList);
																					}}
																				/>
																			</Box>
																		</Td>
																	</Tr>
																);
															})}
													</Tbody>
													<Tfoot>
														<Tr>
															<Th>{t('ID Card Number')}</Th>
															<Th>{t('First Name')}</Th>
															<Th>{t('Last Name')}</Th>
															<Th>{t('End At')}</Th>
															<Th isNumeric>{t('Document')}</Th>
															<Th isNumeric>{t('Status')}</Th>
														</Tr>
														<Tr>
															<Th isNumeric colSpan={20}>
																{t('Total Rows')}:{listState?.length}
															</Th>
														</Tr>
													</Tfoot>
												</Table>
											</TableContainer>
										</Box>
									)}
								</>
							)}
						</Box>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default Report;
