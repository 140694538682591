/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Box, Flex, DrawerBody, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import TextFormField from '../../../components/form/TextFormField';
import DateRangeFormField from '../../../components/form/DateRangeFormField';
import RadioFormField from '../../../components/form/RadioFormField';
import CompanyInputOrSelectFormField from '../../../components/form/CompanyInputOrSelectFormField';
import c from '../../../constant';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	number: Yup.string(),
	firstName: Yup.string(),
	firstNameEn: Yup.string(),
	lastName: Yup.string(),
	lastNameEn: Yup.string(),
	gender: Yup.string().nullable(),

	dob: Yup.string(),
	// approvedDate: Yup.string(),
	company: Yup.object(),
});

function SaleStaffFilter({
	number = null,
	firstName = null,
	lastName = null,
	firstNameEn = null,
	lastNameEn = null,
	gender = null,
	dobFrom = null,
	dobTo = null,
	companyName = null,
	companyId = null,
	isOpen = false,
	onClose = () => null,
	onCallback = () => null,
}) {
	const { t } = useTranslation();

	const defaultdob = () => {
		if (dobFrom && dobTo) {
			const from = JSON.parse(dobFrom);
			const to = JSON.parse(dobTo);
			return JSON.stringify({ from, to });
		}
		return null;
	};

	// const defaultApprovedDate = () => {
	// 	if (approvedDateFrom && approvedDateTo) {
	// 		const from = JSON.parse(approvedDateFrom);
	// 		const to = JSON.parse(approvedDateTo);
	// 		return JSON.stringify({ from, to });
	// 	}
	// 	return null;
	// };

	return (
		<>
			<Drawer isOpen={isOpen} onClose={onClose} size="sm" placement="right">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{t('Filter')}</DrawerHeader>
					<Formik
						initialValues={{
							number: number || '',
							firstName: firstName || '',
							lastName: lastName || '',
							firstNameEn: firstNameEn || '',
							lastNameEn: lastNameEn || '',
							gender: gender || '',

							dob: defaultdob() || '',
							// approvedDate: defaultApprovedDate() || '',
							company: { companyName: companyName || '', companyId },
						}}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							let result = {};
							if (values?.number) {
								result.number = values?.number;
							}
							if (values?.firstName) {
								result.firstName = values?.firstName;
							}
							if (values?.lastName) {
								result.lastName = values?.lastName;
							}
							if (values?.firstNameEn) {
								result.firstNameEn = values?.firstNameEn;
							}
							if (values?.lastNameEn) {
								result.lastNameEn = values?.lastNameEn;
							}

							if (values.gender) {
								result.gender = values?.gender;
							}

							if (values?.dob) {
								let object = JSON.parse(values?.dob);
								if (object?.from && object?.to) {
									result.dobFrom = JSON.stringify(object?.from);
									result.dobTo = JSON.stringify(object?.to);
								}
							}
							// if (values?.approvedDate) {
							// 	let object = JSON.parse(values?.approvedDate);
							// 	if (object?.from && object?.to) {
							// 		result.approvedDateFrom = JSON.stringify(object?.from);
							// 		result.approvedDateTo = JSON.stringify(object?.to);
							// 	}
							// }
							if (values?.company?.companyName) {
								result.companyName = values?.company?.companyName;
							}
							if (values?.company?.companyId) {
								result.companyId = values?.company?.companyId;
							}
							onClose();
							onCallback(result);
						}}
					>
						{(props) => (
							<DrawerBody>
								<Box>
									<Form>
										<Box>
											<center>
												<TextFormField {...props} label="ID Card Number" name="number" />
											</center>
										</Box>
										<Box>
											<center>
												<TextFormField font="NotoSansKhmer" {...props} label="First Name" name="firstName" />
											</center>
										</Box>
										<Box>
											<center>
												<TextFormField font="NotoSansKhmer" {...props} label="Last Name" name="lastName" />
											</center>
										</Box>
										<Box>
											<center>
												<TextFormField {...props} label="First Name En" name="firstNameEn" />
											</center>
										</Box>
										<Box>
											<center>
												<TextFormField {...props} label="Last Name En" name="lastNameEn" />
											</center>
										</Box>
										<RadioFormField
											label="Gender"
											name="gender"
											radios={[
												{ label: 'Male', value: 'M' },
												{ label: 'female', value: 'F' },
											]}
											initValue={gender}
										/>
										<Box>
											<center>
												<CompanyInputOrSelectFormField label="Company" name="company" inputPlaceholder="Company" />
											</center>
										</Box>
										<Box>
											<center>
												<DateRangeFormField
													{...props}
													label="DOB"
													name="dob"
													initialDate={{ fromDate: dobFrom, toDate: dobTo }}
												/>
											</center>
										</Box>
										{/* <Box>
											<center>
												<DateRangeFormField
													{...props}
													label="Approved Request Date"
													name="approvedDate"
													initialDate={{ fromDate: approvedDateFrom, toDate: approvedDateTo }}
												/>
											</center>
										</Box> */}
										<Flex mt={8} mb={8} justifyContent="flex-end">
											<Button
												ml={0}
												size="md"
												h="42px"
												w="148px"
												variant="ghost"
												colorScheme="gray"
												borderRadius={c.borderRadius}
												color="black"
												onClick={() => {
													onClose();
													onCallback({});
												}}
											>
												{t('Clear')}
											</Button>
											<Button
												ml={4}
												size="md"
												h="42px"
												w="148px"
												variant="solid"
												colorScheme="gray"
												borderRadius={c.borderRadius}
												color="#3498db"
												type="submit"
											>
												{t('Apply')}
											</Button>
										</Flex>
									</Form>
								</Box>
							</DrawerBody>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default SaleStaffFilter;
