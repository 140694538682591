/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Center, Container, Divider, Flex, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSaleProfessionalPermissionStore } from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyName from '../../../../components/CompanyName';
import DownloadAndViewButton from '../../../../components/Files/DownloadAndViewButton';
import LabelDetail from '../../../../components/LabelDetail';
import Loading from '../../../../components/Loading';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryModel from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryModel';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import NoData from '../../../../components/NoData';
import PrintLayout from '../../../../components/Print/PrintLayout';
import c from '../../../../constant';
import useStore from '../../../../store';
import useMoment from '.././../../../hooks/useMoment';
import SaleProfessionalNewList from './components/SaleProfessionalNewList';
import SaleProfessionalChangeBranchList from './components/SaleProfessionalChangeBranchList';
import SaleProfessionalTerminationList from './components/SaleProfessionalTerminationList';

const Q_DETAIL = gql`
	query get($id: String!) {
		getIntermediarySaleProfessionalRequestById(id: $id) {
			id
			number
			type
			intermediary {
				id
				name
				nameKhmer
				type
				logo
			}
			createdBy
			createdAt
			updatedBy
			updatedAt
			adminStatus
			completedAt
			completedLetter
			details {
				id
				requestId
				partner {
					id
					name
					nameKhmer
					type
					logo
				}
				partnerType
				model
				labelPartnerType
				items {
					id
					order
					detailId
					saleProfessional {
						id
						idCardNumber
						firstName
						lastName
						firstNameEn
						lastNameEn
						gender
						dob
						status
						company {
							id
							name
							nameKhmer
							type
							logo
						}
					}
					oldBranch {
						id
						name
						nameLatin
					}
					newBranch {
						id
						name
						nameLatin
					}
					approvalStatus
					rejectionReason
					terminatedAt
					terminatedReason
				}
			}
		}
	}
`;

const M_SUBMIT = gql`
	mutation submit($id: String!, $requestDate: String, $solarDate: String, $lunarDate: String) {
		submitIntermediarySaleProfessionalRequestReview(id: $id, requestDate: $requestDate, solarDate: $solarDate, lunarDate: $lunarDate)
	}
`;

const titles = {
	new: 'Request for New Sale Staff',
	change: 'Request for Change Branch of Sale Staff',
	terminate: 'Request for Termination of Sale Staff',
};

function IntermediarySaleProfessionalRequestDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location?.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const setPermitted = useSaleProfessionalPermissionStore((state) => state.setPermitted);
	const { isPermitted } = useSaleProfessionalPermissionStore((state) => state.isPermitted);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { t } = useTranslation();
	const { paperLunarDateString, paperSolarDateString, paperGeneralDateString } = useMoment();
	const [detailData, setDetailData] = useState(null);
	const [fetchDetailData, { loading, error, data }] = useLazyQuery(Q_DETAIL);
	const [sumitReviewData, { loading: loadingSubmit }] = useMutation(M_SUBMIT);

	useEffect(() => {
		loadDetailData();
	}, [id]);

	useEffect(() => {
		data?.getIntermediarySaleProfessionalRequestById && setDetailData(data?.getIntermediarySaleProfessionalRequestById);
	}, [data]);

	useEffect(() => {
		loadPermission();
	}, [detailData, user]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	const loadDetailData = () => {
		fetchDetailData({ variables: { id } });
	};

	const loadPermission = () => {
		detailData?.adminStatus && user?.position && setPermitted(detailData?.adminStatus, user?.position);
	};

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: titles[detailData?.type],
								path: `/intermediary/sale-professional-request?page=1&type=${detailData?.type}`,
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex w="100%" justifyContent="space-between" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Detail')}
									</Text>
									<Flex justifyContent="flex-end" alignItems="center">
										<>
											{detailData?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingSubmit}
																isDisabled={detailData?.details
																	?.reduce((iPre, iCur) => {
																		let localIPre = iPre;
																		let localICur = iCur?.items?.reduce((jPre, jCur) => {
																			let localJPre = jPre;
																			let localjCur = jCur?.approvalStatus === null;
																			localJPre.push(localjCur);
																			return localJPre;
																		}, []);
																		localIPre = [...localIPre, ...localICur];
																		return localIPre;
																	}, [])
																	.includes(true)}
																rightIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() =>
																	sumitReviewData({
																		variables: {
																			id: detailData?.id,
																			requestDate: paperGeneralDateString(data?.startedAt, 'ឆ្នាំ'),
																			solarDate: paperSolarDateString(),
																			lunarDate: paperLunarDateString(),
																		},
																	})
																		.then(({ data }) => {
																			loadDetailData();
																		})
																		.catch((error) => {
																			toast({
																				title: t(error?.message ? error?.message : 'something went wrong'),
																				status: 'error',
																				isClosable: true,
																				position: 'bottom-right',
																			});
																		})
																}
															>
																{t('Done')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									</Flex>
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{loading && (
									<>
										<Loading />
									</>
								)}
								{!loading && !detailData?.id && (
									<>
										<NoData />
									</>
								)}
								{!loading && detailData?.id && (
									<Box w="100%">
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<LabelDetail mb={1} mt={0} labelWidth={32} direction="row" label="Number" titleDetail={detailData?.number} />
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Created By"
												childDetail={<CompanyName company={detailData?.intermediary} />}
											/>
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Submitted At"
												childDetail={<LocalizedDate dateString={detailData?.createdAt} />}
											/>
											<LabelDetail
												mb={1}
												mt={0}
												labelWidth={32}
												direction="row"
												label="Status"
												childDetail={<IntermediaryStatus status={detailData?.adminStatus} />}
											/>
											{detailData?.adminStatus === 'completed' && (
												<LabelDetail
													mb={1}
													mt={0}
													labelWidth={32}
													direction="row"
													label="Completed At"
													childDetail={<LocalizedDate dateString={detailData?.completedAt} />}
												/>
											)}
											{detailData?.adminStatus === 'completed' && (
												<>
													{detailData?.completedLetter?.url && (
														<LabelDetail
															mb={1}
															mt={0}
															labelWidth=""
															label={detailData?.type === 'new' ? 'Approval Letter' : 'Acknowledgement Letter'}
															childDetail={
																<DownloadAndViewButton marginLeft={0} url={c.baseFilePath + detailData?.completedLetter?.url} />
															}
														/>
													)}
												</>
											)}
										</SimpleGrid>
										<Tabs variant="enclosed" mt={4}>
											<TabList>
												{detailData?.details?.map((item, i) => (
													<>
														<Tab key={`tab-i-${i}`}>{t(item?.labelPartnerType)}</Tab>
													</>
												))}
											</TabList>
											<TabPanels>
												{detailData?.details?.map((detail, i) => (
													<TabPanel key={i} borderWidth={1} borderStyle="solid" borderColor="gray.200">
														<SimpleGrid columns={[1, null, 2]} spacingX={8}>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																direction="column"
																label="Partnered Insurance Company"
																childDetail={<CompanyName company={detail?.partner} />}
															/>
															<LabelDetail
																mb={1}
																mt={0}
																labelWidth=""
																direction="column"
																label="Model"
																childDetail={<IntermediaryModel model={detail?.model} />}
															/>
														</SimpleGrid>
														<Divider mb={2} mt={4} />
														{detailData?.type === 'new' && (
															<LabelDetail
																mb={0}
																mt={2}
																label="Sale Staff List"
																childDetail={
																	<Box w="100%" mt={1}>
																		<SaleProfessionalNewList
																			emptyLabel="No Sale Staff Selected"
																			detailId={detail?.id}
																			detailItems={detail?.items}
																			onCallback={(e) => {
																				setDetailData({
																					...detailData,
																					details: detailData?.details?.reduce((pre, cur) => {
																						let localPre = pre;
																						if (cur?.id === e?.detailId) {
																							localPre.push({ ...cur, items: e?.items });
																						} else {
																							localPre.push(cur);
																						}
																						return localPre;
																					}, []),
																				});
																			}}
																		/>
																	</Box>
																}
															/>
														)}
														{detailData?.type === 'change' && (
															<LabelDetail
																mb={0}
																mt={2}
																label="Sale Staff List"
																childDetail={
																	<Box w="100%" mt={1}>
																		<SaleProfessionalChangeBranchList
																			emptyLabel="No Sale Staff Selected"
																			detailId={detail?.id}
																			detailItems={detail?.items}
																			onCallback={(e) => {
																				setDetailData({
																					...detailData,
																					details: detailData?.details?.reduce((pre, cur) => {
																						let localPre = pre;
																						if (cur?.id === e?.detailId) {
																							localPre.push({ ...cur, items: e?.items });
																						} else {
																							localPre.push(cur);
																						}
																						return localPre;
																					}, []),
																				});
																			}}
																		/>
																	</Box>
																}
															/>
														)}
														{detailData?.type === 'terminate' && (
															<LabelDetail
																mb={0}
																mt={2}
																label="Sale Staff List"
																childDetail={
																	<Box w="100%" mt={1}>
																		<SaleProfessionalTerminationList
																			emptyLabel="No Sale Staff Selected"
																			detailId={detail?.id}
																			detailItems={detail?.items}
																			onCallback={(e) => {
																				setDetailData({
																					...detailData,
																					details: detailData?.details?.reduce((pre, cur) => {
																						let localPre = pre;
																						if (cur?.id === e?.detailId) {
																							localPre.push({ ...cur, items: e?.items });
																						} else {
																							localPre.push(cur);
																						}
																						return localPre;
																					}, []),
																				});
																			}}
																		/>
																	</Box>
																}
															/>
														)}
													</TabPanel>
												))}
											</TabPanels>
										</Tabs>
									</Box>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
		</>
	);
}

export default IntermediarySaleProfessionalRequestDetail;
